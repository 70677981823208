import React, { Component, createRef } from "react";

import CallLayout from "../../components/callLayout";
import Alert from "../../components/shared/common/alert";

class Call extends Component {
  constructor(props) {
    super(props);

    this.callLayoutRef = createRef();
    this.onStopScreenShare = this.onStopScreenShare.bind(this);
  }

  onStopScreenShare() {
    const { current } = this.callLayoutRef;
    if (current && current.onStopScreenShare) {
      current.onStopScreenShare();
    } else {
      Alert(400, "Unable to stop screen sharing. Please try again.");
    }
  }

  render() {
    return <CallLayout ref={this.callLayoutRef} {...this.props} />;
  }
}

export default Call;
