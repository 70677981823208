import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../firebase/context";
import { compose } from "redux";
import { UpdateProfilePhoto } from "../../modal";
import * as screenAnimationAction from "../../../redux/screen-animations/action";
import * as chatSepcialSymbolsAction from "../../../redux/chat-special-symbols/action";
import * as callEndAnimationAction from "../../../redux/call-end-animations/action";
import * as loginAction from "../../../redux/login/action";
import SubHeader from "./subHeader";
import {
  LOGIN_SESSION_EXPIRED_AT,
  SCHEMA,
} from "../../../components/shared/constants";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callModal: false,
      callNotifications: {},
      isOpenProfilePhotoModal: false,
      dropdownOpen: false,
      isDisabled: true,
    };
  }

  handleLoginSessionExpiration = () => {
    const {
      userInfo: { loginSessionStartAt },
    } = this.props;
    if (
      loginSessionStartAt && ((Date.now() - loginSessionStartAt) / 1000) >= LOGIN_SESSION_EXPIRED_AT &&
      !sessionStorage.getItem("currentCallId")
    ) {
      this.props.logout();
    }
  }

  componentDidMount() {   
    this.handleLoginSessionExpiration();
    this.getAnimationDetail();

    const { isGuestCallLink } = this.props;
    if (isGuestCallLink) {
      this.intervalJoin = setInterval(() => {
        const {
          userInfo: { userId, isGuestMode },
        } = this.props;
        if (userId) {
          clearInterval(this.intervalJoin);
          if (isGuestMode) {
            this.getCallJoinRequestNotification(userId);
            this.getRecentChatSpecialSymbol(userId);
            this.getAndSetChatSpecialSymbols();
          }
        }
      }, 2000);
    }
  }

  getCallJoinRequestNotification(userId) {
    this.props.firebase.getUserUnreadAskToJoinCallNotifications(
      userId,
      (res) => {
        if (!!res && Object.keys(res).length > 0) {
          this.props.setUserAskJoinCallNotifications(res);
        } else {
          this.props.setUserAskJoinCallNotifications({});
        }
      }
    );
  }

  getAnimationDetail() {
    const {
      firebase,
      userInfo: { isGuestMode, isGuestJoined },
    } = this.props;

    firebase.getScreenAnimations((res) => {
      this.props.updateScreenAnimation(res);
    });

    if (isGuestMode && isGuestJoined) {
      firebase.getCallEndAnimations((res) => {
        this.props.updateCallEndAnimation(res);
      });
    }
  }

  getAndSetChatSpecialSymbols = () => {
    this.props.firebase.refreshChatSymbols(
      SCHEMA.CHAT_SPECIAL_SYMBOLS,
      (res) => {
        if (res) {
          this.props.setChatSpecialSymbols(res);
        }
      }
    );
  };

  getRecentChatSpecialSymbol = (userId) => {
    this.props.firebase.getRecentChatSymbol(userId, (res) => {
      const recentGifs = Object.values(res || {}).sort(
        (a, b) => b.timeStamp - a.timeStamp
      );
      this.props.setRecentChatSpecialSymbol(recentGifs);
    });
  };

  toggleProfilePhotoModal = () => {
    this.setState({
      isOpenProfilePhotoModal: !this.state.isOpenProfilePhotoModal,
    });
  };

  setOpenProfilePhotoModal = (value) => {
    this.setState({
      isOpenProfilePhotoModal: value,
    });
  };

  renderLogo = () => {
    const {
      userInfo: { isGuestMode, isGuestJoined },
      history,
    } = this.props;
    let location = "";

    if (
      isGuestMode &&
      isGuestJoined &&
      history &&
      history.location &&
      history.location.pathname
    ) {
      location = history.location.pathname;
      if (location.includes("call")) {
        return (
          <div className="logo">
            <img src={logo} alt="Bonfire" />
          </div>
        );
      }
    }

    return (
      <Link to="/" className="logo">
        <img src={logo} alt="Bonfire" />
      </Link>
    );
  };

  render() {
    let { isOpenProfilePhotoModal } = this.state;
    const {
      userInfo: { isLoggedIn },
    } = this.props;

    return (
      <>
        <header className="header">
          <div className="wrapper">
            {this.renderLogo()}
            <div>
              {isLoggedIn && (
                <SubHeader
                  setOpenProfilePhotoModal={this.setOpenProfilePhotoModal}
                />
              )}
            </div>
          </div>
        </header>
        {isLoggedIn && isOpenProfilePhotoModal && (
          <UpdateProfilePhoto
            isOpen={isOpenProfilePhotoModal}
            toggle={() => this.toggleProfilePhotoModal()}
            userInfo={Object.assign({}, this.props.userInfo)}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateScreenAnimation: (res) =>
      dispatch(screenAnimationAction.updateScreenAnimation(res)),
    setChatSpecialSymbols: (res) =>
      dispatch(chatSepcialSymbolsAction.setSpecialSymbols(res)),
    updateCallEndAnimation: (res) =>
      dispatch(callEndAnimationAction.updateCallEndAnimation(res)),
    setUserAskJoinCallNotifications: (data) =>
      dispatch(loginAction.setUserAskJoinCallNotifications(data)),
    setRecentChatSpecialSymbol: (data) =>
      dispatch(loginAction.setRecentChatSpecialSymbol(data)),
    logout: (data) => dispatch(loginAction.setLogOut(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
