import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import cx from "classnames";
import moment from "moment";
import { withRouter } from "react-router-dom";
import fireIcon from "../../../assets/images/icons/fire_icon.png";
import { withFirebase } from "../../../firebase/context";
import Alert from "../../shared/common/alert";
import { setUserChatDetail } from "../../../redux/user-chat/action";
import { setTimerData } from "../../../redux/login/action";
import { setIsTimerStarted } from "../../../redux/login/action";
import smileIcon from "../../../assets/images/icons/smile.png";
import {
  checkIsValidURL,
  handleUploadToFireBaseStorage,
} from "../../../components/shared/utils";
import {
  FILE_STORAGE_PATH,
  MESSAGE_TYPES,
  APP_FEATURES_CONFIG_KEYS,
  WELCOME_MESSAGE_TEXT,
} from "../../shared/constants";
import GiphyView from "./giphyView";
import AnimationsSetup from "../../animationsSetup";
import TimerComponent from "../../timer/index";
import messageTone from "../../../assets/audio/message.mp3";

class ChatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessage: "",
      messages: [],
      isSending: false,
      invited: {},
      isDisabled: true,
      joinedFriends: {},
      currentCallId: "",
      eventJoinedFriends: {},
      path: "",
      isImageAttachment: false,
      attachmentUrl: "",
      attachmentFile: "",
      isOpenGiphyView: false,
      gifUrl: "",
      gifKeyword: "",
      isTimer: false,
      isShowAnimationsSetupModal: false,
      chatAnimationStatus: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.onClearAttachment = this.onClearAttachment.bind(this);
    this.onToggleGifView = this.onToggleGifView.bind(this);
    this.onSelectGif = this.onSelectGif.bind(this);
    this.onClearGifSelection = this.onClearGifSelection.bind(this);
    this.showChats = this.showChats.bind(this);
    this.handleAnimationsSetupModal = this.handleAnimationsSetupModal.bind(this);
  }

  scrollToBottom = () => {
    if (document) {
      const element = document.getElementById("chat-scroll-view");
      if (element) {
        const sHeight = element.scrollHeight;
        element.scrollTo({ top: sHeight });
      }
    }
  };

  fetchHistory() {
    const {
      userInfo: { isGuestMode, isGuestJoined },
    } = this.props;
    const sessionCallId = sessionStorage.getItem("currentCallId");

    if ((!sessionCallId && isGuestMode && !isGuestJoined) || !sessionCallId) {
      return;
    }
    const { pathname } = window.location;
    const path = pathname.split("/");

    this.setState({ currentCallId: path[2], path: pathname }, async () => {
      await this.getChatHistory();
    });
  }

  isRouteUpdated(prevProps) {
    let oldPath = this.state.path;
    let newPath = "";
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname
    ) {
      newPath = this.props.history.location.pathname;
    }

    if (oldPath !== newPath && newPath.includes("call")) {
      return true;
    }
    return false;
  }

  async showChats(response) {
    if (response) {
      const result = Object.values(response);
      const indexOfLastMessage = result.length - 1;
      const lastMessage = result[indexOfLastMessage];
      const timeDiff = (Date.now() - lastMessage.timestamp) / 1000;
      if (timeDiff < 1 && lastMessage.name !== MESSAGE_TYPES.WELCOME_MESSAGE) {
        const callAudioEl = document.getElementById("messageTone");
        callAudioEl.play();
      }
      this.props.setUserChatDetail(response);
    }
    if (!response) {
      const totalFriends = this.getTotalFriends(this.state.joinedFriends);
      await this.onSendMessage(totalFriends, WELCOME_MESSAGE_TEXT, false, true);
    }
  }

  componentDidMount() {
    const currentCallId = sessionStorage.getItem("currentCallId");
    this.inputFieldRef && this.inputFieldRef.focus();
    const {
      userInfo: { callTimer, userId },
    } = this.props;
    if (
      callTimer &&
      callTimer.createdBy === userId &&
      callTimer.callId === currentCallId
    ) {
      this.setState({
        isTimer: true,
      });
    } else {
      this.setState({
        isTimer: false,
      });
    }
    if (callTimer && callTimer.callId !== currentCallId) {
      this.props.setTimerData(null);
    }
 
    this.props.firebase.getChatAnimationStatus(currentCallId ,(res)=> {
      if(res) {
        this.setState({ chatAnimationStatus : res.isChatAnimationsDisabled })
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.isRouteUpdated(prevProps)) {
      this.fetchHistory();
    } else {
      const { chatDetail } = this.props;
      const chatList = chatDetail ? Object.values(chatDetail) : [];
      const len = chatList.length;
      const waitTime = len
        ? chatList[len - 1] && chatList[len - 1].isImage
          ? 1600
          : 400
        : 3000;
      setTimeout(() => this.scrollToBottom(), waitTime);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentCallId = sessionStorage.getItem("currentCallId");
    const {
      userInfo: { callTimer, isTimerStarted, userId },
    } = nextProps;
    const { isTimer } = prevState;
    if (
      callTimer &&
      callTimer.createdBy === userId &&
      !isTimer &&
      callTimer.callId === currentCallId
    ) {
      return {
        isTimer: true,
      };
    }
    if (callTimer && callTimer.createdBy !== userId && isTimer) {
      return {
        isTimer: false,
      };
    }

    if (!callTimer && isTimerStarted) {
      return {
        isTimer: false,
      };
    }

    if (
      Object.keys(prevState.joinedFriends).length !==
      Object.keys(nextProps.joinedFriends).length
    ) {
      return {
        joinedFriends: nextProps.joinedFriends,
      };
    }

    if (prevState.isImageAttachment !== nextProps.isImageAttachment) {
      return {
        attachmentFile: nextProps.attachment.file,
        attachmentUrl: nextProps.attachment.url,
        newMessage: "",
        gifKeyword: "",
        gifUrl: "",
      };
    }
    return {};
  }

  componentWillUnmount() {
    if (this.listenerRef && this.listenerRef.ref) {
      this.listenerRef.ref.off("value", this.listenerRef.callbackRef);
      this.listenerRef.ref = null;
      this.listenerRef.callbackRef = null;
    }
    this.chatInterval = null;
  }

  async getChatHistory() {
    const {
      firebase,
      userInfo: { userId },
    } = this.props;
    const { pathname } = window.location;
    const path = pathname.split("/");
    const callId = path[2];
    this.chatInterval = setInterval(
      async () =>
        await firebase.showChatMessagesOnce(userId, callId, this.showChats),
      10000
    );
    this.listenerRef = await firebase.showChatMessages(
      userId,
      callId,
      this.showChats
    );
  }

  handleOnChange(e) {
    this.setState({
      newMessage: e.target.value,
    });
  }

  handleAnimationsSetupModal() {
    this.setState({
      isShowAnimationsSetupModal: !this.state.isShowAnimationsSetupModal
    });
  }

  onClearAttachment() {
    this.inputFieldRef && this.inputFieldRef.focus();
    if (this.state.attachmentFile) {
      this.props.onClearAttachment();
      this.setState({
        attachmentFile: null,
        attachmentUrl: "",
      });
    }
  }

  handleUploadImage(totalFriends) {
    const { attachmentFile, currentCallId } = this.state;
    handleUploadToFireBaseStorage({
      imageFile: attachmentFile,
      folderName: FILE_STORAGE_PATH.chat_image_storage + currentCallId,
      storage: this.props.firebase.storage,
      onError: () => {
        Alert(400, "Sorry, unable to send this image.");
        this.setState({
          isSending: false,
        });

        this.onClearAttachment();
      },
      fileName: "image_" + Date.now(),
    }).then(async (url) => {
      if (url) {
        this.onSendMessage(totalFriends, url, true);
      } else {
        Alert(400, "Sorry, unable to send this image.");
        this.setState({
          isSending: false,
        });
        this.onClearAttachment();
      }
    });
  }

  handleSendMessage(totalFriends) {
    const {
      attachmentUrl,
      attachmentFile,
      newMessage,
      gifUrl,
      gifKeyword,
    } = this.state;
    const {
      // userInfo: { userId, callTimer, isPremium },
      userInfo: { userId, callTimer },
    } = this.props;
    const isPremium = true;
    if (attachmentUrl && attachmentFile) {
      this.setState({
        isSending: true,
        isTimer: false,
      });
      this.handleUploadImage(totalFriends);
    } else {
      const formattedMessage = (newMessage && newMessage.toLowerCase()) || "";
      if (isPremium && formattedMessage === APP_FEATURES_CONFIG_KEYS.ANIMATIONS ) {
        this.setState({
          isSending: false,
          isTimer: false,
          newMessage: "",
        }, () => {
          this.handleAnimationsSetupModal();
        })
      } else if (formattedMessage !== APP_FEATURES_CONFIG_KEYS.TIMER) {
        if (formattedMessage) {
          this.setState({
            isSending: true,
          });
          this.onSendMessage(totalFriends, newMessage, false);
        } else if (gifUrl) {
          this.setState({
            isSending: true,
          });
          this.onSendMessage(totalFriends, gifKeyword, false, false, true);
        }
      } else if (formattedMessage === APP_FEATURES_CONFIG_KEYS.TIMER) {
        if (isPremium) {
          if (!callTimer) {
            this.props.setIsTimerStarted(false);
            this.setState({
              isSending: false,
              isTimer: true,
              newMessage: "",
              isShowAnimationsSetupModal: false
            });
          } else if (callTimer.createdBy !== userId) {
            this.setState({
              isSending: false,
              newMessage: "",
            });
            Alert(400, "Countdown timer is already in progress.");
          }
        } else {
          Alert(400, "Please upgrade your account to access timer feature.");
        }
      }
    }
  }

  async onSendMessage(
    totalFriends,
    newMessage,
    isImageAttachment,
    isWelcomeMessage,
    isGifMessage
  ) {
    const FriendsJoined = Object.values(this.state.joinedFriends);
    let userIds = [];
    if (this.props.eventSession) {
      userIds = Object.values(this.state.joinedFriends);
    } else {
      userIds = FriendsJoined.map(({ userId }) => userId);
    }

    const { invited ,chatAnimationStatus} = this.state;
    const {
      firebase,
      userInfo: { userId, username },
    } = this.props;

    const sendTo = Object.assign({}, invited || {});
    sendTo[userId] = userId;
    let obj = {};
    obj[userId] = userId;
    for (const id of userIds) {
      obj[id] = id;
    }

    const messagePayload = {
      senderId: userId,
      name: isWelcomeMessage ? MESSAGE_TYPES.WELCOME_MESSAGE : username,
      message: newMessage,
      timestamp: Date.now(),
      invited: obj,
      currentCallId: this.state.currentCallId,
      isImage: !!isImageAttachment,
      isGif: !!isGifMessage,
      isShowAnimatedMessage: !chatAnimationStatus,
    };
    await firebase
      .saveChatToDB(this.state.currentCallId, messagePayload)
      .then((respose) => {
        this.setState({
          isSending: false,
          newMessage: "",
        });
        this.onClearAttachment();
        this.onClearGifSelection();

        this.inputFieldRef && this.inputFieldRef.focus();
      })
      .catch((err) => {
        this.setState({
          isSending: false,
          newMessage: "",
        });

        this.onClearAttachment();
        this.onClearGifSelection();

        Alert(400, "Unable to send your message.");
      });
    setTimeout(() => this.scrollToBottom(), 100);
  }

  onToggleGifView() {
    this.setState({
      isOpenGiphyView: !this.state.isOpenGiphyView,
    });
  }

  onSelectGif(keyword, gifUrl) {
    const {
      userInfo: { userId },
    } = this.props;
    const {
      checkIfRecentChatSymbolExist,
      updateRecentChatSymbol,
      setRecentChatSymbol,
    } = this.props.firebase;
    const recentEmoji = { name: keyword, timeStamp: Date.now() };
    checkIfRecentChatSymbolExist(userId, keyword, (response) => {
      if (response) {
        updateRecentChatSymbol(userId, recentEmoji, Object.keys(response)[0]);
      } else {
        setRecentChatSymbol(userId, recentEmoji);
      }
    });

    this.setState({
      newMessage: "",
      gifUrl: gifUrl,
      gifKeyword: keyword,
    });
    this.inputFieldRef && this.inputFieldRef.focus();
    this.onClearAttachment();

    this.onToggleGifView();
  }

  onClearGifSelection() {
    this.inputFieldRef && this.inputFieldRef.focus();
    if (this.state.gifUrl) {
      this.setState({
        gifUrl: "",
        gifKeyword: "",
      });
    }
  }

  renderChatContainer() {
    const {
      chatDetail,
      userInfo: { userId },
      chatSpecialSymbolList,
    } = this.props;
    const gifList = Object.values(chatSpecialSymbolList || {});
    const chatList = chatDetail ? Object.values(chatDetail) : [];
    return chatList.map((chat, index) => {
      const isSender = userId === chat.senderId;
      const time1 = moment(chat.timestamp).format("hh:mm");
      const isWelcomeMessage = chat.name === MESSAGE_TYPES.WELCOME_MESSAGE;
      let name = isWelcomeMessage ? "" : !isSender ? chat.name || "" : "You";
      const isGifMessage = !!chat.isGif;
      let gifUrl = "";
      const message = chat.message;

      if (isWelcomeMessage && !isSender) {
        return null;
      }

      if (isGifMessage && gifList.length) {
        const selected = gifList.find(
          (symbol) => symbol.keyword.toLowerCase() === message
        );
        if (selected && selected.animationUrl) {
          gifUrl = selected.animationUrl;
        }
      }

      let userNumber = 1;
      const isUrl = checkIsValidURL(message);
      let urlText = message;
      if (isUrl && !urlText.includes("://")) {
        urlText = "https://".concat(urlText);
      }

      return (
        <div key={index} className="msg-box">
          <div
            className={cx("chat-msgbox", `user-${userNumber}`, {
              me: isSender && !isWelcomeMessage,
            })}
          >
            <p className="person">{name}</p>
            <p className="msg">
              {chat.isImage ? (
                <img
                  src={chat.message}
                  style={{ maxHeight: 150, maxWidth: 150 }}
                  alt=""
                ></img>
              ) : isUrl ? (
                <a href={urlText} target={"_blank"} rel="noopener noreferrer">
                  {chat.message}
                </a>
              ) : isWelcomeMessage ? (
                <span>
                  {chat.message}
                  <img src={fireIcon} alt="fire" />
                </span>
              ) : gifUrl ? (
                <img
                  src={gifUrl}
                  style={{ maxHeight: 72, maxWidth: 72 }}
                  alt=""
                ></img>
              ) : (
                chat.message
              )}
            </p>
            <p className="time">{time1}</p>
          </div>
        </div>
      );
    });
  }

  getTotalFriends = (joinedFriends) => {
    const {
      userInfo: { userId },
    } = this.props;

    let totalFriends = Object.keys(joinedFriends);
    const list = totalFriends.filter((friend) => friend !== userId);
    return list.length;
  };

  handleCancelTimer = () => {
    this.setState({
      isTimer: false,
    });
  };

  render() {
    const {
      newMessage,
      isSending,
      attachmentUrl,
      gifUrl,
      isOpenGiphyView,
      isTimer,
      isShowAnimationsSetupModal,
      chatAnimationStatus
    } = this.state;
    const {
      userInfo: { callTimer },
    } = this.props;
    const timerVal = callTimer ? callTimer.value / 60 : "";
    const timerAction = callTimer ? callTimer.status : "";

    let totalFriends = this.getTotalFriends(this.state.joinedFriends);
    return (
      <div className="chat-box">
        <audio id="messageTone" src={messageTone} />
        <div className="chat-area">
          <div className="h-100 d-flex flex-column chat-view-wrapper">
            <div
              id="chat-scroll-view"
              className={cx("bg-white chat-view ", { isTimer })}
            >
              {this.renderChatContainer()}
            </div>
            {isTimer && (
              <TimerComponent
                {...this.props}
                value={timerVal}
                actionType={timerAction}
                onCancel={this.handleCancelTimer}
              />
            )}
            {isShowAnimationsSetupModal && <AnimationsSetup {...this.props} value={chatAnimationStatus} onClose={this.handleAnimationsSetupModal}/>}
          </div>
        </div>

        <FormGroup className="rounded-input inputWrapper mb-0">
          {attachmentUrl && (
            <div
              className="d-flex align-items-center position-absolute"
              style={{ top: "50%", left: 10, transform: "translateY(-50%)" }}
            >
              <img
                src={attachmentUrl}
                alt=""
                style={{ maxHeight: 30, maxWidth: 200 }}
              />
              <button
                className="btn-icon"
                disabled={isSending}
                onClick={this.onClearAttachment}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          )}
          {gifUrl && (
            <div
              className="d-flex align-items-center position-absolute"
              style={{ top: "50%", left: 10, transform: "translateY(-50%)" }}
            >
              <img
                src={gifUrl}
                alt=""
                style={{ maxHeight: 30, maxWidth: 200 }}
              />
              <button
                className="btn-icon"
                disabled={isSending}
                onClick={this.onClearGifSelection}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          )}
          <Input
            placeholder={
              attachmentUrl || gifUrl
                ? ""
                : isSending
                ? "Sending..."
                : "Share something..."
            }
            value={newMessage}
            autoFocus
            innerRef={(input) => {
              this.inputFieldRef = input;
            }}
            disabled={isSending}
            id="messagetextbox"
            onKeyDown={(e) => {
              e.key === "Enter" && this.handleSendMessage(totalFriends);
            }}
            onChange={this.handleOnChange}
          ></Input>
          <button
            className="btn-icon smileBtn"
            title={`Click to ${
              isOpenGiphyView ? "close" : "open"
            } giphy keyboard.`}
            onClick={this.onToggleGifView}
          >
            <img src={smileIcon} alt={"smile"} />
          </button>
          <GiphyView
            isOpen={isOpenGiphyView}
            onSelect={this.onSelectGif}
            isDisabled={chatAnimationStatus}
            onClose={this.onToggleGifView}
          />
        </FormGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginReducer,
    chatDetail: state.userChatReducer.chat,
    chatSpecialSymbolList: state.chatSpecialSymbols.specialSymbols,
    recentGifs: state.loginReducer.recentGifs,
  };
};

const mapDispatchToProps = {
  setUserChatDetail,
  setTimerData,
  setIsTimerStarted,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ChatComponent);
