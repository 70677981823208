import React, { Component, createRef } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Header from "./Header";
import classes from "./styles.module.scss";
import { withFirebase } from "../../firebase/context";
import { Call } from "../../pages";
import ScreenSharePopup from "../../components/screenSharePopup";
import * as loaderAction from "../../redux/loader/action";
import * as loginAction from "../../redux/login/action";
import Alert from "../../components/shared/common/alert";
import { isChromeBrowser } from "../shared/utils";
import { ROUTES, SHARED_LINK_SEARCH_PARAMS } from "../shared/constants";
import { setTimerData } from "../../redux/login/action";
import { setIsTimerStarted } from "../../redux/login/action";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.callRef = createRef();

    const {
      userInfo: {
        userId,
        isGuestMode,
        isGuestJoined,
        currentCallId,
        askToJoinCallNotifications,
      },
      firebase,
    } = props;

    //To reset call state if new session
    const sessionCallId = sessionStorage.getItem("currentCallId");

    const path = window.location.pathname;
    let id = "";
    if (path && path.includes("call")) {
      id = path.split("/call/")[1];
    }

    if (
      (!sessionCallId && isGuestMode && isGuestJoined) ||
      (id && sessionCallId && id !== sessionCallId) ||
      (isGuestMode && !sessionCallId && userId)
    ) {
      if (isGuestMode) {
        if (!sessionCallId && userId) {
          firebase.deleteUser(userId).then(() => {
            const notificationKey = id
              ? Object.keys(askToJoinCallNotifications || {}).find(
                  (key) =>
                    askToJoinCallNotifications[key].callId === id &&
                    !askToJoinCallNotifications[key].isRead
                )
              : "";
            if (notificationKey) {
              const notification = askToJoinCallNotifications[notificationKey];

              firebase.deleteUserAskToJoinCallNotification(
                notification.hostId,
                notification.userId
              );

              this.props.setUserAskJoinCallNotifications({});
            }
          });
        }
        this.props.setGuestJoinedMode({
          userId: "",
          username: "",
          status: false,
        });
      }

      if (currentCallId) {
        this.props.setOrUnsetCurrentCall({
          call_status: "",
          id: "",
        });
      }
    }
  }

  onStopScreenShareMode = () => {
    const { current } = this.callRef;
    if (current && current.onStopScreenShare) {
      current.onStopScreenShare();
    } else {
      Alert(400, "Unable to stop screen sharing. Please try again.");
    }
  };

  renderLayout() {
    const { children, isLoggedIn = false, history } = this.props;

    const path = this.props.location.pathname;
    const adminRoutes = Object.values(ROUTES.admin);
    const userRoutes = Object.values(ROUTES.user);
    let isNotFoundRoute =
      !adminRoutes.includes(path) && !userRoutes.includes(path);

    let location = "";
    let style = {};
    let isCallView = false;

    if (history && history.location && history.location.pathname) {
      location = history.location.pathname;
    }

    let isGuestCallLink = false;
    if (location.includes("call")) {
      const callHash = history.location.search;
      isGuestCallLink =
        callHash &&
        callHash.includes("?" + SHARED_LINK_SEARCH_PARAMS.CALL_HASH);
    }

    const isCallMode =
      !!sessionStorage.getItem("currentCallId") || isGuestCallLink;

    let isPopupRequired = false;
    if (!location.includes("call")) {
      style = { display: "none" };
    } else {
      isPopupRequired = !isChromeBrowser();
      isCallView = true;
    }

    return (
      <>
        {(!isNotFoundRoute || isCallView) && (
          <Header isLoggedIn={isLoggedIn} isGuestCallLink={isGuestCallLink} />
        )}
        <main className={classes.main}>{children}</main>
        {isCallMode && (
          <>
            <div style={style}>
              <Call ref={this.callRef} {...this.props} />
            </div>
            {isPopupRequired && (
              <ScreenSharePopup
                onStop={this.onStopScreenShareMode}
                isCallView={isCallView}
              />
            )}
          </>
        )}
      </>
    );
  }

  render() {
    return <div className={classes.root}>{this.renderLayout()}</div>;
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
  chatSpecialSymbolList: state.chatSpecialSymbols.specialSymbols,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setCallEndAnimationType: (data) =>
      dispatch(loginAction.setCallEndAnimationType(data)),
    setScreenShareMode: (data) =>
      dispatch(loginAction.setScreenShareMode(data)),
    setGuestJoinedMode: (data) =>
      dispatch(loginAction.setGuestJoinedMode(data)),
    resetUser: (data) => dispatch(loginAction.setLogOut(data)),
    setUserAskJoinCallNotifications: (data) =>
      dispatch(loginAction.setUserAskJoinCallNotifications(data)),
    setAnonymousLogin: (data) => dispatch(loginAction.setAnonymousLogin(data)),
    setResumeTimerValue: (data) => dispatch(loginAction.setResumeTimerValue(data)),
    setTimerData: (data) => dispatch(loginAction.setTimerData(data)),
    setIsTimerStarted: (data) => dispatch(loginAction.setIsTimerStarted(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Layout);
