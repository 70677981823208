import React from "react";
import { connect } from "react-redux";
import { CALL_END_ANIMATION_TYPE } from "../../../../components/shared/constants";
const CallDisconnectAnimationView = (props) => {
  const { username, animationType } = props;
  if (!username || !animationType) {
    return null;
  }

  let animationUrl = "";
  let message = "";

  if (props.callEndAnimations) {
    const animationTypeDetail = props.callEndAnimations[animationType];

    if (animationTypeDetail) {
      animationUrl = animationTypeDetail.animationUrl;
      message = animationTypeDetail.description.concat(" and left the call");
      if (animationTypeDetail.title === CALL_END_ANIMATION_TYPE.smokeBomb) {
        const callAudioEl = document.getElementById("endCallToneForNinja");
        callAudioEl.play();
      }
      if (animationTypeDetail.title === CALL_END_ANIMATION_TYPE.peaceOut) {
        const callAudioEl = document.getElementById("endCallToneForSurfingPig");
        callAudioEl.play();
      }
    } else if (animationType === CALL_END_ANIMATION_TYPE.NORMAL_CALL_END) {
      const callAudioEl = document.getElementById("endNormalCall");
      callAudioEl.play();
    }
  }

  return (
    <div className="cheers disconnect">
      {animationUrl && <img src={animationUrl} alt={""} />}
      {animationUrl && (
        <p>
          <span className="text-capitalize">{username}</span> {message}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    callEndAnimations: state.callEndAnimations.animations,
  };
};

export default connect(mapStateToProps)(CallDisconnectAnimationView);
