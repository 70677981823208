import DEV_CONFIG from "./development";
import PROD_CONFIG from "./production";

const ENV_TYPE = {
  DEV: "DEV",
  PROD: "PROD",
};

export const appConfig =
  process.env.REACT_APP_ENVIRONMENT === ENV_TYPE.DEV ? DEV_CONFIG : PROD_CONFIG;
