import app from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
import { FILE_STORAGE_PATH, SCHEMA } from '../components/shared/constants';
import { appConfig } from '../config';

const config = {
  apiKey: appConfig.API_KEY,
  authDomain: appConfig.AUTH_DOMAIN,
  databaseURL: appConfig.DATABASE_URL,
  projectId: appConfig.PROJECT_ID,
  storageBucket: appConfig.STORAGE_BUCKET,
  messagingSenderId: appConfig.MESSAGING_SENDER_ID,
  appId: appConfig.APP_ID,
  measurementId: appConfig.MEASUREMENT_ID,
};

// const fireStoreSettings = {
//     timestampsInSnapshots: true
// };

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Firebase APIs */
    this.auth = app.auth();
    this.database = app.database();
    // this.firestore = app.firestore();
    // this.fire.settings(fireStoreSettings);
    this.storage = app.storage();
    this.func = app.functions();
    this.database = app.database();
    app.analytics();

    /**
     * For authentication with facebook account.
     */
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  firestore = () => app.firestore();

  getDatabase = () => this.database;

  chat_rooms = () => this.database.ref('chat_room');

  users = () => this.database.ref('users');
  notifications = () => this.database.ref('notifications');
  testVideoChat = (id) => this.database.ref('testVideoChat/' + id);
  onSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  onSignOut = () => {
    if (this.refreshDataRef) {
      this.refreshDataRef.off('value', this.refreshDataRefCallback);
      this.refreshDataRef = null;
    }

    // off the call notifications
    if (this.getUserCalRef) {
      this.getUserCalRef.off('value', this.getUserCallNoRefCallback);
      this.getUserCalRef = null;
    }

    // off the normal notifications
    if (this.getUserNotRef) {
      this.getUserNotRef.off('value', this.getUserNoRefCallback);
      this.getUserNotRef = null;
    }

    // if (this.getUserEventsRef) {
    //   this.getUserEventsRef.off("value", this.getUserEventsRefCallback);
    //   this.getUserEventsRef = null;
    //   this.getUserEventsRefCallback = null;
    // }

    if (this.getUserNewEventRef) {
      this.getUserNewEventRef.off('value', this.getUserNewEventRefCallback);
      this.getUserNewEventRef = null;
      this.getUserNewEventRefCallback = null;
    }

    // if (this.getUserFriendsRef) {
    //   this.getUserFriendsRef.off("value", this.getUserFriendsRefCallback);
    //   this.getUserFriendsRef = null;
    // }

    if (this.dbCon) {
      this.dbCon.off('child_added', this.getUserAllNotRefCallback);
      this.dbCon = null;
    }

    if (this.showChatMessagesRef) {
      this.showChatMessagesRef.off('value', this.showChatMessagesRefCallback);
      this.showChatMessagesRefCallback = null;
      this.showChatMessagesRef = null;
    }

    if (this.getCurrentCallUsersRef) {
      this.getCurrentCallUsersRef.off(
        'value',
        this.getCurrentCallUsersRefCallback
      );
      this.getCurrentCallUsersRefCallback = null;
      this.getCurrentCallUsersRef = null;
    }

    if (this.getAlertsRef) {
      this.getAlertsRef.off('value', this.getAlertsRefCallback);
      this.getAlertsRefCallback = null;
      this.getAlertsRef = null;
    }

    if (this.onShowCheersAnimationRef) {
      this.onShowCheersAnimationRef.off(
        'value',
        this.onShowCheersAnimationRefCallback
      );
      this.onShowCheersAnimationRefCallback = null;
      this.onShowCheersAnimationRef = null;
    }

    if (this.refreshChatSymbolsRef) {
      this.refreshChatSymbolsRef.off(
        'value',
        this.refreshChatSymbolsRefCallback
      );
      this.refreshChatSymbolsRefCallback = null;
      this.refreshChatSymbolsRef = null;
    }
    if (this.getScreenAnimationRef) {
      this.getScreenAnimationRef.off(
        'value',
        this.getScreenAnimationRefCallback
      );
      this.getScreenAnimationRefCallback = null;
      this.getScreenAnimationRef = null;
    }

    if (this.showTimerRef) {
      this.showTimerRef.off('value', this.showTimerRefCallback);
      this.showTimerRefCallback = null;
      this.showTimerRef = null;
    }

    if (this.getCallEndAnimationRef) {
      this.getCallEndAnimationRef.off(
        'value',
        this.getCallEndAnimationRefCallback
      );
      this.getCallEndAnimationRefCallback = null;
      this.getCallEndAnimationRef = null;
    }

    if (this.getRecentChatSymbolRef) {
      this.getRecentChatSymbolRef.off('value', this.showTimerRefCallback);
      this.getRecentChatSymbolCallback = null;
      this.showTimerRef = null;
    }

    if (this.getUserDetailRef) {
      this.getUserDetailRef.off('value', this.getUserDetailRefCallback);
      this.getUserDetailRefCallback = null;
      this.getUserDetailRef = null;
    }

    this.auth.signOut();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  authorization = () => app.auth();

  setData = (schema) => this.database.ref().child(schema);
  getData = (schema, callback) => {
    const data = this.database.ref(schema);
    const callbackRef = data.once('value', (snapshot) => {
      callback(snapshot.val());
    });
    return callbackRef;
  };

  checkUsernameExist = (username, callback) => {
    const data = this.database
      .ref(SCHEMA.USERS)
      .orderByChild('username')
      .equalTo(username);
    const callbackRef = data.once('value', (snapshot) => {
      callback(snapshot.val());
    });
    return callbackRef;
  };

  getUserCallNotifications = (id, callback) => {
    const data = this.database
      .ref(SCHEMA.USERS + '/' + id + '/call_notifications')
      .orderByChild('status')
      .equalTo('PENDING');
    this.CallNotificationsRef = data.once('value', (snapshot) => {
      callback(snapshot.val());
    });
    return this.CallNotificationsRef;
    // this.getUserCalRef = this.database
    // .ref(SCHEMA.USERS + "/" + id + "/call_notifications")
    // .orderByChild("status")
    // .equalTo('PENDING');
  };

  refreshData = (schema, callback) => {
    this.refreshDataRef = this.database.ref(schema);

    this.refreshDataRefCallback = this.refreshDataRef.on(
      'value',
      (snapshot) => {
        callback(snapshot.val());
      }
    );
  };

  getUserEvents = (query, callback) => {
    this.getUserEventsRef = this.database
      .ref(SCHEMA.USER_EVENTS)
      .orderByChild('invited/' + query)
      .equalTo(query);

    this.getUserEventsRefCallback = this.getUserEventsRef.once(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback(null);
        }
      }
    );

    return {
      ref: this.getUserEventsRef,
      callback: this.getUserEventsRefCallback,
    };
  };

  getUserNewEvent = (query, callback) => {
    this.getUserNewEventRef = this.database
      .ref(SCHEMA.USER_EVENTS)
      .orderByChild('invited/' + query)
      .equalTo(query);

    this.getUserNewEventRefCallback = this.getUserNewEventRef.on(
      'child_added',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.key, snapshot.val());
        } else {
          callback(null);
        }
      }
    );

    return {
      ref: this.getUserNewEventRef,
      callback: this.getUserNewEventRefCallback,
    };
  };

  getUserEventDetail = (id, callback) => {
    this.database
      .ref(SCHEMA.USER_EVENTS + '/' + id)
      .once('value', async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback(null);
        }
      });
  };

  getUserUnreadNotifications = (id, callback) => {
    this.getUserNotRef = this.database
      .ref(SCHEMA.USERS + '/' + id + '/notifications')
      .orderByChild('isread')
      .equalTo(false);

    this.getUserNoRefCallback = this.getUserNotRef.on('value', async function (
      snapshot
    ) {
      if (snapshot.exists()) {
        callback(snapshot.val());
      } else {
        callback();
      }
    });
  };

  getCurrentChats = (callId, callback) => {
    this.callChats = this.database
      .ref(SCHEMA.CHAT)
      .orderByChild('currentCallId')
      .equalTo(callId);

    this.currentcallChats = this.callChats.on('value', async function (
      snapshot
    ) {
      if (snapshot.exists()) {
        callback(snapshot.val());
      } else {
        callback();
      }
    });
  };

  getUserUnreadCallNotifications = (id, callback) => {
    this.getUserCalRef = this.database
      .ref(SCHEMA.USERS + '/' + id + '/call_notifications')
      .orderByChild('status')
      .equalTo('PENDING');

    this.getUserCallNoRefCallback = this.getUserCalRef.on(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback();
        }
      }
    );
  };

  getCurrentCallUsers = (callId, callback) => {
    this.getCurrentCallUsersRef = this.database.ref(
      'calls/' + callId + '/invited'
    );

    this.getCurrentCallUsersRefCallback = this.getCurrentCallUsersRef.on(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback();
        }
      }
    );

    return {
      ref: this.getCurrentCallUsersRef,
      callback: this.getCurrentCallUsersRefCallback,
    };
  };

  getPresentCallUsers = (callId, callback) => {
    this.getCallUsersRef = this.database.ref('calls/' + callId + '/invited');

    this.getCallUsersRefCallback = this.getCallUsersRef.once(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback();
        }
      }
    );
  };

  getAcceptedUsersOfCall = (callId, callback) => {
    this.getaccepted = this.database
      .ref('calls/' + callId + '/invited')
      .orderByChild('status')
      .equalTo('ACCEPTED');
    this.getacceptedCall = this.getaccepted.once('value', async function (
      snapshot
    ) {
      if (snapshot.exists()) {
        callback(snapshot.val());
      } else {
        callback();
      }
    });
  };

  getIncomingPresentCallUsers = (callId, callback) => {
    this.getCallUsersRef = this.database
      .ref('calls/' + callId + '/invited')
      .orderByChild('status')
      .equalTo('ACCEPTED');

    this.getCallUsersRefCallback = this.getCallUsersRef.once(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback();
        }
      }
    );
  };

  addVideoCallData = (userId, callId, obj) =>
    this.database
      .ref(SCHEMA.USERS + '/' + userId + '/calls/' + callId)
      .set(obj);

  getAllNewNotifications = (id, callback) => {
    this.dbCon = this.database.ref(SCHEMA.USERS + '/' + id + '/notifications');
    this.getUserAllNotRefCallback = this.dbCon.on(
      'child_added',
      (childSnapshot, prevChildKey) => {
        callback(childSnapshot.val());
      }
    );
  };

  setUserCallNotificationsToRead = (id) => {
    const dbCon = this.database.ref(
      SCHEMA.USERS + '/' + id + '/call_notifications'
    );
    dbCon.once('value', function (snapshot) {
      snapshot.forEach(function (child) {
        child.ref.update({
          isread: true,
        });
      });
    });
  };

  setUserNotificationToRead = (id) => {
    const dbCon = this.database.ref(SCHEMA.USERS + '/' + id + '/notifications');
    dbCon.once('value', function (snapshot) {
      snapshot.forEach(function (child) {
        child.ref.update({
          isread: true,
        });
      });
    });
  };

  getUserCallData = async (callId, callback) => {
    this.getUserCallRef = this.database.ref('calls/' + callId);

    this.getUserFriendsRefCallback = await this.getUserCallRef.once(
      'value',
      (snapshot) => {
        callback(snapshot.val() || {});
      }
    );
  };

  getCurrentUserDetail = (id, callback) => {
    this.getUserDetailRef = this.database.ref(
      SCHEMA.USERS + '/' + id
    );

    this.getUserDetailRefCallback = this.getUserDetailRef.on(
      'value',
      (snapshot) => {
        console.log('getCurrentUserDetail snapshot:', snapshot)
        callback(snapshot.val() || {});
      }
    );
  };

  getUserFriendsData = (id, callback) => {
    this.getUserFriendsRef = this.database.ref(
      SCHEMA.USERS + '/' + id + '/friends'
    );

    this.getUserFriendsRefCallback = this.getUserFriendsRef.on(
      'value',
      (snapshot) => {
        callback(snapshot.val() || {});
      }
    );
  };

  getUserNotificationData = (schema, id, callback) => {
    const data = this.database.ref(schema + '/' + id + '/friends');

    data.once('value', (snapshot) => {
      callback(snapshot.val());
    });
  };

  deleteAdminEvent = (eventId) => {
    const ref = this.database.ref('events/' + eventId).remove();
    return ref;
  };

  removeUserFromInvitedCall = (callId, userId) => {
    this.database.ref('calls/' + callId + '/invited/' + userId).remove();
  };

  clearUserAlerts = (id) =>
    this.database.ref('users/' + id + '/alert').remove();

  removeUserFriendsData = (schema, id, friendId) =>
    this.database.ref(schema + '/' + id + '/friends/' + friendId).remove();

  updateUserFriendsData = (schema, id, friendId, data) =>
    this.database.ref(schema + '/' + id + '/friends/' + friendId).update(data);

  updateUserCallData = (schema, id, callId, friendId, data) =>
    this.database
      .ref(
        schema +
          '/' +
          id +
          '/calls/' +
          callId +
          '/' +
          'invited' +
          '/' +
          friendId
      )
      .update(data);

  getUserNotifications = (schema, id, callback) => {
    const data = this.database
      .ref(schema + '/' + id + '/notifications')
      .orderByChild('status')
      .equalTo('PENDING');
    data.once('value', (snapshot) => {
      callback(snapshot.val());
    });
  };

  updateUserNotificationData = (schema, id, notificationId, data) =>
    this.database
      .ref(schema + '/' + id + '/notifications/' + notificationId)
      .update(data);

  updateUserCallNotificationData = (schema, id, notificationId, data) =>
    this.database
      .ref(schema + '/' + id + '/call_notifications/' + notificationId)
      .update(data);

  updateData = (schema, id, data) =>
    this.database.ref(schema + '/' + id).update(data);

  addUserFriends = (schema, id, data) =>
    this.database.ref(schema + '/' + id + '/friends').push(data);

  addUserNotification = (schema, id, data) =>
    this.database.ref(schema + '/' + id + '/notifications').push(data);

  addUserCallNotification = (schema, id, data) =>
    this.database.ref(schema + '/' + id + '/call_notifications').push(data);

  addUserCall = (schema, data) => this.database.ref(schema).push(data);

  getUserCall = (callId, callback) => {
    this.getUserEventCallRef = this.database
      .ref('calls/' + callId)
      .once('value', (res) => {
        callback(res.val());
      });
    return this.getUserEventCallRef;
  };
  addUserEventCall = (schema, callId, data) =>
    this.database.ref(schema + '/' + callId).set(data);

  updateUserCall = (callId, data) =>
    this.database.ref('calls/' + callId + '/invited/').update(data);

  addUserVideoCallFriends = (schema, id, callId, userToInviteId, data) =>
    this.database
      .ref(
        schema +
          '/' +
          id +
          '/calls' +
          '/' +
          callId +
          '/invited' +
          '/' +
          userToInviteId
      )
      .set(data);

  addCall = (callId, userToInviteId, data) =>
    this.database
      .ref('calls/' + callId + '/invited/' + userToInviteId)
      .set(data);
  updateCall = (callId, userToInviteId, data) =>
    this.database
      .ref('calls/' + callId + '/invited/' + userToInviteId)
      .update(data);

  checkIfMergedCall = (userId, callback) => {
    this.database.ref('merged_calls/' + userId).on('value', (snapshot) => {
      callback(snapshot.val());
    });
  };

  removeMergedObj = (userId, callId) =>
    this.database.ref('merged_calls/' + userId + '/' + callId).remove();

  keepUserInMergedCall = (userId, data) =>
    this.database.ref('merged_calls/' + userId).update(data);

  setStatusOfUserInCall = (callId, userId, data) =>
    this.database.ref('calls/' + callId + '/invited/' + userId).update(data);

  getQueryData = (schema, query, orderByChildName, callback) => {
    this.database
      .ref(schema)
      .orderByChild(orderByChildName)
      .equalTo(query)
      .once('value', async function (snapshot) {
        if (snapshot.exists()) {
          callback({
            err: 'Record already exists.',
            id: Object.keys(snapshot.val()),
          });
        } else {
          callback({
            err: null,
            id: [],
          });
        }
      });
  };

  /**
   * {
   *  @param {string} senderId
   *  @param {string} message
   *  @param {number} timestamp
   *  @param {object} invited
   * }
   */
  saveChatToDB = (callId, data) =>
    this.database.ref(SCHEMA.CHAT + '/' + callId).push(data);

  showChatMessagesOnce = (id, callId, callback) => {
    this.showChatMessagesRefOnce = this.database
      .ref(SCHEMA.CHAT + '/' + callId)
      .orderByChild('invited/' + id)
      .startAt(id)
      .endAt(id + '\uf8ff');

    this.showChatMessagesRefCallbackOnce = this.showChatMessagesRefOnce.once(
      'value',
      async function (snapshot) {
        callback(snapshot.val() || null);
      }
    );

    return {
      ref: this.showChatMessagesRefOnce,
      callbackRef: this.showChatMessagesRefCallbackOnce,
    };
  };

  showChatMessages = (id, callId, callback) => {
    this.showChatMessagesRef = this.database
      .ref(SCHEMA.CHAT + '/' + callId)
      .orderByChild('invited/' + id)
      .startAt(id)
      .endAt(id + '\uf8ff');

    this.showChatMessagesRefCallback = this.showChatMessagesRef.on(
      'value',
      async function (snapshot) {
        callback(snapshot.val() || null);
      }
    );

    return {
      ref: this.showChatMessagesRef,
      callbackRef: this.showChatMessagesRefCallback,
    };
  };

  setAlert = (toId, alertType, fromId, data) => {
    this.database
      .ref(SCHEMA.USERS + '/' + toId + '/alert/' + alertType + '/' + fromId)
      .set(data);
  };

  removeAlert = (toId, alertType, fromId) => {
    this.database
      .ref(SCHEMA.USERS + '/' + toId + '/alert/' + alertType + '/' + fromId)
      .remove();
  };

  getAlerts = (userId, callback) => {
    this.getAlertsRef = this.database.ref(
      SCHEMA.USERS + '/' + userId + '/alert'
    );

    this.getAlertsRefCallback = this.getAlertsRef.on(
      'child_added',
      async function (snapshot) {
        if (snapshot) {
          callback(snapshot.val());
        } else {
          callback(null);
        }
      }
    );

    return {
      ref: this.getAlertsRef,
      callbackRef: this.getAlertsRefCallback,
    };
  };

  checkCallNotificationStatus = (id, userId, callback) => {
    this.database
      .ref(SCHEMA.USERS + '/' + userId + '/call_notifications/' + id)
      .once('value', async function (snapshot) {
        if (snapshot) {
          callback(snapshot.val());
        } else {
          callback(null);
        }
      });
  };

  deleteEventImagesFromStorage = (eventName, path) => {
    this.storage
      .ref(FILE_STORAGE_PATH.event_photos + eventName + '/' + path)
      .delete()
      .then(() => {})
      .catch((error) => {});
  };

  showAnimatedChatMessages = (userId, callId, callback) => {
    this.showAniChatMessagesRef = this.database
      .ref(SCHEMA.CHAT + '/' + callId)
      .orderByChild('invited/' + userId)
      .equalTo(userId);

    this.showAniChatMessagesRefCallback = this.showAniChatMessagesRef.on(
      'child_added',
      async function (snapshot) {
        callback(snapshot.val());
      }
    );

    return {
      ref: this.showAniChatMessagesRef,
      callbackRef: this.showAniChatMessagesRefCallback,
    };
  };

  refreshChatSymbols = (schema, callback) => {
    this.refreshChatSymbolsRef = this.database.ref(schema);
    this.refreshChatSymbolsRefCallback = this.refreshChatSymbolsRef.on(
      'value',
      (snapshot) => {
        callback(snapshot.val());
      }
    );
  };

  deleteChatSymbol = (id) => {
    const ref = this.database
      .ref(SCHEMA.CHAT_SPECIAL_SYMBOLS + '/' + id)
      .remove();
    return ref;
  };

  deleteSymbolFromStorage = (path) => {
    this.storage
      .ref(
        FILE_STORAGE_PATH.chat_screen_animation + '/' + path + '/animated_image'
      )
      .delete()
      .then(() => {})
      .catch((error) => {});
  };

  getScreenAnimations = (callback) => {
    this.getScreenAnimationRef = this.database.ref(SCHEMA.SCREEN_ANIMATIONS);

    this.getScreenAnimationRefCallback = this.getScreenAnimationRef.on(
      'value',
      (snapshot) => {
        callback(snapshot.val());
      }
    );
  };

  getCallEndAnimations = (callback) => {
    this.getCallEndAnimationRef = this.database.ref(
      SCHEMA.CALL_DISCONNECT_ANIMATIONS
    );

    this.getCallEndAnimationRefCallback = this.getCallEndAnimationRef.on(
      'value',
      (snapshot) => {
        callback(snapshot.val());
      }
    );
  };

  setCallEndAnimationView = (callId, userId, data) =>
    this.database
      .ref(SCHEMA.CALLS + '/' + callId + '/invited/' + userId)
      .update(data);

  getUserDetail = (userId) =>
    this.database
      .ref(SCHEMA.USERS + '/' + userId)
      .once('value', (snapshot) => {});

  showTimer = (callId, callback) => {
    this.showTimerRef = this.database.ref(SCHEMA.CALLS + '/' + callId);

    this.showTimerRefCallback = this.showTimerRef.on('value', async function (
      snapshot
    ) {
      callback(snapshot.val());
    });
  };

  setTimer = (callId, data) =>
    this.database.ref(SCHEMA.CALLS + '/' + callId).update(data);

  setScreenShareMode = (callId, data) =>
    this.database.ref(SCHEMA.CALLS + '/' + callId).update(data);

  deleteUser = (id) => this.database.ref(SCHEMA.USERS + '/' + id).remove();

  deleteUserAskToJoinCallNotification = (id, notificationId) =>
    this.database
      .ref(
        SCHEMA.USERS +
          '/' +
          id +
          '/ask_join_call_notifications/' +
          notificationId
      )
      .remove();

  updateUserAskToJoinCallNotification = (id, notificationId, data) =>
    this.database
      .ref(
        SCHEMA.USERS +
          '/' +
          id +
          '/ask_join_call_notifications/' +
          notificationId
      )
      .update(data);

  getUserUnreadAskToJoinCallNotifications = (id, callback) => {
    this.getUserAskToJoinCalRef = this.database.ref(
      SCHEMA.USERS + '/' + id + '/ask_join_call_notifications'
    );

    this.getUserAskToJoinCallNoRefCallback = this.getUserAskToJoinCalRef.on(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          callback(snapshot.val());
        } else {
          callback();
        }
      }
    );
  };

  checkAskToJoinCallNotificationStatus = (id, userId, callback) => {
    this.database
      .ref(SCHEMA.USERS + '/' + userId + '/ask_join_call_notifications/' + id)
      .once('value', async function (snapshot) {
        if (snapshot) {
          callback(snapshot.val());
        } else {
          callback(null);
        }
      });
  };

  deleteWebmVideoFromStorage = (fileName) => {
    this.storage
      .ref(FILE_STORAGE_PATH.video_file + '/' + fileName)
      .delete()
      .then(() => {})
      .catch(() => {});
  };

  setRecentChatSymbol = (userId, data) => {
    this.database
      .ref(SCHEMA.USERS + '/' + userId + '/recentChatGifs')
      .push(data);
  };

  updateRecentChatSymbol = (userId, data, key) => {
    this.database
      .ref(SCHEMA.USERS + '/' + userId + '/recentChatGifs/' + key)
      .update(data);
  };

  getRecentChatSymbol = (userId, callback) => {
    this.getRecentChatSymbolRef = this.database.ref(
      SCHEMA.USERS + '/' + userId + '/recentChatGifs'
    );
    this.getRecentChatSymbolCallback = this.getRecentChatSymbolRef.on(
      'value',
      async function (snapshot) {
        callback(snapshot.val() || null);
      }
    );
  };

  checkIfRecentChatSymbolExist = (userId, keyword, callback) => {
    const data = this.database
      .ref(SCHEMA.USERS + '/' + userId + '/recentChatGifs')
      .orderByChild('name')
      .equalTo(keyword);
    const callbackRef = data.once('value', (snapshot) => {
      callback(snapshot.val());
    });
    return callbackRef;
  };

  setAudioButtonStatus = (callId, isMuted, userId) => {
    this.setAudioButtonStatusRef = this.database.ref(
      SCHEMA.CALLS + '/' + callId + '/invited/' + userId
    );
    this.setAudioButtonStatusRef.update({ isMuted: isMuted });
  };

  setTimerValue = (callId, userId , data) => {
     this.database.ref(
      SCHEMA.CALLS + '/' + callId + '/callValues/'
    )
    .update(data);
  };

  getTimerValue = (callId, callback) => {
     this.database.ref(
      SCHEMA.CALLS + '/' + callId + '/callValues/'
    )
    .on('value', async function (snapshot) {
      if (snapshot) {
        callback(snapshot.val());
      } else {
        callback(null);
      }
    });
  };

  deleteTimerValue = (callId) => {
     this.database.ref(
      SCHEMA.CALLS + '/' + callId + '/callValues/'
    ).remove();
  };
  
   updatePremiumAccountStatus = async (userId , data  ,callback) => {
     await this.database.ref(
      SCHEMA.USERS + '/' + userId + '/isPremium/'
    ).set(data);
    callback(true);
  };

 setCallStatus = (callId, path, status) => {
    this.database.ref(
     SCHEMA.CALLS + '/' + callId + path
   ).set(status);
 };
 
 getCallStatus = (callId , callback) => {
  this.database.ref(
   SCHEMA.CALLS + '/' + callId 
 )
 .on('value', (snapshot) => {
      callback(snapshot.val());
    });
};

getCallStatusOnce = (callId , callback) => {
  this.database.ref(
   SCHEMA.CALLS + '/' + callId 
 )
 .once('value', (snapshot) => {
      callback(snapshot.val());
    });
};


configureChatAnimationsFeature = (callId, status) => {
  this.database
    .ref(SCHEMA.CALLS + "/" + callId + "/animationStatus")
    .set({ isChatAnimationsDisabled: status });
};

getChatAnimationStatus = (callId, callback) =>
  this.database
    .ref(SCHEMA.CALLS + "/" + callId + "/animationStatus")
    .on("value", (snapshot) => {
      callback(snapshot.val());
    });
  
}



export default Firebase;
