import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import Alert from "../../components/shared/common/alert";
import { withFirebase } from "../../firebase/context";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TickIcon, CancleIcon } from "../../components/svgIcons";
import { SCHEMA } from "../../components/shared/constants";
import { setIsLoading } from "../../redux/loader/action";
import avatarIcon from "../../assets/images/icons/avatar.svg";
import * as loginAction from "../../redux/login/action";
import * as loaderAction from "../../redux/loader/action";
import logo from "../../assets/images/logo.png";

const Notifications = (props) => {
  const [allNotifications, setNotifications] = useState({});
  const [statusArr, setNotificationStatus] = useState([]);
  const [isUpdate, setIsUpdate] = useState(undefined);

  useEffect(() => {
    props.setIsLoading(true);
    setIsUpdate(false);
    const loginUserData = props.loginReducer;

    props.firebase.setUserNotificationToRead(loginUserData.userId);
    props.setUserNotifications({});

    props.firebase.getUserNotifications(
      SCHEMA.USERS,
      loginUserData.userId,
      async (notifications) => {
        props.setIsLoading(false);
        if (notifications) {
          setNotifications(sortNotifications(notifications));
          props.setCount(false);
          setIsUpdate(true);
        } else {
          setIsUpdate(true);
          props.setIsLoading(false);
        }
      }
    );
  }, [props.loginReducer.isCountUpdated]);

  // notification set to (accept/reject) in db
  const updateNotificationsData = (index, status) => {
    const loginUserData = props.loginReducer;
    let keys = Object.keys(allNotifications);
    let selectedKey = keys[index];

    let obj = {
      status,
    };

    props.firebase
      .updateUserNotificationData(SCHEMA.USERS, loginUserData.userId, selectedKey, obj)
      .then(() => {
        let arr = statusArr.slice();
        arr.push({ key: selectedKey, status: status });
        setNotificationStatus(arr);
      })
      .catch(() => {});
  };

  const setFriendRequestStatusInCreator = (userId, friendId, status) => {
    const loginUserData = props.loginReducer;
    let obj = {
      status,
    };
    if (status === "ACCEPTED") {
      props.firebase.updateUserFriendsData(SCHEMA.USERS, userId, friendId, obj).then(() => {
        let obj = {
          userId: userId,
          status: "ACCEPTED",
        };

        props.firebase.addUserFriends(SCHEMA.USERS, loginUserData.userId, obj).then((resp) => {
          Alert(200, `${status} successfully`);
        });
      });
    } else {
      setIsLoading(true);
      props.firebase.removeUserFriendsData(SCHEMA.USERS, userId, friendId).then(() => {
        setIsLoading(false);
        Alert(200, `${status} successfully`);
      });
    }
  };

  const onAcceptOrReject = async (index, notification, status) => {
    await updateNotificationsData(index, status);
    await setFriendRequestStatusInCreator(notification.creatorId, notification.friendId, status);
  };

  const sortNotifications = (allNotifications) => {
    let y = [];
    for (var i in allNotifications) {
      y.push([i, allNotifications[i]]);
    }

    y.sort(function (a, b) {
      return b[1].timestamp - a[1].timestamp;
    });

    const arrLength = y.length;
    let temp = {};
    let k, key, value;

    for (let i = 0; i < arrLength; i++) {
      k = y[i];
      key = k[0];
      value = k[1];

      temp = Object.assign({}, temp, { [key]: value });
    }
    return temp;
  };

  const notificationValues = allNotifications ? Object.values(allNotifications) : [];

  const keys = Object.keys(allNotifications);
  let indexOfNot = -1;

  return (
    <section className="py-5">
      <Container>
        <div className="text-center mb-5">
          <img src={logo} alt="logo" style={{ height: 50 }} />
        </div>
        <div className="notificationsWrapper">
          {notificationValues && notificationValues.length > 0 ? (
            <div>
              {notificationValues.map((notification, index) => {
                if (statusArr && statusArr.length > 0) {
                  indexOfNot = statusArr.findIndex((arr) => arr.key === keys[index]);
                }
                if (notification.status === "PENDING") {
                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center mb-3 justify-content-between"
                    >
                      <div className="d-flex align-items-center">
                        <div className="pImg">
                          <img
                            className="person-img"
                            src={notification.photoUrl || avatarIcon}
                            alt=""
                          />
                        </div>
                        <p>{notification.title}</p>
                      </div>
                      {notification.type !== "EVENT_INVITE" && (
                        <div>
                          {indexOfNot === -1 ? (
                            <div className="btb-iconWrapper">
                              <button
                                onClick={() => {
                                  onAcceptOrReject(index, notification, "ACCEPTED");
                                }}
                                className="btn-icon mx-2 tick"
                              >
                                <TickIcon />
                              </button>
                              <button
                                onClick={() => onAcceptOrReject(index, notification, "REJECTED")}
                                className="btn-icon mx-2 cancel"
                              >
                                <CancleIcon />
                              </button>
                            </div>
                          ) : (
                            <b
                              className={` mx-2 accepted ${
                                statusArr[indexOfNot].status === "ACCEPTED"
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                            >
                              {statusArr[indexOfNot].status}
                            </b>
                          )}
                        </div>
                      )}
                    </div>
                  );
                }

                return null;
              })}
            </div>
          ) : (
            <div>
              <b>
                {isUpdate
                  ? "You have no notifications to display here."
                  : "Fetching new notifications..."}
              </b>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  loginReducer: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
    setCount: (data) => dispatch(loginAction.setCount(data)),
    setUserNotifications: (data) => dispatch(loginAction.setUserNotifications(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Notifications);
