import { handleUploadToFireBaseStorage } from "../../../components/shared/utils";
import { FILE_STORAGE_PATH } from "../../../components/shared/constants";
import Alert from "../../../components/shared/common/alert";

const ShareImageToFB = (props, callback) => {
  handleUploadToFireBaseStorage({
    imageFile: props.photoFile,
    folderName: FILE_STORAGE_PATH.facebook_share_url,
    storage: props.storage,
    onError: (error) => {
      console.log("handleUploadToFireBaseStorage Error: ", error)
      Alert(400, "Sorry, unable to share screenshot at this time.");
    },
    fileName: "bonfire_memory_" + Date.now(),
  })
    .then((url) => {
      if (url) {
        window.open(
          "https://www.facebook.com/sharer.php?u=" +
            encodeURIComponent(url) +
            "&t=" +
            encodeURIComponent("bonfire-connect"),
          "sharer",
          "toolbar=0,status=0,width=626,height=436"
        );
      } else {
        Alert(400, "Sorry, unable to share screenshot at this time.");
      }
      callback();
    })
    .catch(() => {
      Alert(400, "Sorry, unable to share screenshot at this time.");
      callback();
    });
};

export default ShareImageToFB;
