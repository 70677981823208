
import React from 'react';
import Routes from './routes';
import Loading from './components/shared/common/loader';
import {NotificationContainer} from 'react-notifications';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {configureStore} from './redux/store';
import 'react-notifications/lib/notifications.css';
const {store, persistor} = configureStore();


const App = () => (
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
        <Loading />
        <NotificationContainer />
      </PersistGate>
    </Provider>
);

export default App;

