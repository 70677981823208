import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { withFirebase } from "../../../firebase/context";
import Alert from "../../shared/common/alert";

class AlertComponent extends Component {
  componentDidMount() {
    const {
      firebase,
      userInfo: { userId },
    } = this.props;
    firebase.getAlerts(userId, (response) => {
      if (response) {
        const alerts = Object.values(response);
        alerts.forEach((alertData) => {
          if (alertData) {
            const username = alertData.username;
            Alert(
              400,
              username.charAt(0).toUpperCase() + username.slice(1) + " is busy."
            );
            firebase.removeAlert(
              userId,
              alertData.status.toLowerCase(),
              alertData.userId
            );
          }
        });
      }
    });
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginReducer,
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(AlertComponent);
