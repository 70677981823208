import React, { Component } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { compose } from "redux";
import { connect } from "react-redux";

import * as loaderAction from "../../../redux/loader/action";
import { EMAIL_NOTIFICATION } from "../../shared/constants";
import Alert from "../../shared/common/alert";
import Validation from "../../shared/common/validationMethods";

class ContactUsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSend = this.onSend.bind(this);
  }

  onChange(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }

  onSend() {
    const { name, email, message } = this.state;

    if (!name || !email || !message) {
      Alert(400, "Please fill all fields!!");
      return;
    }

    if (!Validation.validateEmail(email)) {
      Alert(400, "Please enter valid email address!!");
      return;
    }

    this.props.setIsLoading(true);
    this.onSendInviteEmail(email, name, message);
  }

  onSendInviteEmail = (email, username, message) => {
    const text = `<div><p>Name: <b>${username}</b></p><p>Email: <b>${email}</b></p><p>Message:</p><p>${message}</p></div>`;

    const msg = {
      to: EMAIL_NOTIFICATION.CONTACT_US_TO,
      from: "no-reply@ourbonfire.com",
      subject: "Inquiry from ourBonfire.com",
      text: text,
    };

    const body = JSON.stringify(msg);

    fetch(EMAIL_NOTIFICATION.INVITE_API, {
      method: "POST",
      body: "" + body,
    })
      .then((response) => response.json())
      .then((json) => {
        Alert(200, "Email sent successfully.");
        this.props.setIsLoading(false);
        this.props.onClose();
      })
      .catch((err) => {
        Alert(400, "Unable to send email at this time.");
        this.props.setIsLoading(false);
      });
  };

  render() {
    const { name, email, message } = this.state;

    return (
      <div className="contactForm">
        <div className="content">
          <h3>Hi There!</h3>
          <p>We are here to help.</p>
          <p>
            Feel free to send us your questions, feedback or general inquiry.
          </p>
        </div>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            value={name}
            type="text"
            name="name"
            id="name"
            onChange={(e) => this.onChange(e, "name")}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            value={email}
            type="email"
            name="email"
            id="email"
            onChange={(e) => this.onChange(e, "email")}
          />
        </FormGroup>
        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            value={message}
            type="textarea"
            name="message"
            id="message"
            onChange={(e) => this.onChange(e, "message")}
          />
        </FormGroup>
        <Button color="primary" onClick={this.onSend}>
          Send
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
  };
};

export default compose(connect(null, mapDispatchToProps))(
  ContactUsFormComponent
);
