import React, { useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { withFirebase } from "../../../../firebase/context";
import { SCHEMA } from "../../../shared/constants";
import { setUsername } from "../../../../redux/login/action";
import { setIsLoading } from "../../../../redux/loader/action";
import Alert from "../../../shared/common/alert";

const UsernameViewComponet = (props) => {
  const { username, userId } = props;
  const [name, setName] = useState(username);
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEdit = () => setIsEditMode((prevState) => !prevState);

  const onCancel = () => {
    setName(username);
    setIsEditMode((prevState) => !prevState);
  };

  const saveUserName = () => {
    const userProfile = {
      username: name,
    };
    props.firebase
      .updateData(SCHEMA.USERS, userId, userProfile)
      .then(() => {
        console.log("updateUserName updated :", name);
        props.setUsername(name);
        props.setIsLoading(false);
        setIsEditMode(false);
        Alert(200, "Username updated sucessfully.");
      })
      .catch(() => {
        props.setIsLoading(false);
        setIsEditMode(false);
        Alert(400, "Unable to update username.");
      });
  };

  const updateUserName = () => {
    if (!name) {
      Alert(400, "Username should not be empty.");
      return;
    }

    props.setIsLoading(true);

    if (name.toLowerCase() === username.toLowerCase()) {
      props.setIsLoading(false);
      setIsEditMode(false);
      Alert(200, "Username updated sucessfully.");
      return;
    }

    props.firebase.checkUsernameExist(name, (res) => {
      if (res) {
        const key = Object.keys(res)[0];
        const val = Object.values(res)[0];
        if (
          key !== userId &&
          val.username.toLowerCase() === name.toLowerCase()
        ) {
          setIsEditMode(false);
          Alert(400, "Username alredy exists.");
        } else {
          saveUserName();
        }
      } else {
        saveUserName();
      }
    });
  };

  return (
    <span className="profile-name ">
      <div
        className={cx({
          ["d-flex align-items-center justify-content-center nameWrapper"]: !isEditMode,
        })}
      >
        {isEditMode ? (
          <input
            className="text-center"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        ) : (
          name
        )}
        <div className="d-flex align-items-center justify-content-center">
          {isEditMode && (
            <button
              className="btn-icon save mr-2"
              title="Save"
              onClick={updateUserName}
            >
              <i className="fas fa-check" />
            </button>
          )}
          <button
            className={cx("btn-icon", isEditMode ? "cancle" : "edit")}
            onClick={isEditMode ? onCancel : toggleEdit}
            title={isEditMode ? "Cancle" : "Edit"}
          >
            <i className={`fas fa-${isEditMode ? "times" : "pen"}`} />
          </button>
        </div>
      </div>
    </span>
  );
};

const mapDispatchToProps = {
  setUsername,
  setIsLoading,
};

export default compose(
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(UsernameViewComponet);
