import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import {
  AdminCreate,
  AdminMain,
  Events,
  EventCalender,
  ForgotPassword,
  Login,
  Notifications,
  SignUp,
  EventOption,
  EventDetails,
  PageNotFound,
  Welcome,
} from "../pages";
import feedback from "../pages/feedback/index";
import Layout from "../components/layout";
import { connect } from "react-redux";
import {
  ROUTES,
  SHARED_LINK_SEARCH_PARAMS,
} from "../components/shared/constants";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          rest.isLoggedIn &&
          props.location.pathname !== ROUTES.user.welcome
        ) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.user.welcome,
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRoutes = ({ component: Component, pageAccess, ...rest }) => {
  let adminAcess = false;
  let condition = true;
  if (pageAccess === "admin") {
    adminAcess = true;
  }

  if (rest.path.includes("admin")) {
    if (!adminAcess) {
      condition = false;
    }
  }

  let isGuestCallLink = false;
  if (rest.isGuestMode && window.location.pathname.includes("call")) {
    const callHash = window.location.search;
    isGuestCallLink =
      callHash && callHash.includes("?" + SHARED_LINK_SEARCH_PARAMS.CALL_HASH);
  }

  if (condition) {
    return (
      <Route
        {...rest}
        render={(props) =>
          rest.isLoggedIn || isGuestCallLink ? (
            <Component {...props} />
          ) : (
              <Redirect
                to={{
                  pathname: ROUTES.user.welcome,
                  state: { from: props.location },
                }}
              />
            )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (
            !rest.isLoggedIn &&
            props.location.pathname !== ROUTES.user.welcome
          ) {
            return (
              <Redirect
                to={{
                  pathname: ROUTES.user.welcome,
                  state: { from: props.location },
                }}
              />
            );
          }
          return <Redirect to={ROUTES.pageNotFound} />;
        }}
      />
    );
  }
};

const NotFound = () => {
  return <Route component={PageNotFound} />;
};

const Routes = (props) => {
  const { isLoggedIn, role: userRole, isGuestMode } = props.loginReducer;

  return (
    <BrowserRouter>
      <Layout isLoggedIn={isLoggedIn} isGuestMode={isGuestMode}>
        <Switch>
          <PublicRoute
            exact
            path={ROUTES.user.welcome}
            component={Welcome}
            isLoggedIn={isLoggedIn}
          />
          <PublicRoute
            exact
            path={ROUTES.user.login}
            component={Login}
            isLoggedIn={isLoggedIn}
          />
          <PublicRoute
            exact
            path={ROUTES.user.signup}
            component={SignUp}
            isLoggedIn={isLoggedIn}
          />
          <PublicRoute
            exact
            path={ROUTES.user.forgotPassword}
            component={ForgotPassword}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.admin.createEvent}
            component={AdminCreate}
            isLoggedIn={isLoggedIn}
            pageAccess={userRole}
          />
          <PrivateRoutes
            exact
            path={ROUTES.admin.editEvent}
            component={AdminCreate}
            isLoggedIn={isLoggedIn}
            pageAccess={userRole}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.eventsCalendar}
            component={EventCalender}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.admin.viewEvent}
            component={AdminMain}
            isLoggedIn={isLoggedIn}
            pageAccess={userRole}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.eventsCall}
            isGuestMode={isGuestMode}
            component={() => null}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.call}
            component={() => null}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.events}
            component={Events}
            isLoggedIn={isLoggedIn}
          />

          <PrivateRoutes
            exact
            path={ROUTES.user.dashboard}
            component={EventOption}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.notifications}
            component={Notifications}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.eventsDetails}
            component={EventDetails}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoutes
            exact
            path={ROUTES.user.feedback}
            component={feedback}
            isLoggedIn={isLoggedIn}
          />
          <NotFound />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  loginReducer: state.loginReducer,
});

export default connect(mapStateToProps, null)(Routes);
