import React from "react";
import { LoginForm } from "../../components/form";
import { Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

const LoginPageComponent = (props) => {
  const {
    history,
    location: { state },
  } = props;
  const handleOnClick = () => {
    history.push("/signup", { state });
  };

  return (
    <div className="main-form-wrapper login-form">
      <div className="w-100">
        <Card>
          <CardBody>
            <div className="p-sm-4">
              <center>
                <h2 className="mb-4 title">Bonfire</h2>
              </center>
              <LoginForm />
            </div>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardBody>
            <p className="text-center  mb-0 sign-up-link">
              Don't have an account?{" "}
              <span onClick={handleOnClick}>Sign up</span>
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default withRouter(LoginPageComponent);
