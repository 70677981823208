import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import cx from "classnames";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AddToCalendar from "react-add-to-calendar";

import Alert from "../../../components/shared/common/alert";
import avatarIcon from "../../../assets/images/icons/avatar.svg";
import { TickIcon } from "../../svgIcons";
import closeIcon from "../../../assets/images/icons/close.svg";
import {
  formatDateTime,
  cloneArrayOfObject,
  getTimeAfterAddHour,
} from "../../shared/utils";
import { SCHEMA } from "../../shared/constants";
import { withFirebase } from "../../../firebase/context";
import * as loginAction from "../../../redux/login/action";
import moment from "moment";

class EventDetailModal extends Component {
  constructor(props) {
    super(props);
    const { selectedEvent } = props;

    this.state = {
      selectedEvent: selectedEvent,
      invitedFriends: [],
      isFetched: false,
      link: "",
      isJoinLinkExpired: false,
      endTime: new Date(),
    };

    this.handleSelectVenue = this.handleSelectVenue.bind(this);
    this.onJoinEventVideoSession = this.onJoinEventVideoSession.bind(this);
  }

  setInitialState() {
    return new Promise(async (resolve, reject) => {
      const { selectedEvent } = this.state;
      const { firebase } = this.props;
      const friendsList = selectedEvent.invitedFriends;
      let lastFriendId = "";

      if (friendsList.length) {
        lastFriendId = friendsList[friendsList.length - 1];
      } else {
        this.setState({
          isFetched: true,
        });
      }

      for (const friendId of friendsList) {
        await firebase.getData(SCHEMA.USERS + "/" + friendId, (friendInfo) => {
          if (!friendInfo) {
            reject({ invitedFriends: [] });
            return;
          }

          const user = {
            userId: friendId,
            email: friendInfo.email,
            username: friendInfo.username,
            photoUrl: friendInfo.photoUrl,
            isConnected: true,
          };

          const friendList = cloneArrayOfObject(this.state.invitedFriends);
          friendList.push(user);

          this.setState({
            invitedFriends: friendList,
          });

          if (lastFriendId === friendId) {
            this.setState({
              isFetched: true,
            });
            resolve({ invitedFriends: friendList });
          }
        });
      }
    });
  }

  async componentDidMount() {
    await this.setInitialState()
      .then((response) => {
        this.setState({
          invitedFriends: response.invitedFriends,
          isFetched: true,
        });
      })
      .catch(() => {
        this.setState({
          isFetched: true,
        });
      });

    this.setInviteLink();

    this.videoSessionInterval = setInterval(() => {
      this.setInviteLink();
    }, 2000);
  }

  setInviteLink() {
    const { selectedEvent } = this.state;
    const joinEventAt = new Date(selectedEvent.dateTime).getTime();
    const tempExpiredTime = new Date(getTimeAfterAddHour(joinEventAt, 1));
    const joinEventExpiredAt = tempExpiredTime.getTime();

    this.setState({
      endTime: tempExpiredTime,
    });
    const currentTime = new Date().getTime();

    if (joinEventAt <= currentTime && currentTime <= joinEventExpiredAt) {
      this.setState({ link: "/call/" + selectedEvent.id });
    } else if (currentTime > joinEventExpiredAt) {
      this.setState({
        isJoinLinkExpired: true,
      });
      clearInterval(this.videoSessionInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.videoSessionInterval);
  }

  handleSelectVenue(url) {
    window.open(url, "_blank");
  }

  async onJoinEventVideoSession() {
    const userData = this.props.userInfo;

    if (userData && userData.currentCallId && userData.currentCallId !== "") {
      Alert(400, "End your current call first");
      return;
    }
    const { link } = this.state;
    const { selectedEvent } = this.state;
    let obj = {
      call_status: "EVENT_CALL",
      id: selectedEvent.id,
    };
    this.props.setOrUnsetCurrentCall(obj);
    if (link) {
      this.props.history.push(link, { isEventSession: true });
    } else {
      Alert(400, "Somthing went wrong.");
    }
  }

  renderLoaderView() {
    const { isFetched } = this.state;
    if (isFetched) {
      return null;
    }

    return <p>Fetching...</p>;
  }

  renderGoogleCalendarButton(title, startAt, endAt, venues) {
    const { invitedFriends } = this.state;
    let invitedUsers = "";
    for (const i in invitedFriends) {
      const invited = invitedFriends[i];
      invitedUsers += `<p>${Number(i) + 1}. ${invited.username}</p>`;
    }

    let venueList = "";
    let locationName = "";
    if (false && venues && venues.length) {
      locationName = venues[0].locationName;
      for (const i in venues) {
        const venue = venues[i];
        venueList += `<p>${Number(i) + 1}. ${venue.locationName}</p>`;
      }
    } else {
      locationName = "Order food/drinks from local restaurants";
      venueList += `<p>1. Order food/drinks from local restaurants (https://postmates.com/)</p>`;
      venueList += `<p>2. Order drinks from Saucey (https://www.saucey.com/)</p>`;
    }

    const desc = `<div><p><b>Friends invited to this event:</b><p>${invitedUsers}<p><b>Venues:</b><p>${venueList}</div>`;

    return (
      <div className="mb-3 rATC">
        <AddToCalendar
          buttonLabel="Add To Calendar"
          buttonTemplate={{ "calendar-plus-o": "left" }}
          event={{
            title,
            description: desc,
            location: locationName,
            startTime: moment(startAt).toISOString(),
            endTime: moment(endAt).toISOString(),
          }}
        />
      </div>
    );
  }

  render() {
    const { isOpen, toggle } = this.props;
    const {
      selectedEvent,
      isFetched,
      invitedFriends,
      link,
      isJoinLinkExpired,
      endTime,
    } = this.state;

    const timeOfEvent = formatDateTime(selectedEvent.dateTime);

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered className="eventModal">
        <ModalBody className="text-center">
          {this.renderLoaderView()}
          {isFetched && (
            <>
              <button onClick={toggle} className="modal-close">
                <img src={closeIcon} alt="close" />
              </button>
              <h3 className="title ">{selectedEvent.eventName}</h3>
              <p className="time-date">{timeOfEvent}</p>
              <div>
                {invitedFriends.map(
                  ({ photoUrl, username, isConnected = false }, index) => (
                    <div key={index} className="peronsOption">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            src={photoUrl || avatarIcon}
                            alt={username}
                            className="person-image"
                          />
                        </div>
                        <p className="mb-0 text-capitalize">{username}</p>
                      </div>
                      <button
                        className={cx("btn-icon", { "active": isConnected })}
                      >
                        <TickIcon />
                      </button>
                    </div>
                  )
                )}
              </div>
              <div>
                {isJoinLinkExpired && (
                  <p className="text-danger">
                    Sorry event video chat join time expired.
                  </p>
                )}
                {link && (
                  <Button
                    className="mb-3"
                    onClick={this.onJoinEventVideoSession}
                  >
                    Join video chat
                  </Button>
                )}
                {!isJoinLinkExpired &&
                  this.renderGoogleCalendarButton(
                    selectedEvent.eventName,
                    selectedEvent.dateTime,
                    endTime,
                    selectedEvent.venueList
                  )}
                <p className="b-title">Recommended Venue</p>
                <div className="eventWrapper">
                  <a href={"https://postmates.com/"} target="_blank" rel="noopener noreferrer">
                    <p className="eventName">
                      Order food/drinks from local restaurants
                    </p>
                  </a>
                  <a href={"https://www.saucey.com/"} target="_blank" rel="noopener noreferrer">
                    <p className="eventName">Order drinks from Saucey</p>
                  </a>
                  {false &&
                    selectedEvent.venueList.map(
                      ({ locationName, photoUrl, locationWebsite }, index) => (
                        <a href={locationWebsite} target="_blank" rel="noopener noreferrer">
                          <p key={index} className="eventName">
                            <img src={photoUrl} alt={"venue"} />
                            {locationName}
                          </p>
                        </a>
                      )
                    )}
                </div>
              </div>
            </>
          )}
        </ModalBody>
        {isFetched && (
          <ModalFooter style={{ display: "none" }}>
            <div className="w-100 m-0 d-flex align-items-center justify-content-between">
              <p className="mb-0">Going?</p>
              <div>
                <button className={cx({ "active": true })}>Yes</button>
                <button className={cx({ "active": false })}>No</button>
                <button className={cx({ "active": false })}>Maybe</button>
              </div>
            </div>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setCallCount: (data) => dispatch(loginAction.setCallCount(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(EventDetailModal);
