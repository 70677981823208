import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ClickAwayListener } from "../../../../util";
import GifItem from "./item";
import arrayMove from "array-move";

const GiphyViewComponent = (props) => {
  const { isOpen, isDisabled } = props;

  const scrollSection = () => {
    if (isOpen) {
      setTimeout(() => {
        const element = document.getElementById("gifScrollView");
        if (element) {
          element.scrollTo({ top: 55, behavior: "smooth" });
        }
      }, 500);
    }
  };


  useEffect(() => scrollSection());

  if (!isOpen) {
    return null;
  }

  const renderGifs = () => {
    const { chatSpecialSymbolList, onSelect, onClose, recentGifs } = props;
    let gifList = Object.values(chatSpecialSymbolList || {});
    recentGifs.forEach(gif => {
      gifList.forEach(item => {
        if (gif.name === item.keyword) {
          gifList = arrayMove(gifList, gifList.indexOf(item), recentGifs.indexOf(gif))
        }
      })
    })

    return (
      <ClickAwayListener onClickAway={onClose}>
        <div id="gifScrollView" className="smiles">
          {gifList.map((item, index) => {
            return (
              <div key={index} className="smileItems">
                <GifItem
                  onSelect={onSelect}
                  animationUrl={item.animationUrl}
                  keyword={item.keyword}
                />
              </div>
            );
          })}
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <div className="giphy-view">
      <div className="wrapper">
        {renderGifs()}
        {isDisabled && <div className="isDisabled"/>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatSpecialSymbolList: state.chatSpecialSymbols.specialSymbols,
    recentGifs: state.loginReducer.recentGifs
  };
};

export default connect(mapStateToProps)(GiphyViewComponent);
