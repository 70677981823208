/* eslint-disable */

// Constant
export const SET_NEW_USER_EVENT = "SET_NEW_USER_EVENT";
export const SET_USER_EVENTS = "SET_USER_EVENTS";
export const RESET_USER_EVENTS = "RESET_USER_EVENTS";
export const SET_USER_EVENT_SESSION_ID = "SET_USER_EVENT_SESSION_ID";

/**
 *
 * @param {Object} eventPayload
 * {
 *  @param {string} creatorId: Id of event creator user
 *  @param {string} eventId
 *  @param {string} dateTime
 *  @param {Array} invitedFriends : Array of invited friends
 *  {
 *    @param {string} userId
 *  }
 *  @param {Array} venueList : Array of selected venues
 *  {
 *    @param {string} locationWebsite
 *  }
 *
 * }
 *
 */
export const setNewUserEvent = (eventPayload) => ({
  type: SET_NEW_USER_EVENT,
  payload: eventPayload,
});

/**
 * @param {Array} eventPayload : Array of user event objects
 */
export const setUserEvents = (eventPayload) => ({
  type: SET_USER_EVENTS,
  payload: eventPayload,
});

/**
 */
export const resetUserEvents = () => ({
  type: RESET_USER_EVENTS,
});

/**
 * @param {Object} payload
 * {
 *  @param {string} id
 *  @param {string} key
 * }
 */
export const setUserEventSessionId = (payload) => ({
  type: SET_USER_EVENT_SESSION_ID,
  payload: payload,
});
