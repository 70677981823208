import React from "react";
import { Input } from "reactstrap";

import { ClickAwayListener } from "../../util";
import avatarIcon from "../../assets/images/icons/avatar.svg";

const EventFieldCardComponent = ({
  icon,
  alt = "Icon",
  placeholder = "",
  selectedList = [],
  imgKey,
  imgClassName = "",
  children,
  onFocus = () => {},
  onClose = () => {},
  onChange = () => {},
  onRemoveSelected = () => {},
}) => {
  return (
    <div className="d-flex align-items-center mb-4">
      <div className="eventIcon">
        <img src={icon} alt={alt} />
      </div>
      <ClickAwayListener onClickAway={onClose}>
        <div className="eventsInputs">
          <div className="inputwrapper">
            <Input
              placeholder={placeholder}
              onFocus={onFocus}
              onChange={onChange}
            />
            {children}
          </div>
          <div className="uploadedImg  ml-3">
            {selectedList.map((item, index) => {
              return (
                <div key={index} className="upload-icon">
                  <img
                    src={item[imgKey] || avatarIcon}
                    alt="Bonfire"
                    className={imgClassName}
                  />
                  <i
                    className="fas fa-times"
                    onClick={() => onRemoveSelected(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default EventFieldCardComponent;
