import Validation from "./validationMethods";
import Alert from "./alert";

export function isValidEmail(mail) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

export function isValidPassword(password) {
  return password.length >= 8;
}

export function isNumericOrFloat(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isNumeric(inputtxt) {
  var numbers = /^[0-9]+$/;
  if (inputtxt.match(numbers)) {
    return true;
  }
  return false;
}

/**
 * @method validateLoginFields : Method for login page validations.
 * @param {Object} data : Data to be validated.
 */
export const validateLoginFields = (data) => {
  if (data.email === "") {
    Alert(400, "Please enter mobile or email first ");
    return false;
  }
  if (data.email.includes("@") && !Validation.validateEmail(data.email)) {
    Alert(400, "Please enter a valid email address.");
    return false;
  }
  if (data.password === "" || !data.password) {
    Alert(400, "Please enter your password.");
    return false;
  }
  if (data.password && !Validation.passwordValidation(data.password)) {
    Alert(400, "Password must be a minimum of 4 characters in length.");
    return false;
  }
  return true;
};

export const validateForgotPasswordFields = (data) => {
  if (data.email === "") {
    Alert(400, "Please enter your email");
    return false;
  }
  if (data.email.includes("@") && !Validation.validateEmail(data.email)) {
    Alert(400, "Please enter a valid email address.");
    return false;
  }
  return true;
};

export const validateSignUpFields = (data) => {
  if (data.email === "" || !data.email) {
    Alert(400, "Please enter your email");
    return false;
  }
  if (data.username === "" || !data.username) {
    Alert(400, "Please enter username");
    return false;
  }
  if (data.email.includes("@") && !Validation.validateEmail(data.email)) {
    Alert(400, "Please enter a valid email address.");
    return false;
  }
  if (data.password === "" || !data.password) {
    Alert(400, "Please enter your password.");
    return false;
  }
  if (data.password && !Validation.passwordValidation(data.password)) {
    Alert(400, "Password must be a minimum of 4 characters in length.");
    return false;
  }
  return true;
};
