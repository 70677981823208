import {
  SET_USER_EVENT_CALL_LOCAL_STREAM,
  SET_USER_EVENT_CALL_DETAIL,
  RESET_USER_EVENT_CALL_DETAIL,
} from "./action";

const defaultState = {
  events: {
    localStream: null,
    // data: {
    //   id: '',
    //   link: '',
    //   invited: {
    //     'id' : 'id'
    //   }
    // }
  },
};

const userCallsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_EVENT_CALL_LOCAL_STREAM: {
      return {
        ...state,
        events: {
          ...state.events,
          localStream: action.payload,
        },
      };
    }

    case SET_USER_EVENT_CALL_DETAIL: {
      return {
        ...state,
        events: {
          ...state.events,
          data: action.payload,
        },
      };
    }

    case RESET_USER_EVENT_CALL_DETAIL: {
      return {
        events: {},
      };
    }

    default:
      return state;
  }
};

export default userCallsReducer;
