import React from "react";

import { TickIcon } from "../../../components/svgIcons";
import avatarIcon from "../../../assets/images/icons/avatar.svg";

export default ({ isOpen, friendList, isFetched, hasFriends, onSelect }) => {
  if (!isOpen) {
    return null;
  }

  const renderItem = (item, index) => {
    return (
      <div key={index} className="eventDetailList mb-3" onClick={() => onSelect(item)}>
        <div className="d-flex align-items-center content">
          <img src={item.photoUrl || avatarIcon} alt="Bonfire" className="userImg mr-2" />
          <p className="mb-0 text-capitalize">{item.username}</p>
        </div>

        <button className={`btb-icon ${item.isSelected ? "active" : ""}`}>
          <TickIcon />
        </button>
      </div>
    );
  };

  const renderEmptyList = () => {
    if (friendList.length) {
      return;
    }
    return (
      <div className="eventDetailList mb-3">
        <div className="d-flex align-items-center">
          <p className="mb-0" style={{ whiteSpace: "initial" }}>
            {!isFetched
              ? "Fetching friends record..."
              : !hasFriends
              ? "You don't have any friends."
              : "No friend available with this name."}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="eventDetailDropdown">
      <div className="eventscroll">
        {friendList.map((venue, index) => {
          return renderItem(venue, index);
        })}
        {renderEmptyList()}
      </div>
    </div>
  );
};
