import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase/context";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import avatarIcon from "../../../assets/images/icons/avatar.svg";
import { CallIcon } from "../../svgIcons";
import cx from "classnames";
import * as loginAction from "../../../redux/login/action";
import { SCHEMA } from "../../../components/shared/constants";
import { BasicLoader } from "../../shared/common/loader";
import Alert from "../../shared/common/alert";
import {
  ALERT_TYPE_STATUS,
  MAX_USER_SUPPORTED_FOR_CALL,
} from "../../shared/constants";
import callTone from "../../../assets/audio/incoming-call.mp3";

class IncomingCallModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenCallModal: false,
      isOpenProfileDropdown: false,
      isLoading: false,
      isUpdate: undefined,
      isFetched: false,
      statusArr: [],
      allCallNotifications: [],
    };
  }

  componentDidMount() {
    let props = this.props;
    this.setState({
      allCallNotifications: props.callNotifications,
    });

    this.clearNotificationInterval = setInterval(() => {
      this.onClearNotification(Object.keys(props.callNotifications));
    }, 2000);
    this.checkNotificationExpiry(Object.keys(props.callNotifications));
  }

  componentWillUnmount() {
    clearInterval(this.clearNotificationInterval);
    clearTimeout(this.clearNotificationTimeout);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      Object.keys(nextProps.callNotifications).length !==
      Object.keys(prevState.allCallNotifications).length
    ) {
      return {
        allCallNotifications: nextProps.callNotifications,
      };
    }

    return {};
  }

  onClearNotification(notificationsList) {
    const { callNotifications } = this.props;

    notificationsList.forEach(async (key, index) => {
      const notification = callNotifications[key];

      if (notification.status === "PENDING") {
        const {
          firebase,
          loginUserData: { userId, currentCallId },
        } = this.props;
        if (currentCallId === notification.callId) {
          await firebase.checkCallNotificationStatus(
            key,
            userId,
            async (res) => {
              if (res && res.status === "PENDING") {
                await this.updateNotificationsData(index, "ACCEPTED");
                await this.getAndaddCallObject(notification.callId, userId);
                this.props.close();
                clearInterval(this.clearNotificationInterval);
                clearTimeout(this.clearNotificationTimeout);
              } else {
                clearInterval(this.clearNotificationInterval);
                clearTimeout(this.clearNotificationTimeout);
              }
            }
          );
        }
      }
    });
  }

  checkNotificationExpiry = (notificationsList) => {
    const {
      callNotifications,
      firebase,
      loginUserData: { userId },
    } = this.props;

    notificationsList.forEach((key, index) => {
      const notification = callNotifications[key];

      if (notification.status === "PENDING") {
        const createAt = new Date(notification.timestamp);
        const expiredAt = new Date(
          createAt.setMinutes(createAt.getMinutes() + 1)
        );

        const currentTime = new Date();
        let waitTime = expiredAt.getTime() - currentTime.getTime();
        if (waitTime <= 0) {
          waitTime = 0;
        }

        this.clearNotificationTimeout = setTimeout(async () => {
          await firebase.checkCallNotificationStatus(
            key,
            userId,
            async (res) => {
              if (res && res.status === "PENDING") {
                await this.updateNotificationsData(index, "REJECTED");
                await this.onDeclineCall(notification);
                clearInterval(this.clearNotificationInterval);
              } else {
                clearInterval(this.clearNotificationInterval);
              }
            }
          );
        }, waitTime);
      }
    });
  };

  updateNotificationsData = async (index, status) => {
    let props = this.props;
    const { allCallNotifications, statusArr } = this.state;
    const loginUserData = props.loginUserData;
    let keys = Object.keys(allCallNotifications);
    let selectedKey = keys[index];

    let obj = {
      status,
    };

    await props.firebase
      .updateUserCallNotificationData(
        SCHEMA.USERS,
        loginUserData.userId,
        selectedKey,
        obj
      )
      .then(() => {
        let arr = statusArr.slice();

        arr.push({ key: selectedKey, status: status });
        props.reduceNotification(selectedKey, status);
        this.setState({
          statusArr: arr,
        });
      })
      .catch(() => {});
  };

  onDeclineCall = async (notification, isAlert = true) => {
    const { userId, username } = this.props.loginUserData;
    await this.props.firebase.removeUserFromInvitedCall(
      notification.callId,
      userId
    );

    if (isAlert) {
      const alertType = ALERT_TYPE_STATUS.DIRECT_CALL_DECLINED.toLowerCase();
      await this.props.firebase.setAlert(
        notification.creatorId,
        alertType,
        userId,
        {
          status: ALERT_TYPE_STATUS.DIRECT_CALL_DECLINED,
          username,
          userId,
          timestamp: Date.now(),
        }
      );
    }
  };

  onAcceptOrRejectVideoCall = async (index, notification, status) => {
    let props = this.props;
    const loginUser = props.loginUserData;

    if (status === "ACCEPTED") {
      // check if i am in  call
      if (loginUser.currentCallId && loginUser.currentCallId !== "") {
        let joinedFriends = {};
        // check users present in my current call
        await props.firebase.getAcceptedUsersOfCall(
          loginUser.currentCallId,
          async (res) => {
            if (!!res) {
              joinedFriends = res;
              if (
                Object.keys(joinedFriends).length >= MAX_USER_SUPPORTED_FOR_CALL
              ) {
                Alert(
                  400,
                  "Your current call can be connected with 6 people only"
                );
                return;
              }
              delete joinedFriends[loginUser.userId];
              // if i hv users connected in my current call
              if (Object.keys(joinedFriends).length > 0) {
                let incomingjoinedFriends = {};
                //check users of incoming  call
                await props.firebase.getAcceptedUsersOfCall(
                  notification.callId,
                  async (res) => {
                    incomingjoinedFriends = res;
                    if (!!incomingjoinedFriends) {
                      delete incomingjoinedFriends[notification.creatorId];
                    }

                    if (Object.keys(incomingjoinedFriends).length <= 0) {
                      //if incoming user is alone in call then merge calls
                      await this.updateNotificationsData(index, status);
                      let data = {};
                      data[notification.callId] = loginUser.currentCallId;

                      await props.firebase.keepUserInMergedCall(
                        notification.creatorId,
                        data
                      );
                      await this.getAndaddCallObject(
                        loginUser.currentCallId,
                        notification.creatorId
                      );
                      // this.onJoinCall(notification.callId);
                      props.close();
                    } else {
                      // you r in call with other users and wants to accept incoming call
                      Alert(400, "End your current call 1st");
                      return;
                    }
                  }
                );
              } else {
                await this.updateNotificationsData(index, status);

                await this.getAndaddCallObject(
                  notification.callId,
                  loginUser.userId
                );
                this.onJoinCall(notification.callId);
                props.close();
              }
            }
          }
        );
      } else {
        await props.firebase.getAcceptedUsersOfCall(
          notification.callId,
          async (res) => {
            if (res) {
              if (Object.keys(res).length >= MAX_USER_SUPPORTED_FOR_CALL) {
                Alert(
                  400,
                  `Sorry you can't connect. Max connection limit reached.`
                );
                await this.updateNotificationsData(index, "REJECTED");
                await this.onDeclineCall(notification, false);
                return;
              } else {
                await this.updateNotificationsData(index, status);
                await this.getAndaddCallObject(
                  notification.callId,
                  loginUser.userId
                );
                this.onJoinCall(notification.callId);
                props.close();
              }
            } else {
              await this.updateNotificationsData(index, status);
              await this.getAndaddCallObject(
                notification.callId,
                loginUser.userId
              );
              this.onJoinCall(notification.callId);
              props.close();
            }
          }
        );
      }
    }
    if (status === "REJECTED") {
      await this.updateNotificationsData(index, status);
      await this.onDeclineCall(notification);
    }
  };

  getAndaddCallObject = async (callId, userId) => {
    let props = this.props;
    let obj = {
      status: "ACCEPTED",
      userId: userId,
    };
    await props.firebase.setStatusOfUserInCall(callId, userId, obj);
  };

  onJoinCall = (callId) => {
    let props = this.props;

    let obj = {
      call_status: "DIRECT_CALL",
      id: callId,
    };
    props.setOrUnsetCurrentCall(obj);
    props.history.push("/call/" + callId, { isCallSession: true });
    window.location.reload();
  };
  render() {
    const {
      allCallNotifications,
      isLoading,
      statusArr,
      isFetched,
    } = this.state;

    let allUnreadNotifications = allCallNotifications;
    const notificationValues = allUnreadNotifications
      ? Object.values(allUnreadNotifications)
      : [];
    const list = notificationValues.filter((val) => val.status === "PENDING");

    let indexOfNot = -1;
    let keys = Object.keys(allUnreadNotifications);
    return (
      <div className="fixedModal incommingCall text-center normalCenterLoader">
        {isLoading && <BasicLoader />}
        {list && list.length > 0 ? (
          <Fragment>
            <h3>Incoming Call</h3>
            <div className="srollView">
              {notificationValues.map((notification, index) => {
                if (statusArr && statusArr.length > 0) {
                  indexOfNot = statusArr.findIndex(
                    (arr) => arr.key === keys[index]
                  );
                }
                if (notification.status === "PENDING") {
                  return (
                    <Fragment key={index}>
                      <div className={cx("person", { "mt-3": index !== 0 })}>
                        <img
                          className="person-image"
                          src={notification.photoUrl || avatarIcon}
                          alt="Bonfire"
                        />
                        <p>{notification.senderName}</p>
                        <audio src={callTone} autoPlay loop></audio>
                      </div>
                      {indexOfNot === -1 ? (
                        <div className="callOption">
                          <button
                            title="Click to accept call"
                            onClick={() =>
                              this.onAcceptOrRejectVideoCall(
                                index,
                                notification,
                                "ACCEPTED"
                              )
                            }
                            className="btn-icon callBtn accept"
                          >
                            <CallIcon />
                          </button>
                          <button
                            title="Click to reject call"
                            onClick={() =>
                              this.onAcceptOrRejectVideoCall(
                                index,
                                notification,
                                "REJECTED"
                              )
                            }
                            className="btn-icon callBtn reject"
                          >
                            <CallIcon />
                          </button>
                        </div>
                      ) : (
                        <b
                          className={` mx-2 accepted ${
                            statusArr[indexOfNot].status === "ACCEPTED"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {statusArr[indexOfNot].status}
                        </b>
                      )}
                    </Fragment>
                  );
                }
                return null;
              })}
            </div>
          </Fragment>
        ) : (
          // {renderEmptyList}
          <div className="eventDetailList mb-3">
            <div className="d-flex align-items-center">
              <p className="mb-0" style={{ whiteSpace: "initial" }}>
                {!isFetched ? "Fetching calls..." : "No more incoming calls."}
              </p>
            </div>
          </div>
          // <div>No more incoming calls</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserData: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setCallCount: (data) => dispatch(loginAction.setCallCount(data)),

    setUserCallNotifications: (data) =>
      dispatch(loginAction.setUserCallNotifications(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(IncomingCallModal);
