import {
  AddIcon,
  CameraIcon,
  MicIcon,
  RecordIcon,
  ScreenShareIcon,
  VideoCameraIcon,
  MutedMiceIcon,
  MiceIcon
} from "../../../components/svgIcons";
import {
  CALL_JOIN_ASK_STATUS,
  CALL_TIMER_STATUS,
  CALL_TYPE_STATUS,
  IS_HIDE_VIDEO_RECORDING_FEATURE,
  MAX_USER_SUPPORTED_FOR_CALL,
  NON_PREMIUM_CALL_END_AT,
  ROUTES,
} from "../../../components/shared/constants";
import React, { Component, createRef } from "react";
import {
  handleImageFileUpload,
  isScreenShareSupported,
  isVideoCallSupported,
  isSafariBrowser,
  getNumberOfColumn,
  getVideoFramePosition,
} from "../../../components/shared/utils";
import Alert from "../../../components/shared/common/alert";
import CallDisconnectAnimationView from "./callDisconnectAnimation";
import CallDisconnectView from "./callDisconnectView";
import ScreenShareComponent from "./screenShare";
import ShareImageToFB from "./shareToFb";
import { VideoChatManager } from "./simpleVideoChat";
import cx from "classnames";
import GrayBgLogo from "../../../assets/images/grayBgLogo.png";
import TransparentLogo from "../../../assets/images/logo.png";
import GuestCallPasswordModal from "../../../components/modal/guestCallPasswordModal";
import { handleUploadWebmFileToFireBaseStorage } from "../../../components/shared/utils";
import { BasicLoader } from "../../../components/shared/common/loader";
import callTimerEndTone from "../../../assets/audio/bells_call.mp3";
import ninja from "../../../assets/audio/ninja.mp3";
import surfingPig from "../../../assets/audio/surfing_pig.mp3";
import normalCallEnd from "../../../assets/audio/normal_end.mp3";
import transparentCover from "../../../assets/images/transparentCover.png";

function getGUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getUpdatedRemoteUsers1(oldUsers, newUsers) {
  let tmpOldUers = [...oldUsers];
  let tmpNewUers = [...newUsers];
  tmpOldUers = tmpOldUers.filter((item) => tmpNewUers.includes(item));
  const addedUsers = tmpNewUers.filter((item) => !tmpOldUers.includes(item));
  const updatedUsers = [...tmpOldUers, ...addedUsers];
  return updatedUsers;
}

class VideoChatComponent extends Component {
  async openUserMedia(id) {
    const {
      userInfo: { userId, isGuestMode, isGuestJoined, isAnonymous },
    } = this.props;
    this.yourId = userId;
    this.connections = [];
    this.time = null;

    await navigator.mediaDevices
      .getUserMedia({
        video: { width: 640, height: 480 },
        audio: true,
      })
      .then((streamData) => (this.localVideoRef.srcObject = streamData))
      .then((stream) => {
        const sessionCallId = sessionStorage.getItem("currentCallId");
        if (
          (isGuestMode && !isGuestJoined) ||
          (!sessionCallId && isGuestJoined) ||
          (isGuestMode && !isAnonymous) ||
          !sessionCallId ||
          (id && sessionCallId && sessionCallId !== id)
        ) {
          this.setState({
            isShowGuestLoginPopup: true,
          });
        } else {
          this.handleVideoChatManagerInitialization(stream);
        }
      })
      .catch((err) => {
        this.props.setOrUnsetCurrentCall({
          call_status: "",
          id: "",
        });
        Alert(400, "Media device unable to connect.");
        this.props.history.push(ROUTES.user.welcome);
        return;
      });
  }

  // ************* Class

  constructor(props) {
    super(props);

    this.state = {
      localVideoStream: null,
      remoteVideoStream: [],
      eventId: "",
      remoteUsers: [],
      isCaptureImage: false,
      isAudioMuted: false,
      isVideoMuted: false,
      isAudioEnabled: false,
      isVideoEnabled: false,
      isCallEnabled: false,
      isCameraEnabled: false,
      isRecorderEnabled: false,
      animationUrl: null,
      isCaptureImageMode: false,
      snapTimer: 0,
      videoRecordingTimer: 0,
      callEndAnimation: "",
      callEndBy: "",
      isScreenShareEnabled: false,
      screenShareTooltipText:
        "Please connect with someone to start sharing your screen.",
      recordedMediaChunks: [],
      recorderState: "",
      isRecordingState: false,
      isShowGuestLoginPopup: false,
      isLoading: true,
      shareScreenBy: null,
      seconds: 0,
      timeToShow: "",
      isShowTimer: false,
      mutedRemoteStream: [],
      callEndTime: NON_PREMIUM_CALL_END_AT,
      showCallEndAlert: false,
      showMuteButttonId: []
    };
    this.isSupportScreenShare = isScreenShareSupported();
    this.remoteVideoRef = {};
    this.pc = {};
    this.onDisconnectCall = this.onDisconnectCall.bind(this);
    this.onMuteSound = this.onMuteSound.bind(this);
    this.onMuteVideo = this.onMuteVideo.bind(this);
    this.onCaptureScreen = this.onCaptureScreen.bind(this);
    this.onSelectCallEndOption = this.onSelectCallEndOption.bind(this);
    this.onClickShareToFB = this.onClickShareToFB.bind(this);
    this.onCloseScreenCaptureMode = this.onCloseScreenCaptureMode.bind(this);
    this.onScreenShare = this.onScreenShare.bind(this);
    this.onStopScreenShare = this.onStopScreenShare.bind(this);
    this.onClickRecordMeeting = this.onClickRecordMeeting.bind(this);
    this.handleOnAskToJoinCall = this.handleOnAskToJoinCall.bind(this);
    this.startSnapTimer = this.startSnapTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.videoRef = createRef();
    this.mediaRecorder = null;
  }

  async componentDidMount() {
    const {
      firebase,
      userInfo: {
        userId,
        call_status,
        currentCallId,
        isScreenSharingOn,
        isGuestMode,
        isGuestJoined,
        isAnonymous,
        // isPremium,
      },
      location,
    } = this.props;
    const isPremium = true;
    if (isScreenSharingOn) {
      this.props.setScreenShareMode(false);
    }

    if (isVideoCallSupported()) {
      const path = window.location.pathname;
      let id = "";
      if (path) {
        id = path.split("/call/")[1];
      }

      const sessionCallId = sessionStorage.getItem("currentCallId");

      if (
        (isGuestMode && !isGuestJoined) ||
        (isGuestMode && !isAnonymous) ||
        (!sessionCallId && isGuestJoined) ||
        !sessionCallId ||
        (sessionCallId && id && sessionCallId !== id)
      ) {
        this.openUserMedia(id);
      } else {
        let isCallSession = false;
        let isEventSession = false;

        if (location && location.state) {
          isCallSession = !!location.state.isCallSession;
          isEventSession = !!location.state.isEventSession;
        }
        this.remoteStatus = {};

        if (currentCallId) {
          id = currentCallId;
          isCallSession = call_status === CALL_TYPE_STATUS.DIRECT_CALL;
          isEventSession = call_status === CALL_TYPE_STATUS.EVENT_CALL;
        }

        let isAllowedToJoin = true;

        if (id) {
          if (isEventSession) {
            await firebase.getUserEventDetail(id, async (res) => {
              if (res) {
                const callRef = firebase.getUserCall(id, () => {});
                await callRef.then((response) => {
                  const res = response.val();
                  if (res) {
                    let invitedKeys = [];
                    if (res.invited) {
                      invitedKeys = Object.values(res.invited).filter(
                        (val) =>
                          val.status === "ACCEPTED" && val.userId !== userId
                      );
                    }
                    if (invitedKeys.length === MAX_USER_SUPPORTED_FOR_CALL) {
                      isAllowedToJoin = false;
                      let obj = {
                        call_status: "",
                        id: "",
                      };

                      this.props.setOrUnsetCurrentCall(obj);
                      Alert(
                        400,
                        `Sorry you can't connect. Max connection limit reached.`
                      );
                      this.props.history.push(ROUTES.user.welcome);
                      return;
                    } else {
                      let invitedCallObj = {};
                      invitedCallObj[userId] = {
                        userId: userId,
                        status: "ACCEPTED",
                      };
                      firebase.updateUserCall(id, invitedCallObj);
                    }
                  } else {
                    let obj = {
                      timestamp: Date.now(),
                      start: true,
                      creatorId: userId,
                      invited: {},
                    };

                    obj.invited[userId] = {
                      userId: userId,
                      status: "ACCEPTED",
                    };

                    firebase.addUserEventCall("calls", id, obj);
                  }
                });

                if (isAllowedToJoin) {
                  const invited = Object.keys(this.props.joinedFriends || {});
                  this.remoteUsers = invited.filter((i) => i !== userId);
                  this.allUsers = invited;
                  this.callSessionId = id;
                  this.setState(
                    {
                      remoteUsers: this.getUpdatedRemoteUsers(
                        this.state.remoteUsers,
                        this.remoteUsers
                      ),
                    },
                    async () => {
                      await this.openUserMedia();
                      this.onShowAnimation(userId, id);
                      this.onShowTimerAnimation(id);
                      this.getTimerValues();
                      this.handleOnLogout();
                      this.handleNonPremiumCallStatus(userId, isPremium, id);
                    }
                  );
                }
              }
            });
          } else if (isCallSession) {
            const callRef = firebase.getUserCall(id, () => {});
            await callRef.then((response) => {
              const res = response.val();

              if (res) {
                let invitedKeys = [];
                if (res.invited) {
                  invitedKeys = Object.values(res.invited).filter(
                    (val) => val.status === "ACCEPTED" && val.userId !== userId
                  );
                }
                if (invitedKeys.length === MAX_USER_SUPPORTED_FOR_CALL) {
                  let obj = {
                    call_status: "",
                    id: "",
                  };

                  this.props.setOrUnsetCurrentCall(obj);
                  if (isGuestMode && isGuestJoined) {
                    this.props.setGuestJoinedMode({
                      userId: "",
                      username: "",
                      status: false,
                    });
                  }
                  isAllowedToJoin = false;
                  Alert(
                    400,
                    `Sorry you can't connect. Max connection limit reached.`
                  );
                  this.props.history.push(ROUTES.user.welcome);
                } else {
                  let invitedCallObj = {};
                  invitedCallObj[userId] = {
                    userId: userId,
                    status: "ACCEPTED",
                  };
                  firebase.updateUserCall(id, invitedCallObj);
                }
              }
            });

            if (isAllowedToJoin) {
              const invited = Object.keys(this.props.joinedFriends || {});
              this.remoteUsers = invited.filter((i) => i !== userId);
              this.allUsers = invited;
              this.callSessionId = id;
              this.setState(
                {
                  remoteUsers: this.getUpdatedRemoteUsers(
                    this.state.remoteUsers,
                    this.remoteUsers
                  ),
                },
                async () => {
                  await this.openUserMedia();
                  this.onShowAnimation(userId, id);
                  this.onShowTimerAnimation(id);
                  this.getTimerValues();
                  this.handleOnLogout();
                  this.handleNonPremiumCallStatus(userId, isPremium, id);
                }
              );
            }
          } else {
            this.props.setOrUnsetCurrentCall({
              call_status: "",
              id: "",
            });
            console.log("Invalid video link");
            Alert(400, "Invalid video link.");
            this.props.history.push(ROUTES.user.welcome);
          }
        }
      }
    } else {
      let obj = {
        call_status: "",
        id: "",
      };

      this.props.setOrUnsetCurrentCall(obj);
      Alert(
        400,
        "Video call not supported on this version. Please update to latest one."
      );
      this.props.history.push(ROUTES.user.welcome);
      return;
    }

    this.setState({
      isLoading: false,
    });
  }

  handleNonPremiumCallTimer(startedAt) {
    const { firebase } = this.props;

    const callId = sessionStorage.getItem("currentCallId");
    if (this.endCallInterval) {
      clearInterval(this.endCallInterval);
    }

    const diff = Number(Date.now() - startedAt);
    const timeSecDiff = Math.abs(Number(Math.round(diff / 1000)));
    const newTimeLeft = this.state.callEndTime - timeSecDiff;
    this.setState({ callEndTime: newTimeLeft });
    this.endCallInterval = setInterval(() => {
      const { callEndTime } = this.state;
      if (callEndTime <= 10) {
        firebase.setCallStatus(callId, "/goingToEnd/", true);
      }
      if (callEndTime <= 0) {
        clearInterval(this.endCallInterval);
        firebase.setCallStatus(callId, "/endCall/", true);
        this.onDisconnectCall("NORMAL_CALL_END");
      }
      this.setState({
        callEndTime: callEndTime - 1,
      });
    }, 1000);
  }

  getTimerValues() {
    const { firebase } = this.props;
    const { pathname } = window.location;
    const path = pathname.split("/");
    const callId = path[2];
    firebase.getTimerValue(callId, (res) => {
      if (res) {
        const {
          userInfo: { callTimer },
        } = this.props;

        let timerData = res;
        if (!callTimer) {
          this.props.setTimerData(res);
        } else {
          let updatedCallTimer = {
            ...callTimer,
            ...timerData,
          };
          this.props.setTimerData(updatedCallTimer);
          timerData = updatedCallTimer;
        }

        this.startTimer(timerData);
      }
    });
  }

  handleVideoChatManagerInitialization(stream) {
    const path = window.location.pathname;
    let roomId = "";
    if (path) {
      roomId = path.split("/call/")[1];
    }
    const isSafari = isSafariBrowser();
    this.setState({
      isAudioEnabled: true,
      isVideoEnabled: true,
      isCallEnabled: true,
      isCameraEnabled: true,
      isRecorderEnabled: !isSafari,
    });
    this.localStreamVideo = stream;
    this.vc = new VideoChatManager(
      `${this.yourId}__${getGUID()}`,
      roomId,
      stream,
      this.props.firebase.getDatabase()
    );
    this.vc.onRemoteStreamAdded = function (gid, remoteStream) {
      const { isScreenShareEnabled, isShowScreenShareView } = this.state;
      const {
        joinedFriends,
        userInfo: { userId },
      } = this.props;

      const arr = gid.split("__");
      const id = arr[0];
      if (this.remoteVideoRef[id]) {
        this.remoteVideoRef[id].srcObject = remoteStream;
      }

      const tempRemoteUsers = Object.keys(joinedFriends || {}).filter(
        (id) => id !== userId
      );
      if (!isScreenShareEnabled && tempRemoteUsers.length) {
        if (this.isSupportScreenShare) {
          if (isShowScreenShareView) {
            this.setState({
              isScreenShareEnabled: true,
              screenShareTooltipText: "You are in screen sharing mode.",
            });
          } else {
            this.setState({
              isScreenShareEnabled: true,
              screenShareTooltipText: "Click to start sharing your screen.",
            });
          }
        } else {
          this.setState({
            screenShareTooltipText:
              "Please update your browser to access this feature.",
          });
        }
      }

      if (
        this.vc &&
        this.vc.onScreenShare &&
        isScreenShareEnabled &&
        this.screenShareStream
      ) {
        this.vc.onScreenShare(this.screenShareStream, gid);
      }
      if (!tempRemoteUsers.length) {
        this.setState({
          screenShareTooltipText:
            "Please connect with someone to start sharing your screen.",
        });
      }
    }.bind(this);

    this.vc.onRemoteStreamRemoved = async function (gid) {
      const arr = gid.split("__");
      const id = arr[0];

      const invited = this.props.joinedFriends;
      const callEndBy = invited[id];
      if (callEndBy && callEndBy.animationType) {
        const userDetailRef = this.props.firebase.getUserDetail(id);
        await userDetailRef.then((snapshot) => {
          if (snapshot.val()) {
            this.setState({
              callEndAnimation: callEndBy.animationType,
              callEndBy: snapshot.val().username,
            });
          }
        });
      }

      const { isShowScreenShareView } = this.state;

      if (isShowScreenShareView === id) {
        this.resetScreenShareMode();
      }

      await this.props.firebase.removeUserFromInvitedCall(
        this.callSessionId,
        id
      );

      if (this.remoteVideoRef[id] && this.remoteVideoRef[id].srcObject) {
        this.remoteVideoRef[id].srcObject
          .getTracks()
          .forEach((track) => track.stop());
      }
      if (this.remoteVideoRef[id]) {
        this.remoteVideoRef[id].srcObject = null;
      }

      const tempRemoteUsers = this.state.remoteUsers.filter(
        (userId) => userId !== id
      );
      this.setState(
        {
          remoteUsers: this.getUpdatedRemoteUsers(
            this.state.remoteUsers,
            tempRemoteUsers
          ),
        },
        () => {
          delete this.remoteVideoRef[id];
          if (!tempRemoteUsers.length) {
            this.setState({
              isScreenShareEnabled: false,
              screenShareTooltipText:
                "Please connect with someone to start sharing your screen.",
            });
          }

          setTimeout(() => {
            this.setState({
              callEndAnimation: "",
              callEndBy: "",
            });
          }, 6000);
        }
      );
    }.bind(this);
  }

  handleOnAskToJoinCall(callJoinInfo) {
    const { hostId, callId, guestUsername } = callJoinInfo;
    const {
      userInfo: { isGuestMode, username, userId, photoUrl },
      firebase,
    } = this.props;

    if (isGuestMode) {
      if (!userId) {
        firebase
          .authorization()
          .signInAnonymously()
          .catch(() => {
            Alert(400, "Unable to join as guest. Please try again.");
          })
          .then(() => {
            const currentUser = firebase.authorization().currentUser;
            if (currentUser && currentUser.isAnonymous) {
              this.props.setAnonymousLogin({
                isAnonymous: true,
              });
            }
            const user = {
              username: guestUsername,
              email: "",
              role: "user",
              photoUrl: "",
              isGuest: true,
            };
            const userData = firebase.setData("users").push(user);

            userData.then((result) => {
              const id = result.key;

              this.props.setGuestJoinedMode({
                userId: id,
                username: user.username,
                status: false,
              });

              this.handleSendNotificationForGuestCallJoinToHost({
                callId,
                hostId,
                userId: id,
                username: guestUsername,
              });
            });
          });
      } else {
        this.handleSendNotificationForGuestCallJoinToHost({
          callId,
          hostId,
          userId,
          username,
        });
      }
    } else {
      const askToJoinNotificationValue = {
        callId,
        hostId,
        userId,
        username: username,
        photoUrl: photoUrl || "",
        status: CALL_JOIN_ASK_STATUS.PENDING,
        isRead: false,
        timestamp: Date.now(),
      };
      this.handleSendNotificationForJoinToHost(
        hostId,
        askToJoinNotificationValue
      );
    }
  }

  handleSendNotificationForGuestCallJoinToHost(notification) {
    const { callId, hostId, userId, username } = notification;
    const askToJoinNotificationValue = {
      callId,
      hostId,
      userId,
      username,
      status: CALL_JOIN_ASK_STATUS.PENDING,
      isGuest: true,
      isRead: false,
      timestamp: Date.now(),
    };
    this.handleSendNotificationForJoinToHost(
      hostId,
      askToJoinNotificationValue
    );
  }

  handleSendNotificationForJoinToHost = (hostId, notification) => {
    this.props.firebase.updateUserAskToJoinCallNotification(
      hostId,
      notification.userId,
      notification
    );
    this.props.firebase.updateUserAskToJoinCallNotification(
      notification.userId,
      hostId,
      notification
    );
  };

  onAcceptStatus = (notification, isHostJoining) => {
    const {
      userInfo: { userId, username, isGuestMode },
    } = this.props;
    if (isHostJoining) {
      this.handleJoinVideoCall(userId);
    } else {
      if (isGuestMode) {
        this.props.setGuestJoinedMode({
          userId,
          username,
          status: true,
        });
      }
      this.handleJoinVideoCall(userId);
      this.onClearAskJoinNotification(notification);
    }
  };

  onRejectStatus = (notification, callback) => {
    const {
      userInfo: { isGuestMode },
    } = this.props;

    if (isGuestMode) {
      this.props.setAnonymousLogin({
        isAnonymous: false,
      });
    }

    this.onClearAskJoinNotification(notification, callback);
  };

  onClearAskJoinNotification = (notification, callback) => {
    this.props.firebase
      .deleteUserAskToJoinCallNotification(
        notification.userId,
        notification.hostId
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        if (callback) {
          callback();
        }
      });
  };

  handleJoinVideoCall = (id) => {
    const {
      firebase,
      history,
      userInfo: { isGuestMode },
    } = this.props;

    let callId = "";
    const path = window.location.pathname;
    if (path) {
      callId = path.split("/call/")[1];
      let callDetail = {
        status: "ACCEPTED",
        userId: id,
      };

      firebase.setStatusOfUserInCall(callId, id, callDetail).then(() => {
        this.props.setOrUnsetCurrentCall({
          call_status: CALL_TYPE_STATUS.DIRECT_CALL,
          id: callId,
        });
      });
      setTimeout(() => {
        if (!isGuestMode) {
          history.push("/call/" + callId, { isCallSession: true });
        }
        window.location.reload();
      }, 500);
    }

    this.setState({
      isShowGuestLoginPopup: false,
    });
  };

  handleOnLogout() {
    this.onLogoutEndCallInterval = setInterval(async () => {
      const {
        userInfo: { currentCallId, endCallMode },
      } = this.props;
      if (currentCallId && endCallMode === "logout") {
        clearInterval(this.onLogoutEndCallInterval);

        if (this.vc && this.vc.onHanupCall) {
          this.vc.onHanupCall();
          this.onRemoveTracks();
        }
      }
    }, 2000);
  }

  onShowTimerAnimation(callId) {
    this.props.firebase.showTimer(callId, (response) => {
      if (response && response.startScreenCapture) {
        this.startSnapTimer(response.startScreenCapture);
      }

      if (response) {
        if (response.startScreenRecording) {
          this.startRecordingTimer(response.startScreenRecording);
        }
        if (response.startScreenShare) {
          this.showScreenShareView(response.startScreenShare);
          this.setState({ shareScreenBy: response.startScreenShare });
        } else {
          this.hideScreenShareView();
        }
      }
    });
  }

  handleNonPremiumCallStatus = (userId, isPremium, callId) => {
    this.props.firebase.getCallStatus(callId, (res) => {
      if (res.endCall && res.creatorId !== userId) {
        this.onDisconnectCall("NORMAL_CALL_END");
      }
      if (res.goingToEnd) {
        this.setState({
          showCallEndAlert: true,
        });
      }
    });

    if (!isPremium) {
      this.props.firebase.getCallStatusOnce(callId, (res) => {
        if (res.creatorId === userId) {
          this.handleNonPremiumCallTimer(res.startedAt);
        } else {
          if (this.endCallInterval) {
            clearInterval(this.endCallInterval);
          }
        }
      });
    }
  };

  onShowAnimation(userId, callId) {
    const { firebase } = this.props;
    firebase.showAnimatedChatMessages(userId, callId, (response) => {
      if (response) {
        const { message, timestamp, isShowAnimatedMessage } = response;
        if (!this.state.animationUrl && isShowAnimatedMessage) {
          const messageAt = new Date(timestamp);
          const expiredAt = new Date(
            messageAt.setSeconds(messageAt.getSeconds() + 6)
          );
          const currentTime = new Date();

          if (expiredAt.getTime() - currentTime.getTime() > 0) {
            const { chatSpecialSymbolList } = this.props;
            const tempMsg = message.toLowerCase();
            const selected = Object.values(chatSpecialSymbolList || {}).find(
              (symbol) => symbol.keyword.toLowerCase() === tempMsg
            );
            if (selected && selected.animationUrl) {
              const time = new Date().getTime();
              this.setState({
                animationUrl: `${selected.animationUrl}&time=${time}`,
              });

              const waitDuration =
                (selected.duration && selected.duration * 1000) || 7000;

              setTimeout(() => {
                this.setState(
                  {
                    animationUrl: "wait",
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        animationUrl: null,
                      });
                    }, 1000);
                  }
                );
              }, waitDuration);
            }
          }
        }
      }
    });
  }

  getUpdatedRemoteUsers(oldUsers, newUsers) {
    let tmpOldUers = [...oldUsers];
    let tmpNewUers = [...newUsers];
    tmpOldUers = tmpOldUers.filter((item) => tmpNewUers.includes(item));
    const addedUsers = tmpNewUers.filter((item) => !tmpOldUers.includes(item));
    const updatedUsers = [...tmpOldUers, ...addedUsers];
    return updatedUsers;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      location,
      userInfo: { call_status },
    } = nextProps;

    let isCallSession = false;
    let isEventSession = false;
    if (location && location.state) {
      isCallSession = !!location.state.isCallSession;
      isEventSession = !!location.state.isEventSession;
    }

    if (call_status) {
      isCallSession = call_status === CALL_TYPE_STATUS.DIRECT_CALL;
      isEventSession = call_status === CALL_TYPE_STATUS.EVENT_CALL;
    }
    const { userId } = nextProps.userInfo;
    const invited = Object.keys(nextProps.joinedFriends || {});
    const remoteUsers = invited.filter((i) => i !== userId);
    const prevRemoteUsers = [...prevState.remoteUsers];
    const isUpdated =
      remoteUsers.length !== prevRemoteUsers.length ||
      !prevRemoteUsers.every((prevId) =>
        remoteUsers.some((newId) => newId === prevId)
      );

    if ((isEventSession || isCallSession) && isUpdated) {
      const remoteUserList = getUpdatedRemoteUsers1(
        prevState.remoteUsers,
        remoteUsers
      );
      if (remoteUserList.length < MAX_USER_SUPPORTED_FOR_CALL) {
        return {
          remoteUsers: remoteUserList,
        };
      }
    }
    return {};
  }

  async onRemoveTracks() {
    if (this.localVideoRef && this.localVideoRef.srcObject) {
      const tracks = this.localVideoRef.srcObject.getTracks();
      await tracks.forEach((track) => {
        track.stop();
      });
      if (this.localVideoRef) this.localVideoRef.srcObject = null;
    }

    const { remoteUsers } = this.state;
    if (remoteUsers.length && this.remoteVideoRef) {
      for (const user of remoteUsers) {
        const ref = this.remoteVideoRef[user];
        if (ref && ref.srcObject) {
          await ref.srcObject.getTracks().forEach((track) => track.stop());
          ref.srcObject = null;
        }
      }
    }

    this.props.setIsLoading(false);
  }

  async onDisconnectCall(typeOfCallEnd) {
    const {
      userInfo: { userId, isGuestMode, isGuestJoined },
    } = this.props;
    if (typeOfCallEnd) {
      this.props.setCallEndAnimationType(typeOfCallEnd);
    }
    if (typeOfCallEnd) {
      await this.props.firebase.setCallEndAnimationView(
        this.callSessionId,
        userId,
        {
          animationType: typeOfCallEnd,
        }
      );
    }

    let obj = {
      call_status: "",
      id: "",
    };
    this.props.setOrUnsetCurrentCall(obj);
    if (isGuestMode && isGuestJoined) {
      this.props.setGuestJoinedMode({
        userId: "",
        username: "",
        status: false,
      });

      this.props.setAnonymousLogin({
        isAnonymous: false,
      });
    }
    this.setState({
      isAudioEnabled: false,
      isVideoEnabled: false,
      isCallEnabled: false,
      isCameraEnabled: false,
      isRecorderEnabled: false,
      isScreenShareEnabled: false,
    });
    if (this.vc && this.vc.onHanupCall) {
      this.vc.onHanupCall();
    }

    this.onRemoveTracks();

    this.props.onDisconnect();

    this.props.history.push({
      pathname: ROUTES.user.feedback,
    });
    if (typeOfCallEnd === "animationTwo") {
      setTimeout(() => {
        window.location.reload(true);
      }, 4000);
    } else {
      setTimeout(() => {
        window.location.reload(true);
      }, 2500);
    }
  }

  onSelectCallEndOption(key) {
    this.onDisconnectCall(key);
  }

  onMuteSound() {
    const { userId } = this.props.userInfo;
    const callID = sessionStorage.getItem("currentCallId");
    if (this.localStreamVideo) {
      this.localStreamVideo.getTracks().forEach((track) => {
        if (track.kind === "audio") {
          const updatedMute = !this.state.isAudioMuted;
          track.enabled = this.state.isAudioMuted;
          this.setState(
            {
              isAudioMuted: updatedMute,
            },
            () => {
              this.props.firebase.setAudioButtonStatus(
                callID,
                this.state.isAudioMuted,
                userId
              );
            }
          );
        }
      });
    }
  }

  onMuteVideo() {
    const { shareScreenBy } = this.state;
    const {
      userInfo: { userId },
    } = this.props;
    if (shareScreenBy === userId) {
      Alert(400, "You cannot disable video while screen is shared.");
      return;
    } else if (this.localStreamVideo) {
      this.localStreamVideo.getTracks().forEach((track) => {
        if (track.kind === "video") {
          const updatedMute = !this.state.isVideoMuted;
          track.enabled = this.state.isVideoMuted;
          this.setState({
            isVideoMuted: updatedMute,
          });
        }
      });
    }
  }

  startRecordingTimer(screenRecordedBy) {
    const videoRecordingTimer = this.state.videoRecordingTimer + 1;
    this.setState({
      videoRecordingTimer,
      isRecorderEnabled: false,
      isCameraEnabled: false,
    });
    this.videoRecordingTimerInterval = setInterval(() => {
      const videoRecordingTimer = this.state.videoRecordingTimer + 1;
      this.setState({
        videoRecordingTimer,
      });
      if (videoRecordingTimer === 4) {
        this.props.firebase.setTimer(this.callSessionId, {
          startScreenRecording: false,
        });

        clearInterval(this.videoRecordingTimerInterval);

        this.setState(
          {
            videoRecordingTimer: 0,
            isRecorderEnabled: true,
            isCameraEnabled: true,
          },
          () => {
            const {
              userInfo: { userId },
            } = this.props;
            if (screenRecordedBy && screenRecordedBy === userId) {
              this.initializeMediaRecorder();
            }
          }
        );
      }
    }, 1000);
  }

  startSnapTimer(screenCaptureBy) {
    const {
      userInfo: { userId },
    } = this.props;
    if (screenCaptureBy && screenCaptureBy === userId) {
      this.handleCreateVideoFrame(true);
    }
    const snapTimer = this.state.snapTimer + 1;
    this.setState({
      snapTimer,
    });
    this.snapTimerInterval = setInterval(() => {
      const snapTimer = this.state.snapTimer + 1;
      this.setState({
        snapTimer,
      });
      if (snapTimer === 4) {
        this.props.firebase.setTimer(this.callSessionId, {
          startScreenCapture: false,
        });
        clearInterval(this.snapTimerInterval);

        this.setState(
          {
            snapTimer: 0,
          },
          () => {
            if (screenCaptureBy && screenCaptureBy === userId) {
              this.onCaptureSnap();
            }
          }
        );
      }
    }, 1000);
  }

  onCaptureSnap() {
    try {
      setTimeout(() => {
        const canvas = document.getElementById("recording-canvas");
        document.getElementById("screenShotView").src = canvas.toDataURL();
        var link = document.createElement("a");
        link.download = "bonfire_connect_recording.png";
        link.href = canvas.toDataURL();
        link.click();
        this.setState({ isCaptureImage: true });
        canvas.toBlob((blob) => {
          this.setState({
            screenCaptureBlob: blob,
            isCaptureImageMode: false,
            isCameraEnabled: true,
            isRecorderEnabled: true,
            isAudioEnabled: true,
            isCallEnabled: true,
            isVideoEnabled: true,
            isScreenShareEnabled: this.isSupportScreenShare,
          });
        }, "image/png");

        setTimeout(() => {
          this.setState({ isCaptureImage: false });
          this.onClearScreenshotImg();
        }, 60000);
      }, 1000);
    } catch (err) {
      this.setState({
        isCaptureImageMode: false,
        isCameraEnabled: true,
        isRecorderEnabled: true,
        isAudioEnabled: true,
        isCallEnabled: true,
        isVideoEnabled: true,
        isScreenShareEnabled: this.isSupportScreenShare,
      });
    }
  }

  onClearScreenshotImg() {
    const videoImg = Object.values(
      document.getElementsByClassName("cap-image") || {}
    );

    videoImg.forEach((imgField) => {
      imgField.style.backgroundImage = "";
    });

    const screenShareImg = Object.values(
      document.getElementsByClassName("screenShareViewCapture") || {}
    );
    if (screenShareImg && screenShareImg.style) {
      screenShareImg.style.backgroundImage = "";
    }
  }

  onCaptureScreen() {
    const {
      userInfo: { userId },
    } = this.props;

    this.setState(
      {
        isCameraEnabled: false,
        isCaptureImageMode: true,
        isRecorderEnabled: false,
        isAudioEnabled: false,
        isCallEnabled: false,
        isVideoEnabled: false,
        isScreenShareEnabled: false,
      },
      () => {
        this.props.firebase
          .setTimer(this.callSessionId, {
            startScreenCapture: userId,
          })
          .then(() => {})
          .catch(() => {
            this.setState({
              isCameraEnabled: true,
              isCaptureImageMode: false,
              isRecorderEnabled: true,
              isAudioEnabled: true,
              isCallEnabled: true,
              isVideoEnabled: true,
              isScreenShareEnabled: this.isSupportScreenShare,
            });
          });
      }
    );
  }

  onClickShareToFB() {
    if (this.state.screenCaptureBlob) {
      this.props.setIsLoading(true);
      ShareImageToFB(
        {
          photoFile: this.state.screenCaptureBlob,
          storage: this.props.firebase.storage,
        },
        () => {
          this.props.setIsLoading(false);
          this.setState({
            screenCaptureBlob: "",
          });
          this.onCloseScreenCaptureMode();
        }
      );
    }
  }

  onCloseScreenCaptureMode() {
    this.setState({ isCaptureImage: false });
    this.onClearScreenshotImg();
  }

  onAddMedia = (e) => {
    this.props.onAttach(handleImageFileUpload(e));
  };

  async onScreenShare() {
    const {
      userInfo: { userId },
    } = this.props;
    await navigator.mediaDevices
      .getDisplayMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        if (this.vc && this.vc.onScreenShare && stream) {
          this.vc.onScreenShare(stream);
          this.screenShareStream = stream;
          this.props.setScreenShareMode(true);
          this.localVideoRef.srcObject = stream;

          if (stream.getVideoTracks()[0]) {
            stream.getVideoTracks()[0].onended = () => {
              this.onStopScreenShare();
            };
          }

          this.props.firebase
            .setScreenShareMode(this.callSessionId, {
              startScreenShare: userId,
            })
            .then(() => {})
            .catch(() => {
              Alert(400, "Unable to share your screen");
            });
        }
      });
  }

  async onStopScreenShare() {
    this.props.setScreenShareMode(false);
    if (this.localVideoRef && this.localVideoRef.srcObject) {
      const tracks = this.localVideoRef.srcObject.getTracks();
      await tracks.forEach((track) => {
        if (track.kind === "video") {
          track.stop();
        }
      });
      // if (this.localVideoRef) this.localVideoRef.srcObject = null;
    }

    await navigator.mediaDevices
      .getUserMedia({
        video: { width: 640, height: 480 },
        audio: true,
      })
      .then((stream) => {
        this.vc.onScreenShare(stream);
        this.localStreamVideo.addTrack(stream.getVideoTracks()[0]);
        this.localVideoRef.srcObject = this.localStreamVideo;
      });

    this.resetScreenShareMode();
    this.screenShareStream = null;
  }

  resetScreenShareMode() {
    this.setState({ shareScreenBy: null });
    this.props.firebase
      .setScreenShareMode(this.callSessionId, {
        startScreenShare: false,
      })
      .then(() => {})
      .catch(() => {
        Alert(400, "Unable to stop sharing your screen.");
      });
  }

  showScreenShareView(sharedBy) {
    const {
      userInfo: { userId },
    } = this.props;
    if (!this.state.isShowScreenShareView) {
      if (sharedBy === userId) {
        this.setState({
          isCameraEnabled: false,
        });
      }
      this.setState({
        isShowScreenShareView: sharedBy,
        isScreenShareEnabled: false,
        isRecorderEnabled: false,
        screenShareTooltipText: "You are in screen sharing mode.",
      });
      const screenStream = document.getElementById("screenShareView");

      if (screenStream && sharedBy === userId && this.localVideoRef) {
        screenStream.srcObject = this.localVideoRef.srcObject;
      } else {
        if (this.remoteVideoRef) {
          const ref = this.remoteVideoRef[sharedBy];

          if (ref && ref.srcObject) {
            screenStream.srcObject = ref.srcObject;
          } else {
            this.screenInterval = setInterval(() => {
              const ref = this.remoteVideoRef[sharedBy];
              if (ref && ref.srcObject) {
                screenStream.srcObject = ref.srcObject;
                clearInterval(this.screenInterval);
              }
            }, 2000);
          }
        }
      }
    }
  }

  hideScreenShareView() {
    const sharedBy = this.state.isShowScreenShareView;
    if (sharedBy) {
      const screenStream = document.getElementById("screenShareView");
      if (screenStream) {
        screenStream.srcObject = null;
      }
      if (!this.state.isScreenShareEnabled) {
        this.setState({
          isCameraEnabled: true,
        });
      }
      this.setState({
        isShowScreenShareView: false,
        isScreenShareEnabled: this.isSupportScreenShare,
        isRecorderEnabled: true,
        screenShareTooltipText: "Click to start sharing your screen.",
      });
    }
  }
  handleFileUploadError(error) {
    if (error) {
      // this.setLoading(false);
      // this.props.setIsLoading(false);
      Alert(400, "Unable to create new event.");
    }
  }

  async downloadRecording(mediaStreamChunks) {
    var blob = new Blob(mediaStreamChunks, {
      type: "video/webm",
    });

    var url = URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);

    handleUploadWebmFileToFireBaseStorage({
      file: blob,
      fileName: "test" + Date.now() + ".webm",
      storage: this.props.firebase.storage,
      onError: this.handleFileUploadError,
    }).then((fireBaseUrl) => {
      fetch("https://api.ourbonfire.com/convertWebmToMp4", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ url: fireBaseUrl }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          this.setState({
            recorderState: "",
          });
          const array = new Uint8Array(result.data.data);
          const newBlob = new Blob([array]);
          const backUrl = URL.createObjectURL(newBlob);
          a.style = "display: none";
          a.href = backUrl;
          a.download = "bonfire_connect_recording.mp4";
          a.click();
          window.URL.revokeObjectURL(url);

          let fileName = fireBaseUrl.split("?");
          fileName = fileName[0].split("%2F").pop();
          this.props.firebase.deleteWebmVideoFromStorage(fileName);
        });
    });
  }

  initializeMediaRecorder() {
    this.recordedMediaChunks = [];
    let options = {};
    if (MediaRecorder.isTypeSupported("video/webm")) {
      options = {
        mimeType: "video/webm",
      };
    }

    const canvas = document.getElementById("recording-canvas");
    const mediaRecorder = new MediaRecorder(canvas.captureStream(25), options);
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedMediaChunks.push(event.data);
      }
    };
    mediaRecorder.onstart = () => {
      this.setState(
        {
          recorderState: "Recording...",
        },
        () => {
          setTimeout(() => {
            mediaRecorder.stop();
          }, 3000);
        }
      );
    };
    mediaRecorder.start();

    mediaRecorder.onstop = () => {
      this.setState({
        isRecorderEnabled: true,
        isRecordingState: false,
        recorderState: "Converting...",
        isCameraEnabled: true,
        isAudioEnabled: true,
        isCallEnabled: true,
        isVideoEnabled: true,
        isScreenShareEnabled: this.isSupportScreenShare,
      });
      this.downloadRecording(this.recordedMediaChunks);
    };
  }

  updateCanvasFrame(ctx, video, x, y, width, height, scaleH) {
    ctx.scale(scaleH, 1);
    ctx.drawImage(video, x, y, width, height);

    if (this.state.isRecordingState || this.state.isCaptureImageMode) {
      requestAnimationFrame(() =>
        this.updateCanvasFrame(ctx, video, x, y, width, height, scaleH)
      ); // wait for the browser to be ready to present another animation frame.
    } else {
      ctx.clearRect(x, y, width, height);
    }
  }

  handleCreateVideoFrame(isSnap) {
    const videos = document.querySelectorAll("video");
    let j = 0,
      top = 0;
    let sharedScreenWidth;
    let friendWindowXpos = 0;
    let friendWindowYpos = 0;
    const parentCanvas = document.getElementById("recording-canvas");
    const parentCtx = parentCanvas.getContext("2d");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const videoFrameLen = videos.length;
    const columnLength = getNumberOfColumn(videoFrameLen);
    if (this.state.isShowScreenShareView) {
      const parentElSharedScreen = document.getElementById(
        "parent-container-screenshare"
      );
      const screenSharedVideo = videos[videoFrameLen - 1];
      const {
        offsetX: offsetXPos,
        y: yPos,
        w,
        h,
        extraWidth,
        extraHeight,
        x: xPos,
      } = getVideoFramePosition(
        parentElSharedScreen,
        screenSharedVideo,
        parentElSharedScreen.offsetTop,
        false
      );
      canvas.width = extraWidth + offsetXPos;
      friendWindowXpos = w + xPos + 5;
      sharedScreenWidth = canvas.width;
      canvas.height = extraHeight + yPos;
      this.updateCanvasFrame(
        ctx,
        screenSharedVideo,
        xPos,
        yPos + parentElSharedScreen.offsetTop,
        w,
        h,
        1
      );
      for (let i = 0, len = videoFrameLen - 1; i < len; i++) {
        const v = videos[i];
        const flipH = i === 0;
        const parentEl = document.getElementById("parent-container" + i);
        if (parentEl && !parentEl.classList.contains("d-none")) {
          const { w, h } = getVideoFramePosition(
            parentEl,
            v,
            parentEl.offsetHeight,
            flipH
          );
          if (i !== 0) {
            friendWindowYpos = yPos + h + 5;
          } else {
            friendWindowYpos = yPos;
            canvas.width = w + sharedScreenWidth;
          }
          this.updateCanvasFrame(
            ctx,
            v,
            flipH ? -friendWindowXpos : friendWindowXpos,
            friendWindowYpos,
            flipH ? -w : w,
            h,
            flipH ? -1 : 1
          );
        }
      }
    } else {
      for (let i = 0, len = videoFrameLen; i < len; i++) {
        try {
          const parentEl = document.getElementById("parent-container" + i);
          const v = videos[i];
          if (i === 0) {
            top = parentEl.offsetTop;
          }

          if (i === columnLength) {
            j = 0;
          }
          const flipH = i === 0;
          const {
            offsetX: offsetXPos,
            y: yPos,
            w: w1,
            h: h1,
            extraWidth,
            extraHeight,
            x: xPos,
          } = getVideoFramePosition(parentEl, v, top, flipH);
          const isVGap = yPos !== 0;

          let offsetX = offsetXPos + j * 4; //j*4 for horizontal gap within video frames
          let x = xPos + j * 4; //j*4 for horizontal gap within video frames
          j++;
          const y = isVGap ? yPos + 4 : yPos; //4 for vertical gap within video frames

          if (i < columnLength) {
            canvas.width = extraWidth + offsetX;
          }
          canvas.height = extraHeight + y;

          this.updateCanvasFrame(ctx, v, x, y, w1, h1, flipH ? -1 : 1);
        } catch (e) {
          continue;
        }
      }
    }

    parentCanvas.width = canvas.width + 8;
    parentCanvas.height = canvas.height + 4;
    this.updateCanvasFrame(
      parentCtx,
      canvas,
      4,
      4,
      canvas.width,
      canvas.height,
      1
    );

    const prevHeight = parentCanvas.height;
    const prevWidth = parentCanvas.width;
    parentCanvas.height = prevHeight + (isSnap ? 26 : 90);
    parentCtx.fillStyle = "white";
    parentCtx.fillRect(0, 0, parentCanvas.width, parentCanvas.height);

    const logoEl = document.createElement("img");
    logoEl.src = isSnap ? GrayBgLogo : TransparentLogo;
    const lW = isSnap ? 116 : 105;
    const lH = isSnap ? 40 : 38;

    if (isSnap) {
      this.updateCanvasFrame(
        parentCtx,
        logoEl,
        prevWidth - lW - 14,
        prevHeight - lH - 16,
        lW,
        lH,
        1
      ); // For logo
    } else {
      parentCtx.drawImage(logoEl, prevWidth - lW - 14, prevHeight - 8, lW, lH); // For logo
    }

    const linkXPos = prevWidth / 2 - 67;
    const linkYPos = isSnap ? parentCanvas.height - 8 : prevHeight + 16;
    parentCtx.fillStyle = "#7b37d3";
    parentCtx.font = "14px Avenir Next";
    parentCtx.fillText("www.OurBonfire.com", linkXPos, linkYPos);
  }

  onClickRecordMeeting() {
    const {
      userInfo: { userId },
    } = this.props;
    this.setState(
      {
        isRecorderEnabled: false,
        isRecordingState: true,
        isCameraEnabled: false,
        isAudioEnabled: false,
        isCallEnabled: false,
        isVideoEnabled: false,
        isScreenShareEnabled: false,
      },
      () => {
        this.handleCreateVideoFrame(false);

        this.props.firebase
          .setTimer(this.callSessionId, {
            startScreenRecording: userId,
          })
          .then(() => {})
          .catch(() => {
            this.setState({
              isRecorderEnabled: true,
              isRecordingState: false,
              isCameraEnabled: true,
              isAudioEnabled: true,
              isCallEnabled: true,
              isVideoEnabled: true,
              isScreenShareEnabled: this.isSupportScreenShare,
            });
          });
      }
    );
  }

  renderAnimationView() {
    const { animationUrl } = this.state;

    return (
      <div className="cheers">
        {
          <img
            src={
              !animationUrl || animationUrl === "wait"
                ? transparentCover
                : animationUrl
            }
            alt="animation"
          />
        }
      </div>
    );
  }

  renderSnapTimerView() {
    const { snapTimer, videoRecordingTimer } = this.state;
    const timmer = snapTimer || videoRecordingTimer;
    if (!timmer) {
      return null;
    }

    return (
      <div className="contdown">
        <p>{4 - timmer}</p>
      </div>
    );
  }

  renderRecordingView() {
    const { recorderState } = this.state;
    if (!recorderState) {
      return null;
    }

    return (
      <div className="recorderState">
        <p>{recorderState}</p>
      </div>
    );
  }

  muteRemoteStream(key) {
    let mutedStream = this.state.mutedRemoteStream.slice();
    if (mutedStream.includes(key)) {
      mutedStream = mutedStream.filter((val) => val !== key);
    } else {
      mutedStream.push(key);
    }
    this.setState({ mutedRemoteStream: mutedStream });
    let remoteVideo = document.getElementById("remoteVideo" + key);
    remoteVideo.srcObject.getTracks().forEach((track) => {
      if (track.kind === "audio") {
        track.enabled = !track.enabled;
      }
    });
  }

  startTimer(data) {
    const { CANCEL, PAUSE, RESUME, START } = CALL_TIMER_STATUS;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (data.status !== CANCEL) {
      this.setState(
        {
          isShowTimer: true,
        },
        () => {
          if (data.updatedValue) {
            let updatedTime = 0;
            const {
              userInfo: { callTimer, resumeTimerValue },
            } = this.props;
            if (data.status === RESUME) {
              const diff = Number(Date.now() - data.timeStamp);
              const timeSecDiff = Math.abs(Number(Math.round(diff / 1000)));
              updatedTime = resumeTimerValue - timeSecDiff;
            } else if (data.status === PAUSE) {
              this.props.setResumeTimerValue(callTimer.updatedValue);
              updatedTime = callTimer.updatedValue;
            } else {
              const diff = Number(Date.now() - data.timeStamp);
              const timeSecDiff = Math.abs(Number(Math.round(diff / 1000)));
              updatedTime = data.value - timeSecDiff;
            }
            this.setState(
              {
                timeToShow: this.getFormattedTimerVal(updatedTime),
                seconds: updatedTime,
              },
              () => {
                this.timer = setInterval(() => this.countDown(data), 1000);
              }
            );
          } else {
            if (data.status === START) {
              const diff = Number(Date.now() - data.timeStamp);
              const timeSecDiff = Math.abs(Number(Math.round(diff / 1000)));
              const updatedTime = data.value - timeSecDiff;
              this.setState(
                {
                  seconds: updatedTime,
                },
                () => {
                  this.timer = setInterval(() => this.countDown(data), 1000);
                }
              );
              this.props.setIsTimerStarted(true);
            } else {
              this.timer = setInterval(() => this.countDown(data), 1000);
            }
          }
        }
      );
    } else {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.props.setTimerData(null);
      this.props.setResumeTimerValue(null);

      this.props.setIsTimerStarted(false);
      this.setState({
        isShowTimer: false,
        seconds: 0,
        timeToShow: "",
      });
      this.props.firebase.deleteTimerValue(this.callSessionId);
    }
  }

  async countDown(data) {
    const {
      userInfo: { callTimer },
    } = this.props;
    const { RESUME, START } = CALL_TIMER_STATUS;

    if (callTimer && callTimer.updatedValue <= 0) {
      this.setState({ isShowTimer: false });
      clearInterval(this.timer);
      this.props.setTimerData(null);
      this.setState({
        isShowTimer: false,
        timeToShow: "",
        seconds: 0,
      });
      const callAudioEl = document.getElementById("callTimerEndTone");
      callAudioEl.play();
      this.props.firebase.deleteTimerValue(this.callSessionId);
      this.props.setResumeTimerValue(null);
      return;
    }
    if (
      (data.status === START || data.status === RESUME) &&
      (data.updatedValue > 0 || this.state.seconds > 0)
    ) {
      const updatedSecs = this.state.seconds - 1;

      this.props.setTimerData({
        ...callTimer,
        updatedValue: updatedSecs,
      });

      this.setState(
        {
          seconds: updatedSecs,
          isShowTimer: true,
        },
        () => {
          const { seconds } = this.state;
          this.setState({ timeToShow: this.getFormattedTimerVal(seconds) });
        }
      );
    }
  }

  getFormattedTimerVal = (seconds) => {
    let hrs = Math.floor(seconds / 3600);
    let min = Math.floor((seconds - hrs * 3600) / 60);
    let secs = seconds - hrs * 3600 - min * 60;
    secs = Math.round(secs * 100) / 100;
    let time = min < 10 ? "0" + min : min;
    time += ":" + (secs < 10 ? "0" + secs : secs);
    return time;
  };

  handleMuteButtonVisiblity(id, type) {
    if (type == "show" && !this.state.showMuteButttonId.includes(id)) {
      this.setState({ showMuteButttonId: [...this.state.showMuteButttonId, id] })
    }
    else {
      let idsToShow = this.state.showMuteButttonId.slice();
      this.state.showMuteButttonId.forEach(item => {
        if (!this.state.mutedRemoteStream.includes(item)) {
          this.setState({ showMuteButttonId: idsToShow.filter((i) => i !== item) })
        }
      })
    }
  }

  render() {
    const { remoteUsers ,showMuteButttonId } = this.state;
    const {
      isVideoMuted,
      isAudioMuted,
      isAudioEnabled,
      isVideoEnabled,
      isCallEnabled,
      isCameraEnabled,
      isRecorderEnabled,
      isCaptureImage,
      callEndBy,
      callEndAnimation,
      isScreenShareEnabled,
      isShowScreenShareView,
      screenShareTooltipText,
      isRecordingState,
      videoRecordingTimer,
      isShowGuestLoginPopup,
      isLoading,
      isShowTimer,
      timeToShow,
      mutedRemoteStream,
      shareScreenBy,
    } = this.state;

    const {
      userInfo: {
        userId,
        username,
        isGuestMode,
        askToJoinCallNotifications,
        isAnonymous,
        // isPremium,
      },
      firebase,
      joinedFriends,
    } = this.props;
    const isPremium = true;
    const sortedRemoteUsers = remoteUsers;
    const count = sortedRemoteUsers.length ? sortedRemoteUsers.length + 1 : 1;
    const isSingle = sortedRemoteUsers.length;
    const isSideView = isShowScreenShareView;
    return (
      <>
        <div className="video-view">
          {this.renderRecordingView()}
          {this.renderAnimationView()}
          {this.renderSnapTimerView()}
          {this.state.showCallEndAlert && (
            <p className="callEndMsg">Call is going to end</p>
          )}
          <CallDisconnectAnimationView
            username={callEndBy}
            animationType={callEndAnimation}
          />
          <audio id="callTimerEndTone" src={callTimerEndTone} />
          <audio id="endCallToneForNinja" src={ninja} />
          <audio id="endCallToneForSurfingPig" src={surfingPig} />
          <audio id="endNormalCall" src={normalCallEnd} />
          <div
            className={cx(
              "video-view-container position-relative",
              `length-${sortedRemoteUsers.length + 1}`,
              {
                activeScreen: isShowScreenShareView,
              }
            )}
            id="video-container"
          >
            {isLoading && <BasicLoader />}
            <div className={cx("videosWrapper", { isSideView })}>
              <div
                className={cx("firstVideo", {
                  "d-none": shareScreenBy === userId && isShowScreenShareView,
                })}
                id={"parent-container0"}
              >
                <div>
                  <video
                    ref={(localVideo) => {
                      this.localVideoRef = localVideo;
                    }}
                    id="localVideo"
                    autoPlay
                    muted
                    playsInline
                    className={cx({
                      isCoverFit: !isSingle,
                    })}
                  ></video>
                </div>
              </div>

              {!!sortedRemoteUsers.length &&
                sortedRemoteUsers.map((remoteUser, index) => {
                  const currentRemoteUser = joinedFriends[remoteUser];
                  return (
                    <div
                    onMouseOver={()=> this.handleMuteButtonVisiblity(remoteUser ,"show")} 
                    onMouseOut={() => this.handleMuteButtonVisiblity(remoteUser ,"hide" )}
                      className={cx("position-relative", {
                        "d-none":
                          currentRemoteUser.userId === shareScreenBy &&
                          isShowScreenShareView,
                      })}
                      key={remoteUser}
                      id={"parent-container" + (index + 1)}
                    >
                      {this.remoteVideoRef[remoteUser] &&
                        this.remoteVideoRef[remoteUser].srcObject &&
                        isPremium && (
                          <button
                            className={cx("btn-icon video-speaker d-none", {
                              "d-block": showMuteButttonId.includes(remoteUser)
                            })}
                            onClick={() => this.muteRemoteStream(remoteUser)}
                          >
                            {mutedRemoteStream.includes(remoteUser) ? (
                              <MutedMiceIcon />
                            ) : (
                              <MiceIcon />
                            )}
                          </button>
                        )}

                      <div>
                        <video
                          ref={(remoteVideo) => {
                            this.remoteVideoRef[remoteUser] = remoteVideo;
                          }}
                          id={"remoteVideo" + remoteUser}
                          autoPlay
                          playsInline
                          className={cx({
                            isCoverFit: !isSingle,
                          })}
                        ></video>
                        {currentRemoteUser.isMuted && (
                          <div className="disabled-audio">
                            <MicIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>

            {isShowTimer && !!timeToShow && (
              <p className="time-count">{timeToShow}</p>
            )}

            {isShowScreenShareView && (
              <ScreenShareComponent
                count={count}
                isPresenter={isShowScreenShareView === userId}
              />
            )}
          </div>
          <div className="btnWrapper">
            <div className="d-flex align-items-center justify-content-between">
              <button
                id="cameraBtn"
                title={
                  isCameraEnabled
                    ? "Click to capture your video screen."
                    : "Video screen capture is disabled."
                }
                className={cx("btn-icon cameraBtn", {
                  disable: !isCameraEnabled,
                })}
                disabled={!isCameraEnabled}
                onClick={this.onCaptureScreen}
              >
                <CameraIcon />
              </button>
              {!IS_HIDE_VIDEO_RECORDING_FEATURE && (
                <button
                  id="recorderBtn"
                  title={
                    isRecordingState
                      ? "You are recording your video meeting."
                      : videoRecordingTimer
                      ? "You are already in video meeting recording mode."
                      : isSafariBrowser()
                      ? "Video meeting recording not supported."
                      : isRecorderEnabled
                      ? "Click to start recording video meeting."
                      : "Video meeting recording is disabled."
                  }
                  className={cx("btn-icon recorderBtn mx-5", {
                    disable: isSafariBrowser() || !isRecorderEnabled,
                  })}
                  disabled={isSafariBrowser() || !isRecorderEnabled}
                  onClick={this.onClickRecordMeeting}
                >
                  <RecordIcon />
                </button>
              )}
            </div>
            <div className="centerIconWrapper">
              <button
                id="callAcceptBtn"
                title={
                  isAudioEnabled
                    ? isAudioMuted
                      ? "Click to unmute your audio."
                      : "Click to mute your audio."
                    : "Audio mute is disabled."
                }
                className={cx("btn-icon mic", {
                  disabled: isAudioMuted,
                  "call-btn-disable": !isAudioEnabled,
                })}
                disabled={!isAudioEnabled}
                onClick={this.onMuteSound}
              >
                <MicIcon />
              </button>
              <CallDisconnectView
                isCallEnabled={isCallEnabled}
                onSelect={this.onSelectCallEndOption}
              />

              <button
                id="startVideoBtn"
                title={
                  isVideoEnabled
                    ? isVideoMuted
                      ? "Click to unmute your video."
                      : "Click to mute your video."
                    : "Video mute is disabled."
                }
                className={cx("btn-icon video", {
                  disabled: isVideoMuted,
                  "call-btn-disable": !isVideoEnabled,
                })}
                disabled={!isVideoEnabled}
                onClick={this.onMuteVideo}
              >
                <VideoCameraIcon />
              </button>
              <button
                id="callShareBtn"
                className={cx("btn-icon share", {
                  "call-btn-disable":
                    !isScreenShareEnabled ||
                    !!isShowScreenShareView ||
                    !sortedRemoteUsers.length ||
                    isVideoMuted,
                })}
                onClick={this.onScreenShare}
                title={
                  isVideoMuted
                    ? "Video must be enabled to start screen sharing"
                    : screenShareTooltipText || ""
                }
                disabled={
                  !isScreenShareEnabled ||
                  !!isShowScreenShareView ||
                  !sortedRemoteUsers.length ||
                  isVideoMuted
                }
              >
                <ScreenShareIcon />
              </button>
            </div>

            <label
              className={cx("btn-icon", {
                "upload-btn-disable": isGuestMode && !isAnonymous,
              })}
              style={{ cursor: "pointer" }}
              title={
                isGuestMode && !isAnonymous
                  ? "Not available for guest user."
                  : "Click to upload image."
              }
            >
              <input
                type="file"
                style={{ display: "none" }}
                accept=".jpeg,.jpg,.png,.gif"
                onChange={(e) => this.onAddMedia(e)}
                disabled={isGuestMode && !isAnonymous}
              />
              <AddIcon />
            </label>
          </div>
          <div className="overflow-hidden" style={{ width: 0, height: 0 }}>
            <canvas id="canvas"></canvas>
          </div>
          <div
            className="screenShotViewWrapper"
            style={{
              transform: `translate(${!isCaptureImage ? -100 : 0}%)`,
            }}
          >
            <img
              className="image"
              id="screenShotView"
              alt=""
              crossOrigin="true"
            />
            {isCaptureImage && (
              <div className="btns">
                <button
                  className="btn-icon facebook"
                  title="Share To Facebook"
                  onClick={this.onClickShareToFB}
                >
                  <i className="fab fa-facebook-f"></i>
                </button>
                <button
                  className="btn-icon"
                  title="Close"
                  onClick={this.onCloseScreenCaptureMode}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            )}
          </div>
          <CallDisconnectAnimationView
            username={callEndBy}
            animationType={callEndAnimation}
          />
          <div style={{ width: 0, height: 0, overflow: "hidden" }}>
            <canvas id="recording-canvas"></canvas>
          </div>
          {isShowGuestLoginPopup && (
            <GuestCallPasswordModal
              isOpen={isShowGuestLoginPopup}
              isGuestMode={isGuestMode}
              userId={userId}
              name={username}
              firebase={firebase}
              notifications={askToJoinCallNotifications}
              onJoinByHost={this.onAcceptStatus}
              onAskToJoin={this.handleOnAskToJoinCall}
              onRejectStatus={this.onRejectStatus}
              onAcceptStatus={this.onAcceptStatus}
            />
          )}
        </div>
      </>
    );
  }
}

export default VideoChatComponent;
