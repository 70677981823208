import * as loginAction from "../../redux/login/action";

import { Button, Container } from "reactstrap";
import React, { Component } from "react";

import Alert from "../../components/shared/common/alert";
import { Heart } from "../../components/svgIcons";
import { ROUTES } from "../../components/shared/constants";
import { compose } from "redux";
import { connect } from "react-redux";
import { setUserChatDetail } from "../../redux/user-chat/action";
import { withFirebase } from "../../firebase/context";
import { withRouter } from "react-router-dom";

class WelcomeComponent extends Component {
  constructor(props) {
    super(props);
    this.onClickStartVideoCall = this.onClickStartVideoCall.bind(this);
  }

  onClickStartVideoCall() {
    const {
      userInfo: { isLoggedIn },
    } = this.props;
    if (!isLoggedIn) {
      const navigateTo = ROUTES.user.login;
      this.props.history.push(navigateTo, { isStartVideoCall: true });
    } else {
      this.startVideoCall();
    }
  }

  startVideoCall() {
    const { props } = this;
    const loginUserData = props.loginReducer;
    let currentUserId = loginUserData.userId;
    const currentCallVal = sessionStorage.getItem("currentCallId");
    if (currentCallVal && currentCallVal !== "") {
      props.history.push(`/call/${currentCallVal}`, { isCallSession: true });
    } else {
      let userObj = {
        userId: loginUserData.userId,
        status: "ACCEPTED",
      };

      let obj = {
        timestamp: Date.now(),
        start: true,
        creatorId: loginUserData.userId,
        startedAt: Date.now()
      };
      obj.invited = {};
      obj.invited[currentUserId] = userObj;

      props.firebase
        .addUserCall("calls", obj)
        .then((resp) => {
          props.history.push(`/call/${resp.key}`, { isCallSession: true });
          let obj = {
            call_status: "DIRECT_CALL",
            id: resp.key,
          };
          props.setOrUnsetCurrentCall(obj);

          props.setUserChatDetail({});
        })
        .catch((e) => {
          console.log("error: ", e);
          Alert(400, "Unable to start call.");
        });
    }
  }

  render() {
    const { screenAnimations } = this.props;
    const animationUrl =
      screenAnimations && screenAnimations.welcome
        ? screenAnimations.welcome.animationUrl
        : "";
    return (
      <section className="bg-white py-3 welcome">
        <Container className="text-center">
          <div className="gifWrapper">
            {animationUrl && (
              <img className="w-100" src={animationUrl} alt="welcome" />
            )}
          </div>
          <p className="main-text">
            Can’t live without you <Heart />
          </p>
          <Button color="primary" onClick={this.onClickStartVideoCall}>
            Start a video call now
          </Button>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
  screenAnimations: state.screenAnimations.animations,
  loginReducer: state.loginReducer,
  adminEventReducer: state.adminEventReducer,
  callEndAnimations: state.callEndAnimations.animations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setUserChatDetail,
    setCallEndAnimationType: (data) =>
      dispatch(loginAction.setCallEndAnimationType(data)),
  };
};

export default compose(
  withRouter,
  withFirebase
)(connect(mapStateToProps, mapDispatchToProps)(WelcomeComponent));
