/* eslint-disable */

// Constant
export const ADD_EVENT_VENUE = "ADD_EVENT_VENUE";
export const DELETE_EVENT_VENUE = "DELETE_EVENT_VENUE";
export const SET_EVENT_VENUE_VALUE = "SET_EVENT_VENUE_VALUE";
export const SET_EVENT_VENUE_NAME = "SET_EVENT_VENUE_NAME";
export const SET_EVENT_VENUE_PHOTO = "SET_EVENT_VENUE_PHOTO";
export const RESET_EVENT_VENUE_STATE = "RESET_EVENT_VENUE_STATE";
export const SET_EVENT_DETAIL = "SET_EVENT_DETAIL";

/**
 *
 * @param {Object} eventPayload :
 *
 * {
 *  @param {string} photoUrl
 *  @param {string} locationName
 *  @param {string} locationWebsite
 * }
 */
export const addEventVenue = (eventPayload) => ({
  type: ADD_EVENT_VENUE,
  payload: eventPayload,
});

/**
 *
 * @param {number} indexOfEvent
 */
export const deleteEventVenue = (indexOfEvent) => ({
  type: DELETE_EVENT_VENUE,
  payload: indexOfEvent,
});

/**
 *
 * @param {Object} eventVenuePayload :
 *  {
 *  @param {string} key
 *  @param {number} index
 *  @param {string} value
 * }
 */
export const setEventVenue = (eventVenuePayload) => ({
  type: SET_EVENT_VENUE_VALUE,
  payload: eventVenuePayload,
});

/**
 *
 * @param {string} eventName
 */
export const setEventVenueName = (eventName) => ({
  type: SET_EVENT_VENUE_NAME,
  payload: eventName,
});

/**
 *
 * @param {string} eventVenuePhoto : Url of photo
 */
export const setEventVenuePhoto = (eventVenuePhoto) => ({
  type: SET_EVENT_VENUE_PHOTO,
  payload: eventVenuePhoto,
});

export const resetEventVenue = () => ({
  type: RESET_EVENT_VENUE_STATE,
});


/**
 * 
 * @param {Object} eventDetail : Contains full detail of event
 * {
 *  @param {string} name
 *  @param {string} photoUrl
 *  @param {Array} venueList : Array of objects
 *  {
 *    @param {string} photoUrl
 *    @param {string} locationName
 *    @param {string} locationWebsite
 *  },
 * }
 * 
 */
export const setEventDetail = (eventDetail) => ({
  type: SET_EVENT_DETAIL,
  payload: eventDetail,
});
