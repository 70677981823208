import {
  DELETE_ADMIN_EVENT,
  SET_EVENTS,
  SET_NEW_EVENT,
  RESET_ADMIN_EVENT,
} from "./action";

const defaultState = {
  events: {},
};

const adminEventReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }

    case SET_NEW_EVENT: {
      const prevState = { ...state.events };
      const { key, event } = action.payload;
      prevState[key] = event;

      return {
        ...state,
        events: prevState,
      };
    }

    case DELETE_ADMIN_EVENT: {
      const prevState = Object.assign({}, state.events);
      delete prevState[action.payload];

      return {
        ...state,
        events: prevState,
      };
    }

    case RESET_ADMIN_EVENT: {
      return {
        ...state,
        events: {},
      };
    }

    default:
      return state;
  }
};

export default adminEventReducer;
