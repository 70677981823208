import * as loginAction from '../../redux/login/action';

import Alert from '../../components/shared/common/alert';
import ImageBox from '../../components/imageBox';
import React from 'react';
import addeventIcon from '../../assets/images/icons/addCalander.svg';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUserChatDetail } from '../../redux/user-chat/action';
import videoCamerIcon from '../../assets/images/icons/video-camera.svg';
import { withFirebase } from '../../firebase/context';
import { withRouter } from 'react-router-dom';

const optionsData = [
  {
    id: 'createUserEvent',
    name: 'Events',
    icon: addeventIcon,
  },
  {
    id: 'startNewVideoBtn',
    name: 'Start video',
    icon: videoCamerIcon,
  },
];

const Dashboard = (props) => {
  const loginUserData = props.loginReducer;
  let currentUserId = loginUserData.userId;
  const onButtonClick = (name) => {
    if (name === 'Events') {
      props.history.push('/events');
    }
    if (name === 'Start video') {
      const currentCallVal = sessionStorage.getItem('currentCallId');
      if (currentCallVal && currentCallVal !== '') {
        props.history.push(`/call/${currentCallVal}`, { isCallSession: true });
      } else {
        let userObj = {
          userId: loginUserData.userId,
          status: 'ACCEPTED',
        };

        let obj = {
          timestamp: Date.now(),
          start: true,
          creatorId: loginUserData.userId,
        };
        obj.invited = {};
        obj.invited[currentUserId] = userObj;

        props.firebase
          .addUserCall('calls', obj)
          .then((resp) => {
            props.history.push(`/call/${resp.key}`, { isCallSession: true });
            let obj = {
              call_status: 'DIRECT_CALL',
              id: resp.key,
            };
            props.setOrUnsetCurrentCall(obj);
            props.setUserChatDetail({});
          })
          .catch(() => {
            Alert(400, 'Unable to start call.');
          });
      }
    }
  };

  let animationOneUrl = '';
  let animationTwoUrl = '';
  let animationUrl = '';

  const callAnimationType = loginUserData.callAnimationType;
  if (props.callEndAnimations) {
    const { animationOne, animationTwo } = props.callEndAnimations;
    animationOneUrl = (animationOne && animationOne.animationUrl) || '';
    animationTwoUrl = (animationTwo && animationTwo.animationUrl) || '';
    animationUrl =
      callAnimationType === 'animationOne'
        ? animationOneUrl
        : callAnimationType === 'animationTwo'
        ? animationTwoUrl
        : '';
  }

  if (callAnimationType) {
    setTimeout(() => {
      props.setCallEndAnimationType('');
    }, 5000);
  }
  const animationStyle = {
    position: 'fixed',
    zIndex: 999,
    width: 300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  };
  return (
    <div className="event-options-screen">
      {optionsData.map(({ id, name, icon }, index) => (
        <div key={index} className="c-col">
          <button
            id={id}
            onClick={() => onButtonClick(name)}
            className="btn-icon imgBoxBtnWrapper"
          >
            <ImageBox imagePath={icon} className="mx-auto mb-3 border-0" />
            <p>{name}</p>
          </button>
        </div>
      ))}
      {callAnimationType && animationUrl && (
        <img style={animationStyle} src={animationUrl} alt={''} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginReducer: state.loginReducer,
  adminEventReducer: state.adminEventReducer,
  callEndAnimations: state.callEndAnimations.animations,
  openFeedBackModal: state.loginReducer.isOpenFeedBackModal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    // setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
    setUserChatDetail,
    setCallEndAnimationType: (data) =>
      dispatch(loginAction.setCallEndAnimationType(data)),
    openFeedbackModal: (data) => dispatch(loginAction.openFeedBackModal(data)),
  };
};

// export default connect(mapStateToProps, null)(Dashboard);
export default compose(
  withRouter,
  withFirebase
)(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
