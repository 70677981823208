import React from "react";
import { ForgotPasswordForm } from "../../components/form";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

export default () => (
  <div className="main-form-wrapper login-form">
    <div className="w-100">
      <Card>
        <CardBody>
          <div className="p-sm-4">
            <center>
              <h2 className="mb-4 title">Bonfire</h2>
            </center>
            <ForgotPasswordForm />
          </div>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardBody>
          <p className="text-center  mb-0 linkText">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </CardBody>
      </Card>
    </div>
  </div>
);
