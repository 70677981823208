import * as loginAction from '../../redux/login/action';
import { Container } from 'reactstrap';
import React, { Component } from 'react';
import Alert from '../../components/shared/common/alert';
import { connect } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { EMAIL_NOTIFICATION } from '../../components/shared/constants';
import * as loaderAction from '../../redux/loader/action';
import { Heart } from '../../components/svgIcons';
import {ROUTES} from "../../components/shared/constants";
import callTimerEndTone from "../../assets/audio/bells_call.mp3";
import ninja from "../../assets/audio/ninja.mp3";
import surfingPig from "../../assets/audio/surfing_pig.mp3";
import normalCallEnd from "../../assets/audio/normal_end.mp3";

class FeedbackComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
      rating: 0,
      isSubmitted: false,
    };
    this.ratingChanged = this.onRatingChanged.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSend = this.onSend.bind(this);
  }

   onRatingChanged = (event) => {
    this.setState({
      rating: event
    })
  }
  componentDidMount(){
    const callAnimationType = this.props.loginReducer.callAnimationType;
if(callAnimationType === "animationOne"){
  const callAudioEl =  document.getElementById("ninjaTone");
      callAudioEl.play();
}
else if(callAnimationType === "animationTwo"){
  const callAudioEl =  document.getElementById("surfingPigTone");
      callAudioEl.play();
}
else if(callAnimationType === "NORMAL_CALL_END"){
    const callAudioEl =  document.getElementById("normalCallEndTone");
      callAudioEl.play();
}
if(callAnimationType === "animationTwo"){
  setTimeout(() => { 
    this.props.setCallEndAnimationType('');
  }, 4000);
}else{
  setTimeout(() => { 
    this.props.setCallEndAnimationType('');
  }, 2500);
}
   
  }

  onChange = (event) => {
    this.setState({
      feedback: event.target.value
    });
  }

   onSend(){
    const { feedback, rating } = this.state;
    if (!feedback || !rating) {
      Alert(400, 'Please fill all fields!!');
      return;
    }
    this.props.setIsLoading(true);
    this.onSendInviteEmail(feedback, rating);
    setTimeout(() => { 
      this.props.history.push(ROUTES.user.dashboard);
    }, 3000);
  }

  onSendInviteEmail = (feedback, rating) => {
    const text = `<div><p>Message: <b>${feedback}</b></p><p>Rating: <b>${rating}</b></p></div>`;
    const msg = {
      to: EMAIL_NOTIFICATION.CONTACT_US_TO,
      from: 'no-reply@ourbonfire.com',
      subject: 'Feedback for ourBonfire.com',
      text: text,
    };
    const body = JSON.stringify(msg);
    fetch(EMAIL_NOTIFICATION.INVITE_API, {
      method: 'POST',
      body: '' + body,
    })
      .then((response) => response.json())
      .then((json) => {
        Alert(200, 'Feedback sent successfully.');
        this.props.setIsLoading(false);
        this.setState({ isSubmitted: true });
      })
      .catch((err) => {
        Alert(400, 'Unable to send feedback at this time.');
        this.props.setIsLoading(false);
      });
  };

  render() {
    let animationOneUrl = '';
    let animationTwoUrl = '';
    let animationUrls = '';
    const callAnimationType = this.props.loginReducer.callAnimationType;
    if (this.props.callEndAnimations) {
      const { animationOne, animationTwo } = this.props.callEndAnimations;
      animationOneUrl = (animationOne && animationOne.animationUrl) || '';
      animationTwoUrl = (animationTwo && animationTwo.animationUrl) || '';
      animationUrls =
        callAnimationType === 'animationOne'
          ? animationOneUrl
          : callAnimationType === 'animationTwo'
          ? animationTwoUrl
          : '';
    }

    if (callAnimationType) {
      setTimeout(() => {
        this.props.setCallEndAnimationType('');
      }, 5000);
    }
    const animationStyle = {
      position: 'fixed',
      zIndex: 999,
      width: 300,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    };
    const { screenAnimations } = this.props;
    const { feedback } = this.state;

    const animationUrl =
      screenAnimations && screenAnimations.feedback
        ? screenAnimations.feedback.animationUrl
        : '';
    return (
      <section className="bg-white py-3 welcome feedback-view">
                        <audio id="callTimerEndTones" src={callTimerEndTone}/>
                        <audio id="ninjaTone" src={ninja}/>
                        <audio id="surfingPigTone" src={surfingPig}/>
                        <audio id="normalCallEndTone" src={normalCallEnd}/>
        <Container className="text-center">
          {!this.state.isSubmitted ? (
            <div>
              <p className="text-purple feedbackTitle ">How was your call?</p>
              <ReactStars
                classNames="react-stars mx-auto"
                count={5}
                onChange={this.onRatingChanged}
                size={40}
                color="transparent"
                activeColor="#7b37d3"
              />
              <FormGroup className="feedback-form-item mb-3">
                <Input
                  type="textarea"
                  value={feedback}
                  id="feedback"
                  placeholder="You can also tell us how you feel!"
                  onChange={this.onChange}
                />
              </FormGroup>
              <Button color="purple" className="feedback" onClick={this.onSend}>
                Submit
              </Button>
            </div>
          ) : (
            <div>
              <p className="main-text">
                You are awesome! Thanks for your feedback.
              </p>
              <p className="main-text">
                Can’t live without you <Heart />
              </p>
            </div>
          )}
          <div className="gifWrapper">
            {animationUrl && (
              <img className="w-100" src={animationUrl} alt="welcome" />
            )}
          </div>
          {callAnimationType && animationUrls && (
            <img style={animationStyle} src={animationUrls +"&"+ Math.random()} alt={''} />
          )}
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
  screenAnimations: state.screenAnimations.animations,
  loginReducer: state.loginReducer,
  adminEventReducer: state.adminEventReducer,
  callEndAnimations: state.callEndAnimations.animations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCallEndAnimationType: (data) =>
      dispatch(loginAction.setCallEndAnimationType(data)),
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackComponent);
