import React from "react";

export default ({ isOpen, venueList, onSelect }) => {
  if (!isOpen) {
    return null;
  }

  const renderVenueItem = (venue, index) => {
    return (
      <div
        key={index}
        className="eventDetailList mb-3"
        onClick={() => onSelect(venue)}
      >
        <div className="d-flex align-items-center w-100">
          <img
            src={venue.photoUrl}
            alt="Bonfire"
            className="locationImg mr-2"
          />
          <p className="mb-0 text-capitalize">{venue.locationName}</p>
        </div>
      </div>
    );
  };

  const renderEmptyList = () => {
    if (venueList.length) {
      return;
    }
    return (
      <div className="mb-3">
        <div className="">
          <p className="mb-0 text-center">No venue found.</p>
        </div>
      </div>
    );
  };

  return (
    <div className="eventDetailDropdown">
      <div className="eventscroll">
        {venueList.map((venue, index) => {
          return renderVenueItem(venue, index);
        })}
        {renderEmptyList()}
      </div>
    </div>
  );
};
