/**
 * @class Validation : Component for defining validation methods.
 */
export default class Validation {
    /**
     * @method noSpecialChars : Method to check data passed contains special symbols or not, if contains then false is returned.
     * @param {*} str : Data value to be tested.
     */
    noSpecialChars(str) {
      str = String(str);
      return !/[~/`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?_.()@]/g.test(str);
    }
    
  
    static allLetter(inputTxt) {
      const letters = /^[A-Za-z]+$/;
      if (inputTxt.match(letters)) {
        return true;
      }
      return false;
    }
  
    /**
     * @method passwordValidation : Method to validate password if password contains characters less then 6, false is returned.
     * @param {*} str : password value to be tested.
     */
    static passwordValidation(str) {
      if (str.length < 4) {
        return false;
      }
      return true;
    }
  
    /**
     * @method validateEmail : Method to validate email, if email contains characters, not according to given email format, false is returned.
     * @param {*} str : email value to be tested.
     */
    static validateEmail(value) {
      // eslint-disable-next-line
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    }
  }
  