import React, { useState } from "react";
import { FormGroup, Input, Button } from "reactstrap";
import Alert from "../../shared/common/alert";
import { withFirebase } from "../../../firebase/context";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as loaderAction from "../../../redux/loader/action";
import { validateForgotPasswordFields } from "../../shared/common/validation";

const ForgotPassword = (props) => {
  const [info, setInfo] = useState({});
  const fields = [
    {
      label: "Enter email",
      item: "email",
      type: "text",
      size: 6,
    },
  ];

  const onInputChange = (e, field) => {
    setInfo({ ...info, [field.item]: e.target.value });
  };

  const submit = async () => {
    if (Object.keys(info).length === 0) {
      Alert(400, "Please fill the form.");
      return false;
    }

    const isValidData = validateForgotPasswordFields(info);

    if (!isValidData) {
      return false;
    }

    props.setIsLoading(true);
    props.firebase
      .authorization()
      .sendPasswordResetEmail(info.email)

      .then(function (user) {
        props.setIsLoading(false);
        Alert(200, "Successfully done.Please check your email.");
      })
      .catch(function (e) {
        props.setIsLoading(false);
        Alert(200, e.message);
      });
  };

  return (
    <>
      <>
        {fields.map((field, index) => {
          return (
            <FormGroup key={index} className="mb-2">
              <Input
                type={field.type}
                onChange={(e) => {
                  onInputChange(e, field);
                }}
                placeholder={field.label}
                name={field.item}
                id={field.item}
              />
            </FormGroup>
          );
        })}
      </>
      <Button
        onClick={() => submit()}
        color="primary"
        className="w-100 mt-2 mb-3"
      >
        Forgot password
      </Button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
  };
};
export default compose(
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(ForgotPassword);
