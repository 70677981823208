import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import Alert from "../../shared/common/alert";
import { withFirebase } from "../../../firebase/context";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import avatarIcon from "../../../assets/images/icons/avatar.svg";
import { cloneArrayOfObject } from "../../../components/shared/utils";
import {
  SCHEMA,
  MAX_USER_SUPPORTED_FOR_CALL,
} from "../../../components/shared/constants";
import { BasicLoader } from "../../shared/common/loader";
import { CallIcon } from "../../svgIcons";

class ConnectWithFriends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      friendList: [],
      dropdownlist: [],
      filteredFriendList: [],
      isLoading: false,
      isFetched: false,
    };
  }

  getCallInvitedUsers = async (currentCallId, userId) => {
    let newObj = {};
    await this.props.firebase.getUserCallData(currentCallId, (val) => {
      newObj = Object.assign({}, val);
      return newObj;
    });
    return newObj;
  };

  async componentDidMount() {
    const {
      userInfo: { userId },
      firebase,
    } = this.props;
    let friendsList = this.props.userInfo.friends;
    const userList = Object.values(friendsList);
    const userData = this.props.userInfo;

    this.setState({
      isLoading: true,
    });

    const { pathname } = window.location;
    const path = pathname.split("/");
    const callId = path[2];

    if (callId) {
      let currentCallId = userData.currentCallId || callId;
      const callData = await this.getCallInvitedUsers(currentCallId, userId);
      let invitedFriends = [];
      if (Object.keys(callData).length > 0) {
        if (callData.invited) {
          invitedFriends = Object.values(callData.invited);
        }
      }

      let acceptedfriendsList = [];
      acceptedfriendsList = cloneArrayOfObject(
        userList.filter((val) => val && val.status === "ACCEPTED")
      );

      if (acceptedfriendsList && acceptedfriendsList.length > 0) {
        for (const val of acceptedfriendsList) {
          await firebase.getData("/users/" + val.userId, (user) => {
            const friendList = cloneArrayOfObject(this.state.friendList);

            const tempList = [];
            for (const i of friendList) {
              if (i && i.userId) {
                tempList.push(i.userId);
              }
            }

            if (user && !tempList.includes(val.userId)) {
              let obj = {
                email: user.email,
                photoUrl: user.photoUrl,
                role: user.role,
                username: user.username,
                userId: val.userId,
                isConnected: true,
                isInvited: false,
                status: "",
              };

              if (invitedFriends && invitedFriends.length > 0) {
                const friendIndex = invitedFriends.findIndex(
                  (friend) => friend.userId === val.userId
                );

                if (friendIndex !== -1) {
                  let friend = invitedFriends[friendIndex];

                  if (
                    friend.status === "PENDING" ||
                    friend.status === "ACCEPTED"
                  ) {
                    if (friend.status === "PENDING") {
                      obj.status = "PENDING";
                    } else {
                      obj.status = "ACCEPTED";
                    }
                    obj.isInvited = true;
                  }
                }
              }

              friendList.push(obj);
            }

            this.setState({
              isFetched: true,
              dropdownlist: friendList,
              friendList: friendList,
              isLoading: false,
            });
          });
        }
      } else {
        this.setState({
          isFetched: true,
          isLoading: false,
        });
      }
    }
  }

  handleChangeFriendsInput = (event) => {
    const { friendList } = this.state;
    let updatedList = [];
    if (event.target.value !== "") {
      const username = event.target.value.toLowerCase();
      const friendsArr = Object.values(friendList);

      const filteredList = friendsArr.filter((item) =>
        item.username.toLowerCase().includes(username)
      );

      if (filteredList) {
        updatedList = filteredList;
      } else {
        updatedList = username ? [] : friendList;
      }
      this.setState({
        dropdownlist: updatedList,
      });
    } else {
      this.setState({
        dropdownlist: friendList,
      });
    }
  };

  onSelectFriend = async (user, index) => {
    let loginUserData = this.props.userInfo;
    this.setState({
      isCallDisabled: true,
    });

    let joinedFriends = {};
    await this.props.firebase.getPresentCallUsers(
      loginUserData.currentCallId,
      (res) => {
        if (!!res) {
          joinedFriends = Object.assign({}, res);
        }
      }
    );

    if (
      joinedFriends &&
      Object.keys(joinedFriends).length >= MAX_USER_SUPPORTED_FOR_CALL
    ) {
      Alert(
        400,
        `Sorry you can't add more firends. Only ${MAX_USER_SUPPORTED_FOR_CALL} people are allowed to participate in call.`
      );
      this.setState({
        isCallDisabled: false,
      });
      return;
    }

    this.getDataRef = this.props.firebase.getUserCallNotifications(
      user.userId,
      () => {}
    );

    await this.getDataRef.then((resData) => {
      if (resData && resData.val()) {
        Alert(400, "User is busy");
        this.setState({
          isCallDisabled: false,
        });
      } else {
        let connectedFriends = this.state.dropdownlist.slice();
        connectedFriends[index].isInvited = true;
        connectedFriends[index].status = "PENDING";

        this.setState({
          dropdownlist: connectedFriends,
        });

        let userToInviteId = connectedFriends[index].userId;
        const timestamp = Date.now();
        let obj = {
          userId: userToInviteId,
          status: "PENDING",
          timestamp,
        };

        if (loginUserData && loginUserData.currentCallId) {
          this.props.firebase
            .addCall(loginUserData.currentCallId, userToInviteId, obj)
            .then((resp) => {
              let object = {
                senderName: loginUserData.username,
                timestamp,
                creatorId: loginUserData.userId,
                type: "CALL_INVITE",
                photoUrl: loginUserData.profilePhotoUrl,
                status: "PENDING",
                isread: false,
                callId: loginUserData.currentCallId,
              };

              this.props.firebase
                .addUserCallNotification(SCHEMA.USERS, userToInviteId, object)
                .then((res) => {
                  this.props.firebase.updateCall(
                    loginUserData.currentCallId,
                    userToInviteId,
                    { notificationKey: res.key }
                  );
                });
              this.setState({
                isCallDisabled: false,
              });
            });
        }
      }
    });
  };

  handleCallEnd = async (user) => {
    const {
      allInvitations,
      onCallEnd,
      userInfo: { currentCallId },
    } = this.props;
    const invitedDetail = allInvitations[user.userId];

    if (invitedDetail) {
      await onCallEnd({
        notificationKey: invitedDetail.notificationKey,
        userToInviteId: user.userId,
        callId: currentCallId,
      });
    }
  };

  renderEmptyList = () => {
    const { isFetched, dropdownlist } = this.state;
    let hasFriends = dropdownlist.length;

    if (dropdownlist.length) {
      return;
    }
    return (
      <div className="eventDetailList mb-3">
        <div className="d-flex align-items-center">
          <p className="mb-0" style={{ whiteSpace: "initial" }}>
            {!isFetched
              ? "Fetching friends record..."
              : !!hasFriends
              ? "You don't have any friends."
              : "No friend available."}
          </p>
        </div>
      </div>
    );
  };

  renderCallStatus(callStatus, user, index) {
    const { isCallDisabled } = this.state;

    if (!callStatus) {
      return (
        <button
        title="Click to start calling"
          onClick={() => {
            this.onSelectFriend(user, index);
          }}
          disabled={isCallDisabled}
          className="btn-icon callBtn accept"
        >
          <CallIcon />
        </button>
      );
    }

    if (callStatus === "PENDING") {
      return (
        <>
          {/* <div style={{ fontWeight: "600", fontSize: "13px" }}>CALLING...</div> */}
          <button
          title="Click to end calling"
            onClick={() => {
              this.handleCallEnd(user);
            }}
            className="btn-icon callBtn reject"
          >
            <CallIcon />
          </button>
        </>
      );
    }

    return <div style={{ fontWeight: "600", fontSize: "13px" }}>CONNECTED</div>;
  }

  render() {
    const { isLoading, dropdownlist } = this.state;
    const { allInvitations } = this.props;

    return (
      <div className="normalCenterLoader">
        {isLoading && <BasicLoader />}
        <h3 className="sm-title">Connect with Friends</h3>
        <FormGroup className="rounded-input mb-4">
          <Input
            onChange={(e) => this.handleChangeFriendsInput(e)}
            placeholder="Start typing…"
          />
        </FormGroup>
        <div className="srollView">
          {dropdownlist && dropdownlist.length > 0
            ? dropdownlist.map((user, index) => {
                const invited = allInvitations[user.userId];
                let callStatus = "";
                if (invited) {
                  callStatus = invited.status;
                }

                return (
                  <div key={index} className="personConnect">
                    <div className="d-flex  align-items-center call-content">
                      <div>
                        <img
                          className="person-image"
                          src={user.photoUrl || avatarIcon}
                          alt="Bonfire"
                        />
                      </div>
                      <p
                        className="mb-0 text-capitalize pr-1"
                        style={
                          callStatus !== ""
                            ? { maxWidth: callStatus === "ACCEPTED" ? 80 : 95 }
                            : {}
                        }
                      >
                        {user.username}
                      </p>
                    </div>
                    {this.renderCallStatus(callStatus, user, index)}
                  </div>
                );
              })
            : this.renderEmptyList()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginReducer,
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, null)
)(ConnectWithFriends);
