import {
  DELETE_SPECIAL_SYMBOL,
  RESET_SPECIAL_SYMBOLS,
  SET_SPECIAL_SYMBOLS,
  SET_NEW_SPECIAL_SYMBOLS,
} from "./action";

const defaultState = {
  specialSymbols: {},
};

const chatSpecialSymbolReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SPECIAL_SYMBOLS: {
      return {
        ...state,
        specialSymbols: action.payload,
      };
    }

    case SET_NEW_SPECIAL_SYMBOLS: {
      const prevState = Object.assign({}, state.specialSymbols);
      const { key, symbol } = action.payload;
      prevState[key] = symbol;

      return {
        ...state,
        specialSymbols: prevState,
      };
    }

    case DELETE_SPECIAL_SYMBOL: {
      const prevState = Object.assign({}, state.specialSymbols);
      delete prevState[action.payload];

      return {
        ...state,
        specialSymbols: prevState,
      };
    }

    case RESET_SPECIAL_SYMBOLS: {
      return {
        ...state,
        specialSymbols: {},
      };
    }

    default:
      return state;
  }
};

export default chatSpecialSymbolReducer;
