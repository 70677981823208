import {
  SET_PROFILE_PHOTO,
  RESET_INVITED_FRIENDS,
  UPDATE_INVITED_FRIEND_STATUS,
  RESET_FRIEND_LIST_UPDATION_STATUS,
  SET_USERNAME,
  SET_CALL_END_ANIMATION_TYPE,
  SET_SCREEN_SHARE_MODE,
  SET_CALL_END_MODE,
  SET_GUEST_JOINED_MODE,
  SET_ASK_JOIN_CALL_NOTIFICATIONS,
  SET_ANONYMOUS_LOGIN,
  OPEN_FEEDBACK_MODAL,
  SET_RADIO_BUTTON_VALUE,
  RECENT_GIFS_LIST,
  SET_TIMER_DATA,
  SET_RESUME_TIMER_VALUE,
  IS_TIMER_STARTED,
  UPDATE_PREMIUM_STATUS,
  UPDATE_CURRENT_USER
} from './action';
import { THEME_SETTINGS } from '../../components/shared/constants';

const defaultState = {
  email: '',
  role: '',
  isLoggedIn: false,
  isLoading: false,
  username: '',
  profilePhotoUrl: '',
  authId: '',
  userId: '',
  friends: {},
  isFriendsUpdated: false,
  notifications: {},
  isCountUpdated: false,
  isCallCountUpdated: false,
  call_notifications: {},
  call_status: '',
  currentCallId: '',
  callAnimationType: '',
  isScreenSharingOn: false,
  endCallMode: '',
  isGuestMode: true,
  isGuestJoined: false,
  askToJoinCallNotifications: {},
  isAnonymous: false,
  isOpenFeedBackModal: false,
  selectedTheme: THEME_SETTINGS.LIGHT,
  recentGifs: [],
  callTimer: null,
  resumeTimerValue:null,
  isTimerStarted: false,
  loginSessionStartAt: null,
  isPremium: false,
};

const getUpdatedFriendsAfterAdd = (state, action) => {
  const friends = Object.assign({}, state.friends);

  let keyToInsert = action.data.key;
  delete action.data['key'];
  friends[keyToInsert] = action.data;

  return friends;
};
const getUpdatedNotificationsAfterAdd = (state, action) => {
  const allNotifications = action.data;
  return allNotifications;
};

const getUpdatedCallNotificationsAfterAdd = (state, action) => {
  const allNotifications = action.data;
  return allNotifications;
};

const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LOGIN_DETAILS': {
      const loginUserData = action.data;

      return {
        ...state,
        email: loginUserData.email,
        username: loginUserData.username,
        authId: loginUserData.authId,
        role: loginUserData.role,
        isLoggedIn: true,
        profilePhotoUrl: loginUserData.profilePhotoUrl,
        userId: loginUserData.userId,
        friends: loginUserData.friends ? loginUserData.friends : {},
        isGuestMode: false,
        loginSessionStartAt: Date.now(),
        isPremium: !!loginUserData.isPremium,
      };
    }

    case 'SET_COUNT': {
      return {
        ...state,
        isCountUpdated: action.data,
      };
    }

    case 'SET_CALL_COUNT': {
      return {
        ...state,
        isCallCountUpdated: action.data,
      };
    }

    case 'SET_USER_CALL': {
      sessionStorage.setItem('currentCallId', action.data.id);
      sessionStorage.setItem('call_status', action.data.call_status);
      return {
        ...state,
        currentCallId: action.data.id,
        call_status: action.data.call_status,
      };
    }
    case SET_SCREEN_SHARE_MODE: {
      sessionStorage.setItem('isScreenSharingOn', action.data);
      return {
        ...state,
        isScreenSharingOn: action.data,
      };
    }

    case 'SET_SESSION_CALL_DETAIL': {
      return {
        ...state,
        currentCallId: action.data.id,
        call_status: action.data.call_status,
      };
    }

    case SET_PROFILE_PHOTO: {
      return {
        ...state,
        profilePhotoUrl: action.data,
      };
    }

    case 'ADD_FRIEND': {
      const friends = getUpdatedFriendsAfterAdd(state, action);
      return {
        ...state,
        friends: friends,
      };
    }

    case RESET_INVITED_FRIENDS: {
      return {
        ...state,
        friends: Object.assign({}, action.data),
      };
    }
    case 'SET_NOTIFICATIONS': {
      const notifications = getUpdatedNotificationsAfterAdd(state, action);

      return {
        ...state,
        notifications: notifications,
      };
    }

    case 'SET_CALL_NOTIFICATIONS': {
      const callNotifications = getUpdatedCallNotificationsAfterAdd(
        state,
        action
      );

      return {
        ...state,
        call_notifications: callNotifications,
      };
    }

    case SET_ASK_JOIN_CALL_NOTIFICATIONS: {
      return {
        ...state,
        askToJoinCallNotifications: action.data,
      };
    }

    case UPDATE_INVITED_FRIEND_STATUS: {
      return {
        ...state,
        friends: Object.assign({}, action.data),
        isFriendsUpdated: true,
      };
    }

    case RESET_FRIEND_LIST_UPDATION_STATUS: {
      return {
        ...state,
        isFriendsUpdated: false,
      };
    }

    case SET_USERNAME: {
      return {
        ...state,
        username: action.data,
      };
    }

    case SET_CALL_END_ANIMATION_TYPE: {
      return {
        ...state,
        callAnimationType: action.data,
      };
    }

    case SET_CALL_END_MODE: {
      return {
        ...state,
        endCallMode: action.data,
      };
    }
    case SET_GUEST_JOINED_MODE: {
      return {
        ...state,
        userId: action.data.userId,
        isGuestJoined: action.data.status,
        username: action.data.username,
      };
    }

    case SET_ANONYMOUS_LOGIN: {
      return {
        ...state,
        isAnonymous: action.data.isAnonymous,
      };
    }

    case 'SET_LOG_OUT': {
      sessionStorage.setItem('currentCallId', '');
      sessionStorage.setItem('call_status', '');
      return {
        email: '',
        notifications: [],
        role: '',
        isLoggedIn: false,
        isLoading: false,
        username: '',
        profilePhotoUrl: '',
        authId: '',
        userId: '',
        friends: {},
        isFriendsUpdated: false,
        chat: {},
        currentCallId: '',
        call_status: '',
        call_notifications: {},
        callAnimationType: '',
        isScreenSharingOn: false,
        endCallMode: '',
        isGuestMode: true,
        isGuestJoined: false,
        askToJoinCallNotifications: {},
        isAnonymous: false,
        selectedTheme: THEME_SETTINGS.LIGHT,
        callTimer: null,
        resumeTimerValue: null,
        isTimerStarted: false,
        loginSessionStartAt: null,
        isPremium: false,
      };
    }
    case OPEN_FEEDBACK_MODAL: {
      return {
        ...state,
        isOpenFeedBackModal: action.data,
      };
    }
    case SET_RADIO_BUTTON_VALUE: {
      return {
        ...state,
        selectedTheme: action.data,
      };
    }
    case RECENT_GIFS_LIST: {
      return {
        ...state,
        recentGifs: action.payload,
      };
    }
    case SET_TIMER_DATA: {
      return {
        ...state,
        callTimer: action.payload,
      };
    }
    case SET_RESUME_TIMER_VALUE: {
      return {
        ...state,
        resumeTimerValue: action.payload,
      };
    }
    case IS_TIMER_STARTED: {
      return {
        ...state,
        isTimerStarted: action.payload,
      };
    }
    case UPDATE_PREMIUM_STATUS: {
      return {
        ...state,
        isPremium: true,
      };
    }
    case UPDATE_CURRENT_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
