import React from "react";

const GifItemComponent = ({ keyword, animationUrl, onSelect }) => {
  const onClick = () => {
    onSelect(keyword, animationUrl);
  };

  return (
    <div className="smileItem" onClick={onClick}>
      <img src={animationUrl} alt="" />
    </div>
  );
};

export default GifItemComponent;
