import { SET_USER_CHAT_DETAIL, RESET_USER_CHAT } from "./action";

const defaultState = {
  chat: {},
};

const userChatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_CHAT_DETAIL: {
      return {
        ...state,
        chat: action.payload,
      };
    }

    case RESET_USER_CHAT: {
      return {
        chat: {},
      };
    }

    default:
      return state;
  }
};

export default userChatReducer;
