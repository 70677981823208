import React from "react";
import cx from "classnames";

const ScreenShareComponent = ({ count, isPresenter }) => {
  return (
    <span className="screenShareViewWrapper" id="parent-container-screenshare">
      <video
        id="screenShareView"
        autoPlay
        muted
        playsInline
        className={cx({
          "d-none": isPresenter,
        })}
      ></video>
      {/* <div id={"videoImage" + count} className="screenShareViewCapture" /> */}
      <p
        className={cx("shareText", {
          "d-none": !isPresenter,
        })}
      >
        You are sharing your screen.
      </p>
    </span>
  );
};

export default ScreenShareComponent;
