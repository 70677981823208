import React from "react";
import classes from "./styles.module.scss";
import cx from "classnames";

export default ({
  imagePath,
  alt = "",
  sm = false,
  className = "",
  onClick,
  onChange,
  isFileUpload,
  isDisabled,
}) => (
  <div
    className={cx("imageBox", className, classes.imageBox, {
      [classes.sm]: sm,
    })}
    onClick={onClick}
  >
    <img src={imagePath} alt={alt} />
    {isFileUpload && (
      <label className={classes.fileUpload}>
        <input
          disabled={isDisabled}
          className="d-none"
          type="file"
          id="image"
          onChange={onChange}
          accept=".jpg, .jpeg, .png"
        />
      </label>
    )}
  </div>
);
