import React, { Component } from "react";
import { Button } from "reactstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import closeIcon from "../../../assets/images/icons/close.svg";
import * as loginAction from "../../../redux/login/action";
import { THEME_SETTINGS } from "../../shared/constants";
import {
  Modal,
  ModalBody,
} from "reactstrap";

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeType: this.props.radioButtonValue
    };
    this.onApply = this.onApply.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onChangeValue(type) {
    this.setState({
      themeType: type
    });
  }

  onApply() {
    this.props.changeActiveRadio(this.state.themeType);
    this.props.onClose();
  }
  onCloseModal() {
    this.props.onClose();
    this.setState({
      themeType : this.props.radioButtonValue
    });
  }

  render() {
    const {
      themeType
    } = this.state;
    return (
      <Modal
        isOpen={this.props.isOpen}
        centered
        className="isOpenContactUsModal"
      >
        <ModalBody>
          <button onClick={this.onCloseModal} className="modal-close">
            <img src={closeIcon} alt="close" />
          </button>
          <div className="settingForm">
            <div className="content">
              <h3>Choose theme </h3>
              <label>
                <input type="radio"
                  checked={themeType === THEME_SETTINGS.DARK}

                  onChange={() => this.onChangeValue(THEME_SETTINGS.DARK)} /> Dark theme
                </label>
              <label className="padding-left-15">
                <input type="radio"
                  checked={themeType === THEME_SETTINGS.LIGHT}
                  onChange={() => this.onChangeValue(THEME_SETTINGS.LIGHT)}
                /> Light theme
                </label>
            </div>
            <Button color="primary" onClick={this.onApply}>
              Apply
        </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveRadio: (data) => dispatch(loginAction.changeActiveRadioButton(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    radioButtonValue: state.loginReducer.selectedTheme,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SettingsModal
);
