/* eslint-disable */

// Constant
export const SET_NEW_EVENT = "SET_NEW_EVENT";
export const SET_EVENTS = "SET_EVENTS";
export const RESET_ADMIN_EVENT = "RESET_ADMIN_EVENT";
export const DELETE_ADMIN_EVENT = "DELETE_ADMIN_EVENT";

/**
 *
 * @param {Object} eventPayload
 * {
 *  @param {string} name
 *  @param {string} photoUrl
 *  @param {Array} venueList : Array of objects
 *  {
 *    @param {string} photoUrl
 *    @param {string} locationName
 *    @param {string} locationWebsite
 *  },
 * }
 *
 */
export const setNewEvent = (eventPayload) => ({
  type: SET_NEW_EVENT,
  payload: eventPayload,
});

/**
 * @param {Array} eventPayload : Array of event objects
 */
export const setEvents = (eventPayload) => ({
  type: SET_EVENTS,
  payload: eventPayload,
});

/**
 * To reset event reducer state
 */
export const resetEvents = () => ({
  type: RESET_ADMIN_EVENT,
});

/**
 * To delete some event
 *  @param {string} key
 */
export const deleteEvent = (key) => ({
  type: DELETE_ADMIN_EVENT,
  payload: key,
});
