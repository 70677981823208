import moment from "moment";

import Alert from "./common/alert";
import {
  VIDEO_CALL_BROWSER_SUPPORT,
  SCREEN_SHARE_BROWSER_SUPPORT, FILE_STORAGE_PATH
} from "../shared/constants";

/**
 * @method handleImageFileUpload : To get uploaded image's url.
 *
 * @param {object} event: File input event.
 */
export const handleImageFileUpload = (event, data) => {
  const imageFile = event.target.files[0];
  if (!imageFile) {
    return {
      url: "",
      file: "",
    };
  }

  return {
    url: URL.createObjectURL(imageFile),
    file: imageFile,
  };
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * @method handleUploadToFireBaseStorage : To handle upload image to firebase storage
 *
 * @param {object} imageFile : image file
 * @param {string} folderName : name of image folder
 * @param {object} storage : firebase storage object
 * @param {function} onError : callback to handle error
 * @param {boolean} isEditMode : file is being undated
 * @param {string} fileName : name of file
 */
export const handleUploadToFireBaseStorage = async ({
  imageFile,
  folderName,
  storage,
  onError,
  isEditMode,
  fileName,
}) => {
  return new Promise(async (resolve) => {
    if (!imageFile) {
      if (isEditMode) {
        resolve(null);
        return;
      }
      Alert(400, "Please provide valid image file.");
      resolve(null);
      return;
    }

    //initiates the firebase side uploading
    const uploadFile = storage
      .ref((folderName || "/images") + "/" + (fileName || imageFile.name))
      .put(imageFile);

    await uploadFile.on(
      "state_changed",
      null,
      (err) => {
        if (err) {
          onError(err);
          resolve(null);
          Alert(400, "Error in image upload!!");
        }
      },
      () => {
        // To get the download url then sets the image from firebase as the value for the imgage url :

        storage
          .ref(folderName || "/images")
          .child(fileName || imageFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            resolve(fireBaseUrl);
          })
          .catch(() => {
            resolve(null);
          });
      }
    );
  });
};

export const handleUploadWebmFileToFireBaseStorage = async ({
  file,
  fileName,
  storage,
  onError
}) => {
  console.log("arguments===", file, fileName, storage, onError)
  return new Promise(async (resolve) => {
    //initiates the firebase side uploading
    const uploadFile = storage

      .ref(FILE_STORAGE_PATH.video_file + "/" + fileName)
      .put(file);

    await uploadFile.on(
      "state_changed",
      null,
      (err) => {
        if (err) {
          onError(err);
          resolve(null);
          Alert(400, "Error in image upload!!");
        }
      },
      () => {
        // To get the download url then sets the image from firebase as the value for the imgage url :

        storage
          .ref(FILE_STORAGE_PATH.video_file)
          .child(fileName)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            resolve(fireBaseUrl);
          })
          .catch(() => {
            resolve(null);
          });
      }
    );
  });
};

/**
 * @method isValidURL : Returns true if url is a valid url
 *
 * @param {string} url
 */
export const isValidURL = (url) => {
  const regexp = /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  return !url || regexp.test(url);
};

/**
 * @method cloneArrayOfObject : To clone simple array of objects
 *
 * @param {Array} data
 */
export const cloneArrayOfObject = (data) => {
  return (
    (Array.isArray(data) && data.map((val) => Object.assign({}, val))) || []
  );
};

/**
 * @method formatDateTime: Return formatted date
 * @param {Date} date
 * @param {string} format
 */
export const formatDateTime = (date, format = "LLLL") => {
  return date && new Date(date) !== "Invalid Date"
    ? moment(date).format(format).toString()
    : date;
};

/**
 * @method getTimeAfterAddHour: To get date/time after given hours
 * @param {Date} date
 * @param {number} hours
 */
export const getTimeAfterAddHour = (date, hours) => {
  return date && new Date(date) !== "Invalid Date"
    ? moment(date).add(hours, "h")
    : date;
};

/**
 * To get snake case of given string.
 *
 * @param {string} str
 */
export const toSnakeCase = (str) => {
  if (str !== undefined && str !== null) {
    return str.replace(/ |\/|-/g, "_");
  }
  return str;
};

/**
 * Async For Each loop
 *
 * @param {Array} array
 * @param {Function} callback
 */
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function checkIsValidURL(str) {
  var pattern = new RegExp(
    /^((http?:\/\/)?www\.|(https:\/\/)?www\.|http?:\/\/|https?:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
  );
  return !!pattern.test(str);
}

export function isSafariBrowser() {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return /Safari/i.test(uA) && /Apple Computer/.test(vendor);
}

export function isChromeBrowser() {
  const browserDetail = getBrowserDetail();
  return !!browserDetail.name && browserDetail.name.toLowerCase() === "chrome";
}

export function isVideoCallSupported() {
  const { name, version } = getBrowserDetail();
  const supportedVersion = VIDEO_CALL_BROWSER_SUPPORT[name.toUpperCase()];
  return supportedVersion ? !(version < supportedVersion) : true;
}

export function isScreenShareSupported() {
  const { name, version } = getBrowserDetail();
  const supportedVersion = SCREEN_SHARE_BROWSER_SUPPORT[name.toUpperCase()];
  return supportedVersion ? !(version < supportedVersion) : true;
}

function getBrowserDetail() {
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // In Chrome
  if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // To trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return {
    name: browserName,
    fullVersion: fullVersion,
    version: majorVersion,
  };
}

export const getNumberOfColumn = (videoFrameLength) => {
  const isEvenNoOfFrames = !(videoFrameLength % 2);
  const maxNumberOfRows = 2;

  if (isEvenNoOfFrames) {
    return videoFrameLength === maxNumberOfRows
      ? videoFrameLength
      : videoFrameLength / maxNumberOfRows;
  } else {
    return Math.ceil(videoFrameLength / 2);
  }
};

export const getVideoFramePosition = (parentEl, videoEl, top, flipH) => {
  const pElW = parentEl.clientWidth;

  const pElH = parentEl.clientHeight;
  const pElOffsetLeft = parentEl.offsetLeft;
  const pElOffsetTop = parentEl.offsetTop;
  const vElW = videoEl.videoWidth;
  const vElH = videoEl.videoHeight;

  let finalWidth = pElW;
  let finalHeight = (vElH / vElW) * finalWidth;
  if (finalHeight > pElH) {
    finalHeight = pElH;
    finalWidth = (vElW / vElH) * finalHeight;
  }

  const extraWidth = pElW - finalWidth;
  let finalX = extraWidth / 2 + pElOffsetLeft;
  const extraHeight = pElH - finalHeight;
  let finalY = extraHeight / 2 + (pElOffsetTop - top);

  return {
    extraWidth: pElW - extraWidth / 2,
    extraHeight: pElH - extraHeight / 2,
    offsetX: finalX,
    x: flipH ? finalWidth * -1 - extraWidth / 2 : finalX,
    y: finalY,
    w: finalWidth,
    h: finalHeight,
  };
};
