import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { EventDetailModal } from "../../components/modal";
import { ROUTES, SCHEMA } from "../../components/shared/constants";
import { withFirebase } from "../../firebase/context";
import { setIsLoading } from "../../redux/loader/action";
import { setUserEvents } from "../../redux/user-events/action";
import { cloneArrayOfObject } from "../../components/shared/utils";

const localizer = momentLocalizer(moment);

class CalendarPickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      eventList: [],
      calendarEventList: [],
      selectedEvent: {},
    };
  }

  setInitialState() {
    const {
      firebase,
      userInfo: { userId },
    } = this.props;

    this.props.setIsLoading(true);

    this.setUserEventRef = firebase.getUserEvents(userId, async (res) => {
      const tempEventList = [];
      const tempCalEventList = [];

      if (res) {
        this.props.setUserEvents(res);
        const userEventList = res;
        const eventListKeys = userEventList ? Object.keys(userEventList) : [];

        for (const userEventKey of eventListKeys) {
          const userEvent = userEventList[userEventKey];
          const invited = Object.assign({}, userEvent.invited);
          delete invited[userId];

          const data = {
            id: userEventKey,
            creatorId: userEvent.creatorId,
            dateTime: userEvent.dateTime,
            eventId: userEvent.eventId,
            isGoing: "",
            venueList: userEvent.venueList,
            invitedFriends: Object.keys(invited),
          };

          const calData = {
            id: userEventKey,
            start: new Date(userEvent.dateTime),
            end: new Date(userEvent.dateTime),
            title: "dummy",
            allDay: true,
          };

          this.getDataRef = firebase.getData(
            "/" + SCHEMA.EVENTS + "/" + userEvent.eventId,
            () => {}
          );

          await this.getDataRef.then((resData) => {
            if (resData && resData.val()) {
              const event = resData.val();

              data.eventName = event.name;
              calData.title = event.name;

              tempEventList.push(data);
              tempCalEventList.push(calData);

              if (tempEventList.length === 1) {
                this.props.setIsLoading(false);
              }

              this.setState({
                eventList: tempEventList,
                calendarEventList: tempCalEventList,
              });
            }
          });
        }

        if (this.props.isLoading) {
          this.props.setIsLoading(false);
        }

        return;
      } else {
        if (this.props.isLoading) {
          this.props.setIsLoading(false);
        }
      }
    });
  }

  refreshEventState() {
    const {
      firebase,
      userInfo: { userId },
    } = this.props;

    this.userEventRef = firebase.getUserNewEvent(userId, async (key, res) => {
      const tempEventList = cloneArrayOfObject(this.state.eventList);
      const tempCalEventList = cloneArrayOfObject(this.state.calendarEventList);

      const indexOfSelected = tempEventList.findIndex(
        (event) => event.id === key
      );

      if (indexOfSelected === -1) {
        const invited = Object.assign({}, res.invited);
        delete invited[userId];
        const data = {
          id: key,
          creatorId: res.creatorId,
          dateTime: res.dateTime,
          eventId: res.eventId,
          isGoing: "",
          venueList: res.venueList,
          invitedFriends: Object.keys(invited),
        };

        const calData = {
          id: key,
          start: new Date(res.dateTime),
          end: new Date(res.dateTime),
          title: "",
          allDay: true,
        };

        this.getEventDataRef = firebase.getData(
          "/" + SCHEMA.EVENTS + "/" + res.eventId,
          async (event) => {
            if (!event) {
              return;
            }
          }
        );

        await this.getEventDataRef.then((resData) => {
          if (resData && resData.val()) {
            const event = resData.val();

            data.eventName = event.name;
            calData.title = event.name;

            tempEventList.push(data);
            tempCalEventList.push(calData);
            this.setState({
              eventList: tempEventList,
              calendarEventList: tempCalEventList,
            });
          }
        });
      }
    });
  }

  async componentDidMount() {
    await this.setInitialState();
    this.refreshEventState();
  }

  componentWillUnmount() {
    if (this.userEventRef) {
      if (this.userEventRef.ref) {
        this.userEventRef.ref.off("value", this.userEventRef.callback);
        this.userEventRef.callback = null;
        this.userEventRef.ref = null;
      }
    }

    if (this.getDataRef) {
      this.getDataRef = null;
    }

    if (this.getEventDataRef) {
      this.getEventDataRef = null;
    }

    if (this.setUserEventRef) {
      this.setUserEventRef.callback = null;
      this.setUserEventRef.ref = null;
    }
  }

  eventStyleGetter = (event) => {
    let className = "";
    let style = {};

    return {
      style: style,
      className: className,
    };
  };

  handleSelectEvent = (selectedEvent) => {
    const { eventList } = this.state;
    const value = eventList.find((event) => event.id === selectedEvent.id);

    if (value) {
      this.setState({
        selectedEvent: value,
      });
      this.toggle();
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { calendarEventList } = this.state;

    return (
      <>
        <div className="eventCalanderScreen">
          <div className="wrapper">
            <Link to={ROUTES.user.events} className="backBtn">
              {"<"} Back to Events
            </Link>
            <Calendar
              selectable
              localizer={localizer}
              events={calendarEventList}
              startAccessor="start"
              endAccessor="end"
              defaultDate={new Date()}
              className="eventCalcander"
              eventPropGetter={this.eventStyleGetter}
              onSelectEvent={(event) => this.handleSelectEvent(event)}
            />
          </div>
        </div>
        {this.state.isOpen && (
          <EventDetailModal
            selectedEvent={this.state.selectedEvent}
            toggle={this.toggle}
            isOpen
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userEventList: state.userEventReducer.events,
    userInfo: state.loginReducer,
    isLoading: state.loaderDetails.isLoading,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  setUserEvents,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(CalendarPickerComponent);
