import React, { Component, createRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import cx from 'classnames';
import { AddCallIcon, TreeIcon } from '../../components/svgIcons';
import Chat from "./chat";
import { ConnectWithFriendModal } from "../modal";
import Alert from "../../components/shared/common/alert";
import Video from "../../pages/call/video";
import callTone from "../../assets/audio/incoming-call.mp3";
import { MAX_USER_SUPPORTED_FOR_CALL, } from "../shared/constants";
import { THEME_SETTINGS } from "../shared/constants";

class CallLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addFriend: false,
      messages: [],
      isSending: false,
      invited: {},
      isDisabled: true,
      joinedFriends: {},
      eventJoinedFriends: {},
      eventSession: false,
      videoCallId: "",
      allInvitations: {},
      path: "",
      chatImageUrl: "",
      chatImageFile: null,
    };

    this.videoCallViewRef = createRef();

    this.handleUploadChatImage = this.handleUploadChatImage.bind(this);
    this.onClearAttachment = this.onClearAttachment.bind(this);
    this.onStopScreenShare = this.onStopScreenShare.bind(this);
    this.onCallEnd = this.onCallEnd.bind(this);
  }


  toggle = () => {
    if (this.props.userInfo.isGuestMode) {
      Alert(400, "Not available for guest mode.");
    } else {
      this.setState({
        addFriend: !this.state.addFriend,
      });
    }
  };

  async componentDidMount() {
    this.fetchCallMembers();
  }



  fetchCallMembers() {
    const {
      firebase,
      userInfo: { isGuestMode, isGuestJoined, isAnonymous },
    } = this.props;

    const sessionCallId = sessionStorage.getItem("currentCallId");

    if (
      (isGuestMode && !isGuestJoined) ||
      (isGuestMode && !isAnonymous) ||
      (!sessionCallId && isGuestMode && isGuestJoined) ||
      !sessionCallId
    ) {
      return;
    }

    const { pathname } = window.location;
    const path = pathname.split("/");
    const callId = path[2];

    this.getCallUserRef = firebase.getCurrentCallUsers(callId, (res) => {
      if (!!res) {
        const joinedFriends = {};
        const resKeys = Object.keys(res);

        const pendingList = Object.values(res).filter(
          (val) => val.status === "PENDING"
        );

        const callAudioEl = document.getElementById("callAudio");

        if (callAudioEl) {
          if (pendingList.length) {
            callAudioEl
              .play()
              .then(() => { })
              .catch(() => { });
          } else {
            this.setState({
              playAudio: false,
            });
            callAudioEl.pause();
            callAudioEl.currentTime = 0;
          }
        }

        resKeys.forEach((key) => {
          const val = res[key];
          if (val.status === "ACCEPTED") {
            joinedFriends[key] = val;
          } else if (val.status === "PENDING" && val.notificationKey) {
            this.autoRejectCall(val, callId);
          }
        });

        if (
          Object.keys(this.state.joinedFriends).length <
          Object.keys(joinedFriends).length
        ) {
          this.setState({
            addFriend: false,
          });
        } else if (
          Object.keys(this.state.allInvitations).length >
          Object.keys(res).length
        ) {
          this.setState({
            addFriend: false,
          });
        }

        this.setState({
          joinedFriends,
          videoCallId: callId,
          allInvitations: res,
          path: pathname,
        });
      }
    });
  }

  componentWillUnmount() {
    const {
      userInfo: { userId },
    } = this.props;
    const { joinedFriends } = this.state;

    let invitedKeys = [];
    if (joinedFriends) {
      invitedKeys = Object.keys(joinedFriends).filter((i) => i !== userId);
    }
    if (
      invitedKeys.length === MAX_USER_SUPPORTED_FOR_CALL &&
      this.getCallUserRef
    ) {
      this.getCallUserRef.ref.off("value", this.getCallUserRef.callback);
      this.getCallUserRef.callback = null;
      this.getCallUserRef.ref = null;
    }
  }

  isRouteUpdated(prevProps) {
    let oldPath = this.state.path;
    let newPath = "";
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname
    ) {
      newPath = this.props.history.location.pathname;
    }
    if (oldPath !== newPath && newPath.includes("call")) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.isRouteUpdated(prevProps)) {
      this.fetchCallMembers();
    }
  }

  async onCallEnd({ notificationKey, userToInviteId, callId }) {
    await this.props.firebase.checkCallNotificationStatus(
      notificationKey,
      userToInviteId,
      async (res) => {
        if (res && res.status === "PENDING") {
          await this.updateNotificationsData(
            "REJECTED",
            notificationKey,
            userToInviteId
          );
          await this.props.firebase.removeUserFromInvitedCall(
            callId,
            userToInviteId
          );
        }
      }
    );
  }

  autoRejectCall(callData, callId) {
    const { notificationKey, timestamp, userId: userToInviteId } = callData;
    const createAt = new Date(timestamp);
    const expiredAt = new Date(createAt.setMinutes(createAt.getMinutes() + 1));

    const currentTime = new Date();
    let waitTime = expiredAt.getTime() - currentTime.getTime();
    if (waitTime <= 0) {
      waitTime = 0;
    }

    setTimeout(async () => {
      this.onCallEnd({ notificationKey, userToInviteId, callId });
    }, waitTime);
  }

  updateNotificationsData = async (status, notificationKey, userToInviteId) => {
    let obj = {
      status,
    };

    await this.props.firebase
      .updateUserCallNotificationData(
        "users",
        userToInviteId,
        notificationKey,
        obj
      )
      .then(() => { })
      .catch(() => { });
  };

  onAddVirtualBackground = () => {
    Alert(400, "Virtual Backgrounds will be available soon.");
  };

  onDisconnect = () => {
    console.log("going to disconnect......");
  };

  handleUploadChatImage(data) {
    if (data) {
      this.setState({
        chatImageFile: data.file,
        chatImageUrl: data.url,
      });
    }
  }

  onClearAttachment() {
    this.setState({
      chatImageFile: null,
      chatImageUrl: "",
    });
  }

  onStopScreenShare() {
    const { current } = this.videoCallViewRef;
    if (current && current.onStopScreenShare) {
      current.onStopScreenShare();
    } else {
      Alert(400, "Unable to stop screen sharing. Please try again.");
    }
  }

  render() {
    const {
      addFriend,
      joinedFriends,
      eventSession,
      allInvitations,
      chatImageUrl,
      chatImageFile,
    } = this.state;
    const {
     userInfo:{ selectedTheme } 
    } = this.props;

    const {
      userInfo: { isGuestMode },
    } = this.props;

    return (
      <div className={cx({ 'light-theme': selectedTheme === THEME_SETTINGS.LIGHT })}>
        <div className="call-layout  bg-black">
          <audio id="callAudio" src={callTone} loop></audio>
          <div className="video-screen">
            <Video
              ref={this.videoCallViewRef}
              joinedFriends={joinedFriends}
              onDisconnect={this.onDisconnect}
              onAttach={this.handleUploadChatImage}
              {...this.props}
            />
          </div>
          <div className="chat-screen">
            <div className="top-btn">
              {
                <Dropdown isOpen={addFriend} toggle={() => this.toggle()}>
                  <DropdownToggle
                    title={`Click to ${addFriend ? 'close' : 'open'} friendlist.`}
                    className="btn-icon-important noBoxShadow tBtn"
                    style={{ padding: "0 6px" }}
                  >
                    <AddCallIcon />
                  </DropdownToggle>
                  <DropdownMenu className="fixedModal connect-friendModal">
                    {!isGuestMode && (
                      <ConnectWithFriendModal
                        toggle={this.toggle}
                        allInvitations={allInvitations}
                        onCallEnd={this.onCallEnd}
                      />
                    )}
                  </DropdownMenu>
                </Dropdown>
              }
              <button
                title={"Click to Apply virtual backgrounds."}
                onClick={() => this.onAddVirtualBackground()}
                className="btn-icon tBtn"
              >
                <TreeIcon />
              </button>
            </div>
            <Chat
              joinedFriends={joinedFriends}
              eventSession={eventSession}
              isImageAttachment={!!chatImageFile}
              attachment={{ url: chatImageUrl, file: chatImageFile }}
              onClearAttachment={this.onClearAttachment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CallLayout;
