import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import ScreenSharing from "../../assets/images/icons/screenSharing.png";

const ScreenSharePopup = (props) => {
  const {
    userInfo: { isScreenSharingOn },
    isCallView,
  } = props;

  if (!isScreenSharingOn) {
    return null;
  }

  return (
    <div className={cx("screenShareBar", { "video-screen": isCallView })}>
      <div className="shareContent">
        <h5 className="mb-0">Bonfire is sharing your screen</h5>
        <button
          onClick={props.onStop}
          className="shareBtn"
          title={"Click to stop sharing your screen."}
        >
          <img src={ScreenSharing} alt="share" />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
});

export default connect(mapStateToProps)(ScreenSharePopup);
