/* eslint-disable */

// Constant
export const SET_SPECIAL_SYMBOLS = "SET_SPECIAL_SYMBOLS";
export const SET_NEW_SPECIAL_SYMBOLS = "SET_NEW_SPECIAL_SYMBOLS";
export const RESET_SPECIAL_SYMBOLS = "RESET_SPECIAL_SYMBOLS";
export const DELETE_SPECIAL_SYMBOL = "DELETE_SPECIAL_SYMBOL";

/**
 *
 * @param {Object} Payload: object containing list of chat screen animation symbols
 * {
 *  @param {string} keyword
 *  @param {string} animationUrl
 * }
 *
 */
export const setSpecialSymbols = (payload) => ({
  type: SET_SPECIAL_SYMBOLS,
  payload,
});

/**
 *
 * @param {Object} Payload: object containing new chat screen animation symbol
 *  {
 *  @param {string} key
 *  @param {object} symbol
 *  {
 *    @param {string} keyword
 *    @param {string} animationUrl
 *  }
 * }
 */
export const setNewSpecialSymbol = (payload) => ({
  type: SET_NEW_SPECIAL_SYMBOLS,
  payload,
});

/**
 * To reset reducer state
 */
export const resetSpecialSymbols = () => ({
  type: RESET_SPECIAL_SYMBOLS,
});

/**
 * To delete some symbol
 *  @param {string} key
 */
export const deleteSpecialSymbol = (key) => ({
  type: DELETE_SPECIAL_SYMBOL,
  payload: key,
});
