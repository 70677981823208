import { UPDATE_SCREEN_ANIMATION } from "./action";

const defaultState = {
  animations: {},
};

const screenAnimationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SCREEN_ANIMATION: {
      return {
        ...state,
        animations: action.payload,
      };
    }

    default:
      return state;
  }
};

export default screenAnimationReducer;
