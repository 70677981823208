import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Input,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { withFirebase } from "../../../../firebase/context";
import closeIcon from "../../../../assets/images/icons/close.svg";
import {
  handleImageFileUpload,
  handleUploadToFireBaseStorage,
  toSnakeCase,
} from "../../../../components/shared/utils";
import {
  FILE_STORAGE_PATH,
  SCHEMA,
} from "../../../../components/shared/constants";
import Alert from "../../../../components/shared/common/alert";
import { setIsLoading } from "../../../../redux/loader/action";
import {
  setNewSpecialSymbol,
  deleteSpecialSymbol,
} from "../../../../redux/chat-special-symbols/action";
import { onUpdateCallEndAnimation } from "../../../../redux/call-end-animations/action";
import { CallEndAnimation } from "./callEnd";
import AddNewAnimation from "./addNew";

const ANIMATION_TYPE = {
  WELCOME: "welcome",
  EVENTS: "events",
  CHAT: "chat",
  CALL_END_ANIMATION_ONE: "animationOne",
  CALL_END_ANIMATION_TWO: "animationTwo",
  CALL_END: "call_end",
  FEEDBACK: "feedback",
};

class AnimationsComponent extends Component {
  constructor(props) {
    super(props);

    let welcomeAnimationUrl = "";
    let eventsAnimationUrl = "";
    let feedbackAnimationUrl = "";

    let tempCallEndAnimations = null;
    if (props.screenAnimations) {
      const { welcome, events, feedback } = props.screenAnimations;
      welcomeAnimationUrl = (welcome && welcome.animationUrl) || "";
      eventsAnimationUrl = (events && events.animationUrl) || "";
      feedbackAnimationUrl = (feedback && feedback.animationUrl) || "";
    }

    if (props.callEndAnimations) {
      const { animationTwo, animationOne } = props.callEndAnimations;
      tempCallEndAnimations = {
        animationOne: Object.assign({}, animationOne || {}),
        animationTwo: Object.assign({}, animationTwo || {}),
      };
    }

    this.state = {
      isOpenAddVideoAnimation: false,
      isEditWelcomeScreenAnimation: false,
      welcomeScreenAnimationUrl: welcomeAnimationUrl,
      welcomeScreenAnimationFile: null,
      isEditEventsScreenAnimation: false,
      eventsScreenAnimationUrl: eventsAnimationUrl,
      eventsScreenAnimationFile: null,
      isEditFeedbackScreenAnimation: false,
      feedbackScreenAnimationUrl: feedbackAnimationUrl,
      feedbackScreenAnimationFile: null,
      chatScreenAnimationUrl: "",
      chatScreenAnimationFile: null,
      newChatKeyword: "",
      newChatAnimationDuration: "",
      updateChatSymbolAtKey: "",
      isOpenDeleteModal: false,
      selectedSymbol: null,
      selectedSymbolId: "",
      callEndAnimations: tempCallEndAnimations,
      isUpdateCallEndAnimation: false,
      isEditCallEndKey: "",
    };

    this.onCloseAddVideoAnimation = this.onCloseAddVideoAnimation.bind(this);
    this.onUpdateAnimation = this.onUpdateAnimation.bind(this);
    this.toggleAddVideoAnimationView = this.toggleAddVideoAnimationView.bind(
      this
    );
    this.onChangeChatKeyword = this.onChangeChatKeyword.bind(this);
    this.onSaveNewChatSymbol = this.onSaveNewChatSymbol.bind(this);
    this.onEditChatSymbol = this.onEditChatSymbol.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.onCancelRemoveChatSymbol = this.onCancelRemoveChatSymbol.bind(this);
    this.onRemoveChatSymbol = this.onRemoveChatSymbol.bind(this);
    this.handleOnRemoveChatSymbol = this.handleOnRemoveChatSymbol.bind(this);
    this.onEditCallEndAnimation = this.onEditCallEndAnimation.bind(this);
    this.handleChangeAnimationText = this.handleChangeAnimationText.bind(this);
    this.onUpdateAnimation = this.onUpdateAnimation.bind(this);
    this.uploadAnimationFile = this.uploadAnimationFile.bind(this);
    this.handleCancelUpdateCallEndAnimation = this.handleCancelUpdateCallEndAnimation.bind(
      this
    );
    this.onChangeChatAnimationDuration = this.onChangeChatAnimationDuration.bind(
      this
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.callEndAnimations ||
      prevState.isUpdateCallEndAnimation !== nextProps.isUpdateCallEndAnimation
    ) {
      return {
        callEndAnimations: nextProps.callEndAnimations
          ? Object.assign({}, nextProps.callEndAnimations)
          : nextProps.callEndAnimations,
      };
    }
  }

  onCancelUpdateScreenAnimation(type) {
    const animationUrlKey = type + "ScreenAnimationUrl";
    const animationFileKey = type + "ScreenAnimationFile";
    const { welcome, events, feedback } = this.props.screenAnimations;
    this.setState({
      [animationFileKey]: null,
      [animationUrlKey]:
        type === ANIMATION_TYPE.WELCOME
          ? (welcome && welcome.animationUrl) || ""
          : type === ANIMATION_TYPE.EVENTS
          ? (events && events.animationUrl) | ""
          : (feedback && feedback.animationUrl) | "",
    });
  }

  toggleAddVideoAnimationView() {
    this.setState({
      isOpenAddVideoAnimation: !this.state.isOpenAddVideoAnimation,
      chatScreenAnimationUrl: "",
      chatScreenAnimationFile: null,
      newChatKeyword: "",
      updateChatSymbolAtKey: "",
      newChatAnimationDuration: "",
    });
  }

  onCloseAddVideoAnimation() {
    this.setState({
      isOpenAddVideoAnimation: false,
      chatScreenAnimationUrl: "",
      chatScreenAnimationFile: null,
      newChatKeyword: "",
      updateChatSymbolAtKey: "",
      newChatAnimationDuration: "",
    });
  }

  toggleDeleteModal() {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
    });
  }

  /**
   * To upload image animation file
   * @param {object} event
   * @param {string} type
   */
  uploadAnimationFile(event, type, key) {
    if (type === ANIMATION_TYPE.CALL_END) {
      const { url, file } = handleImageFileUpload(event);
      const { callEndAnimations } = this.state;
      const tempVal = Object.assign({}, callEndAnimations[key]);
      tempVal.animationUrl = url;
      tempVal.animationFile = file;
      callEndAnimations[key] = tempVal;

      this.setState({
        callEndAnimations,
      });
    } else {
      const { url, file } = handleImageFileUpload(event);
      const animationUrlKey = type + "ScreenAnimationUrl";
      const animationFileKey = type + "ScreenAnimationFile";
      this.setState({
        [animationUrlKey]: url,
        [animationFileKey]: file,
      });
    }
  }

  /**
   * To upload welcome , events and feedback screen animation and call end animations
   * @param {string} type
   * @param {string} key
   */
  onUpdateAnimation(type, key) {
    const props = this.props;

    if (type === ANIMATION_TYPE.CALL_END) {
      const { callEndAnimations } = this.state;
      const animationDetail = callEndAnimations[key];
      if (!animationDetail.title || !animationDetail.description) {
        Alert(400, "Please fill all fields.");
        return null;
      }

      const callEndAnimationsDetail = this.props.callEndAnimations;
      const tempVal = Object.assign({}, callEndAnimationsDetail[key]);
      const isEditMode = !!tempVal.animationUrl;

      props.setIsLoading(true);
      handleUploadToFireBaseStorage({
        imageFile: animationDetail.animationFile,
        folderName: FILE_STORAGE_PATH["call_end_" + key],
        storage: props.firebase.storage,
        onError: () => {
          this.onCancelUpdateScreenAnimation(type);
          Alert(400, "Sorry, unable to add this animation at this time.");
          props.setIsLoading(false);
        },
        fileName: "animated_image",
        isEditMode,
      }).then(async (url) => {
        const tempUrl = isEditMode ? url || tempVal.animationUrl : url || "";

        const payload = {
          title: animationDetail.title,
          description: animationDetail.description,
          animationUrl: tempUrl,
        };

        props.firebase
          .updateData(SCHEMA.CALL_DISCONNECT_ANIMATIONS, key, payload)
          .then(() => {
            callEndAnimations[key].animationFile = null;
            this.setState({
              callEndAnimations,
              isEditCallEndKey: "",
            });

            // this.props.onUpdateCallEndAnimation(true);

            // setTimeout(() => {
            //   this.props.onUpdateCallEndAnimation(false);
            // }, 3000);

            Alert(200, "Animation updated sucessfully.");
            props.setIsLoading(false);
          })
          .catch(() => {
            props.setIsLoading(false);
          });
      });
    } else {
      const animationFile = this.state[type + "ScreenAnimationFile"];

      props.setIsLoading(true);
      handleUploadToFireBaseStorage({
        imageFile: animationFile,
        folderName: FILE_STORAGE_PATH[type + "_screen_animation"],
        storage: props.firebase.storage,
        onError: () => {
          this.onCancelUpdateScreenAnimation(type);
          Alert(400, "Sorry, unable to add this animation at this time.");
          props.setIsLoading(false);
        },
        fileName: "animated_image",
      }).then(async (url) => {
        if (url) {
          props.firebase
            .updateData(SCHEMA.SCREEN_ANIMATIONS, type, { animationUrl: url })
            .then(() => {
              this.setState({
                [type + "ScreenAnimationFile"]: null,
              });
              Alert(200, "Animation updated sucessfully.");
              props.setIsLoading(false);
            });
        } else {
          Alert(200, "Animation updated sucessfully.");
          props.setIsLoading(false);
        }
      });
    }
  }

  onChangeChatKeyword(event) {
    this.setState({
      newChatKeyword: event.target.value,
    });
  }

  onChangeChatAnimationDuration(event) {
    const val = event.target.value;
    if (isNaN(val)) {
      return;
    }

    if (val > 60) {
      Alert(
        400,
        "Sorry, you can't set animation duration more then 60 seconds."
      );
      return;
    }

    this.setState({
      newChatAnimationDuration: event.target.value,
    });
  }

  onSaveToFirebase(newSymbol, isEditMode) {
    if (isEditMode) {
      const key = this.state.updateChatSymbolAtKey;
      this.props.firebase
        .updateData(SCHEMA.CHAT_SPECIAL_SYMBOLS, key, newSymbol)
        .then(() => {
          this.onCloseAddVideoAnimation();
          this.props.setNewSpecialSymbol({ key, symbol: newSymbol });
          Alert(200, "Symbol updated successfully.");
          this.props.setIsLoading(false);
        })
        .catch(() => {
          this.onCloseAddVideoAnimation();
          this.props.setIsLoading(false);
          Alert(400, "Unable to add new chat symbol.");
        });
    } else {
      this.props.firebase
        .setData(SCHEMA.CHAT_SPECIAL_SYMBOLS)
        .push(newSymbol)
        .then((res) => {
          this.props.setNewSpecialSymbol({ key: res.key, symbol: newSymbol });
          this.onCloseAddVideoAnimation();
          Alert(200, "New symbol added successfully.");
          this.props.setIsLoading(false);
        })
        .catch(() => {
          this.onCloseAddVideoAnimation();
          this.props.setIsLoading(false);
          Alert(400, "Unable to add new chat symbol.");
        });
    }
  }

  /**
   * To upload chats screen animation
   * @param {string} type
   */
  onUploadImagesToFirbase(type, isEditMode) {
    const props = this.props;
    const animationUrl = this.state[type + "ScreenAnimationUrl"];
    const animationFile = this.state[type + "ScreenAnimationFile"];
    const { newChatKeyword, newChatAnimationDuration } = this.state;
    const imagePath = toSnakeCase(newChatKeyword);
    props.setIsLoading(true);
    handleUploadToFireBaseStorage({
      imageFile: animationFile,
      folderName:
        FILE_STORAGE_PATH[type + "_screen_animation"] + "/" + imagePath,
      storage: props.firebase.storage,
      onError: () => {
        Alert(400, "Sorry, unable to add this animation at this time.");
        props.setIsLoading(false);
      },
      fileName: "animated_image",
      isEditMode,
    }).then(async (url) => {
      const tempUrl = isEditMode ? url || animationUrl : url || "";
      if (tempUrl) {
        this.onSaveToFirebase(
          {
            keyword: newChatKeyword,
            animationUrl: tempUrl,
            duration: newChatAnimationDuration,
          },
          isEditMode
        );
        props.setIsLoading(false);
      } else {
        this.onCloseAddVideoAnimation();
        props.setIsLoading(false);
      }
    });
  }

  onSaveNewChatSymbol() {
    const {
      newChatKeyword,
      newChatAnimationDuration,
      chatScreenAnimationUrl,
      updateChatSymbolAtKey,
    } = this.state;
    let isEditMode = false;

    if (
      !newChatKeyword ||
      !chatScreenAnimationUrl ||
      !newChatAnimationDuration
    ) {
      Alert(400, "Please fill all fields!!");
      return;
    }

    if (updateChatSymbolAtKey) {
      const { chatSpecialSymbolList } = this.props;
      const symbol = chatSpecialSymbolList[updateChatSymbolAtKey];
      if (symbol) {
        if (
          symbol.keyword === newChatKeyword &&
          symbol.animationUrl === chatScreenAnimationUrl &&
          symbol.duration === newChatAnimationDuration
        ) {
          this.onCloseAddVideoAnimation();
          return;
        } else {
          isEditMode = true;
        }
      }
    }

    this.props.setIsLoading(true);
    const tempKeyword = newChatKeyword.toLowerCase();
    const { chatSpecialSymbolList } = this.props;
    const specialSymbolsKeysList = Object.keys(chatSpecialSymbolList || {});
    const specialSymbolsList = Object.values(chatSpecialSymbolList || {});
    const symbolIndex = specialSymbolsList.findIndex(
      (symbol) => symbol.keyword.toLowerCase() === tempKeyword
    );

    if (symbolIndex !== -1) {
      const symbol = specialSymbolsList[symbolIndex];
      const symbolKey = specialSymbolsKeysList[symbolIndex];
      if (symbol && !(symbolKey === updateChatSymbolAtKey)) {
        Alert(400, "Chat symbol already exists.");
        this.props.setIsLoading(false);
        return;
      }
    }

    this.onUploadImagesToFirbase(ANIMATION_TYPE.CHAT, isEditMode);
  }

  onEditChatSymbol(key, keyword, url, duration) {
    this.setState({
      isOpenAddVideoAnimation: false,
      updateChatSymbolAtKey: key,
      newChatKeyword: keyword,
      chatScreenAnimationUrl: url,
      newChatAnimationDuration: duration,
    });
  }

  onRemoveChatSymbol(key, selectedSymbol) {
    const name = toSnakeCase(selectedSymbol.keyword);
    this.toggleDeleteModal();
    this.props.setIsLoading(true);

    this.ref = this.props.firebase.deleteChatSymbol(key);
    this.ref.then(async () => {
      this.props.deleteSpecialSymbol(key);
      await this.props.firebase.deleteSymbolFromStorage(name);
      this.props.setIsLoading(false);
      this.setState({
        selectedSymbol: null,
        selectedSymbolId: "",
      });
      Alert(200, "Chat symbol deleted sucessfully.");
    });
  }

  handleOnRemoveChatSymbol(symbol, key) {
    this.setState({
      selectedSymbol: symbol,
      selectedSymbolId: key,
    });
    this.toggleDeleteModal();
  }

  onCancelRemoveChatSymbol() {
    this.setState({
      selectedSymbol: null,
      selectedSymbolId: "",
    });
    this.toggleDeleteModal();
  }

  handleChangeAnimationText(e, key, subKey) {
    const { callEndAnimations } = this.state;
    const tempVal = Object.assign({}, callEndAnimations[key]);
    tempVal[subKey] = e.target.value;
    callEndAnimations[key] = tempVal;

    this.setState({
      callEndAnimations,
    });
  }

  onEditCallEndAnimation(key) {
    const { isEditCallEndKey } = this.state;
    const { callEndAnimations } = this.props;
    if (isEditCallEndKey) {
      const tempVal = Object.assign({}, callEndAnimations[isEditCallEndKey]);
      const tempCallEndAnimations = this.state.callEndAnimations;
      tempCallEndAnimations[isEditCallEndKey] = tempVal;
      this.setState({
        callEndAnimations: tempCallEndAnimations,
      });
    }
    this.setState({
      isEditCallEndKey: key,
    });
  }

  handleCancelUpdateCallEndAnimation(key) {
    const { callEndAnimations } = this.state;
    const callEndAnimationsDetail = this.props.callEndAnimations;
    const tempVal = Object.assign({}, callEndAnimationsDetail[key]);
    callEndAnimations[key] = tempVal;

    this.setState({
      callEndAnimations,
      isEditCallEndKey: false,
    });
  }

  handleEditScreenAnimation(type, status) {
    const key = "isEdit" + type + "ScreenAnimation";
    this.setState({
      [key]: !status,
    });
  }

  renderScreenAnimation(name, type) {
    const animationUrl = this.state[type + "ScreenAnimationUrl"];
    const animationFile = this.state[type + "ScreenAnimationFile"];
    const editType = type.charAt(0).toUpperCase() + type.slice(1);
    const isEditMode = this.state["isEdit" + editType + "ScreenAnimation"];

    const { screenAnimations } = this.props;
    const imgUrl =
      screenAnimations && screenAnimations[type]
        ? screenAnimations[type].animationUrl
        : "";

    const hasNoInitialAnimation =
      !screenAnimations || !screenAnimations[type] || !animationUrl;

    if (!isEditMode && hasNoInitialAnimation) {
      return (
        <AddNewAnimation
          isShow
          text={"Add " + name + " Animation"}
          onClick={() => this.handleEditScreenAnimation(editType, isEditMode)}
        />
      );
    }

    return (
      <Col sm={6}>
        <div className="imgWrapper">
          {!animationFile && (
            <label className="fileUpload" title="Upload Animation Image">
              <i className="fas fa-camera"></i>
              <input
                type="file"
                accept=".gif"
                onChange={(e) => this.uploadAnimationFile(e, type)}
              />
            </label>
          )}
          {animationUrl || imgUrl ? (
            <img src={animationUrl || imgUrl} alt="" />
          ) : (
            <div className="addAnimation text-center">
              <p className="font-weight-bold">
                Add new {editType} screen animation
              </p>
              <Button
                color="danger"
                className="mt-1"
                size="sm"
                title="Cancel"
                onClick={() =>
                  this.handleEditScreenAnimation(editType, isEditMode)
                }
              >
                Cancel
              </Button>
            </div>
          )}
          {!!animationFile && (
            <div className="fileOption">
              <button title="save" onClick={() => this.onUpdateAnimation(type)}>
                <i className="fas fa-check" />
              </button>
              <button
                title="cancel"
                onClick={() => this.onCancelUpdateScreenAnimation(type)}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          )}
        </div>
        {!isEditMode && <p className="name">{name}</p>}
      </Col>
    );
  }

  renderAddNewChatSymbol() {
    const {
      isOpenAddVideoAnimation,
      newChatKeyword,
      newChatAnimationDuration,
      chatScreenAnimationUrl,
    } = this.state;

    return (
      <Collapse isOpen={isOpenAddVideoAnimation}>
        <Table className="mb-0">
          <tbody>
            <tr>
              <td className="keywordTd">
                <Input
                  placeholder="Enter Keyword"
                  value={newChatKeyword}
                  onChange={this.onChangeChatKeyword}
                />
              </td>
              <td>
                <div className="animationImage">
                  <img src={chatScreenAnimationUrl} alt="" />

                  <label className="fileUpload" title="Upload Animation Image">
                    <i className="fas fa-camera"></i>
                    <input
                      type="file"
                      accept=".gif"
                      onChange={(e) =>
                        this.uploadAnimationFile(e, ANIMATION_TYPE.CHAT)
                      }
                    />
                  </label>
                </div>
              </td>
              <td className="durationTd">
                <Input
                  placeholder="Enter duration"
                  value={newChatAnimationDuration}
                  onChange={this.onChangeChatAnimationDuration}
                />
              </td>
              <td className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  title="Save"
                  onClick={this.onSaveNewChatSymbol}
                >
                  Save
                </Button>
                <Button
                  color="danger"
                  className="ml-2"
                  size="sm"
                  title="Cancel"
                  onClick={this.onCloseAddVideoAnimation}
                >
                  Cancel
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Collapse>
    );
  }

  renderConfirmationDialog() {
    const { isOpenDeleteModal, selectedSymbol, selectedSymbolId } = this.state;

    if (!selectedSymbol || !selectedSymbolId) {
      return;
    }

    return (
      <Modal
        isOpen={isOpenDeleteModal}
        toggle={this.toggleDeleteModal}
        centered
        className="delete-modal"
      >
        <ModalBody>
          <button
            onClick={this.onCancelRemoveChatSymbol}
            className="modal-close"
          >
            <img src={closeIcon} alt="close" />
          </button>
          <p className="text-danger m-text mb-0">
            Are you sure to remove{" "}
            <span className="text-capitalize">{selectedSymbol.keyword}</span>{" "}
            chat symbol?{" "}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() =>
              this.onRemoveChatSymbol(selectedSymbolId, selectedSymbol)
            }
          >
            Remove
          </Button>
          <Button color="secondary" onClick={this.onCancelRemoveChatSymbol}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderUpdateSymbolView(key, index, symbol) {
    const {
      newChatKeyword,
      chatScreenAnimationUrl,
      newChatAnimationDuration,
    } = this.state;
    return (
      <tr key={key}>
        <td>{index + 1}</td>
        <td className="keywordTd">
          <Input
            placeholder="Enter Keyword"
            value={newChatKeyword}
            onChange={this.onChangeChatKeyword}
          />
        </td>
        <td>
          <div className="animationImage">
            <img src={chatScreenAnimationUrl} alt="" />

            <label className="fileUpload" title="Upload Animation Image">
              <i className="fas fa-camera"></i>
              <input
                type="file"
                accept=".gif"
                onChange={(e) =>
                  this.uploadAnimationFile(e, ANIMATION_TYPE.CHAT)
                }
              />
            </label>
          </div>
        </td>
        <td className="durationTd">
          <Input
            placeholder="Enter duration"
            value={newChatAnimationDuration}
            onChange={this.onChangeChatAnimationDuration}
          />
        </td>
        <td className="text-right">
          <Button
            color="primary"
            title="Save"
            size="sm"
            onClick={this.onSaveNewChatSymbol}
          >
            Save
          </Button>
          <Button
            color="danger"
            className="ml-2"
            title="Cancel"
            size="sm"
            onClick={this.onCloseAddVideoAnimation}
          >
            Cancel
          </Button>
        </td>
      </tr>
    );
  }

  renderSymbolView(key, index, symbol) {
    return (
      <tr key={key}>
        <td>{index + 1}</td>
        <td className="keywordTd">{symbol.keyword}</td>
        <td>
          <div className="animationImage">
            <img src={symbol.animationUrl} alt="" />
          </div>
        </td>
        <td className="durationTd">
          <p>{symbol.duration ? symbol.duration : "-"}</p>
        </td>
        <td className="text-right">
          <Button
            color="primary"
            title="Edit"
            size="sm"
            onClick={() =>
              this.onEditChatSymbol(
                key,
                symbol.keyword,
                symbol.animationUrl,
                symbol.duration
              )
            }
          >
            Edit
          </Button>
          <Button
            color="danger"
            className="ml-2"
            size="sm"
            title="Delete"
            onClick={() => this.handleOnRemoveChatSymbol(symbol, key)}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  }

  renderVideoScreenAnimation() {
    const { chatSpecialSymbolList } = this.props;
    const { isOpenAddVideoAnimation, updateChatSymbolAtKey } = this.state;
    const specialSymbolsKeys = Object.keys(chatSpecialSymbolList || {});
    const specialSymbolsList = Object.values(chatSpecialSymbolList || {});

    return (
      <>
        <h3 className="title">
          Video Screen{" "}
          <Button
            color="primary"
            className="addNewBtn float-right"
            title={isOpenAddVideoAnimation ? "Cancle" : "Add New"}
            onClick={this.toggleAddVideoAnimationView}
          >
            <i
              className="fas fa-plus"
              style={
                isOpenAddVideoAnimation ? { transform: "rotate(45deg)" } : {}
              }
            />
          </Button>
        </h3>

        {this.renderAddNewChatSymbol()}

        <Table striped>
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Keyword</th>
              <th>Image</th>
              <th className="durationTh">Animation Duration (sec)</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {specialSymbolsKeys.map((key, index) => {
              const symbol = specialSymbolsList[index];
              return updateChatSymbolAtKey === key
                ? this.renderUpdateSymbolView(key, index, symbol)
                : this.renderSymbolView(key, index, symbol);
            })}
          </tbody>
        </Table>
        {this.renderConfirmationDialog()}
      </>
    );
  }

  render() {
    const { callEndAnimations, isEditCallEndKey } = this.state;
    const callEndAnimationsKeys = Object.keys(callEndAnimations || {});
    return (
      <>
        <Container>
          <Row
            className="mx-sm-auto mb-3 animationScreen"
            style={{ maxWidth: 652 }}
          >
            {this.renderScreenAnimation(
              "Welcome Screen",
              ANIMATION_TYPE.WELCOME
            )}
            {this.renderScreenAnimation("Events Screen", ANIMATION_TYPE.EVENTS)}
            {this.renderScreenAnimation(
              "Feedback Screen",
              ANIMATION_TYPE.FEEDBACK
            )}
          </Row>
          <h4 className="text-center mb-3">Call Disconnect Animations</h4>
          <hr style={{ maxWidth: 624 }} />
          <Row
            className="mx-sm-auto mb-3 animationScreen"
            style={{ maxWidth: 652 }}
          >
            {callEndAnimationsKeys.map((key) => {
              return (
                <CallEndAnimation
                  id={key}
                  value={callEndAnimations[key]}
                  isEditMode={isEditCallEndKey === key}
                  animationType={ANIMATION_TYPE.CALL_END}
                  onEdit={this.onEditCallEndAnimation}
                  onSave={this.onUpdateAnimation}
                  onCancel={this.handleCancelUpdateCallEndAnimation}
                  onChangeText={this.handleChangeAnimationText}
                  onChangeAnimation={this.uploadAnimationFile}
                />
              );
            })}
          </Row>
        </Container>
        <Container fluid="lg">
          <div className="animationTable">
            {this.renderVideoScreenAnimation()}
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chatSpecialSymbolList: state.chatSpecialSymbols.specialSymbols,
    screenAnimations: state.screenAnimations.animations,
    callEndAnimations: state.callEndAnimations.animations,
    isUpdateCallEndAnimation: state.callEndAnimations.isUpdateCallEndAnimation,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  setNewSpecialSymbol,
  deleteSpecialSymbol,
  onUpdateCallEndAnimation,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(AnimationsComponent);
