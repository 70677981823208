import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase/context";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import avatarIcon from "../../../assets/images/icons/avatar.svg";
import { CallIcon } from "../../svgIcons";
import cx from "classnames";
import * as loginAction from "../../../redux/login/action";
import * as chatDetailAction from "../../../redux/user-chat/action";

import {
  CALL_JOIN_ASK_STATUS,
  CALL_TYPE_STATUS,
  INVITE_FRIEND_STATUS,
} from "../../shared/constants";

class AllowJoinCallModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.autoReject();
  }

  async componentDidMount() {
    const {
      firebase,
      notifications,
      userInfo: { userId },
    } = this.props;
    const currentCallId = sessionStorage.getItem("currentCallId");

    const callRef = firebase.getUserCall(currentCallId, () => {});
    await callRef.then((response) => {
      const res = response.val();
      if (res) {
        let invitedKeys = [];
        if (res.invited) {
          invitedKeys = Object.values(res.invited).filter(
            (val) =>
              val.status === INVITE_FRIEND_STATUS.ACCEPTED &&
              val.userId !== userId
          );
        }

        if (!!invitedKeys.length && notifications[0].callId !== currentCallId) {
          this.handleOnReject(notifications[0]);
        }
      }
    });
  }

  autoReject() {
    const {
      notifications,
      userInfo: { userId },
    } = this.props;
    notifications.forEach((notification) => {
      if (notification.status === "PENDING") {
        const createAt = new Date(notification.timestamp);
        const expiredAt = new Date(
          createAt.setMinutes(createAt.getMinutes() + 1)
        );

        const currentTime = new Date();
        let waitTime = expiredAt.getTime() - currentTime.getTime();
        if (waitTime <= 0) {
          waitTime = 0;
        }

        this.clearNotificationTimeout = setTimeout(async () => {
          await this.props.firebase.checkAskToJoinCallNotificationStatus(
            notification.userId,
            userId,
            async (res) => {
              if (res && res.status === "PENDING") {
                this.handleOnReject(notification);
              }
            }
          );
        }, waitTime);
      }
    });
  }

  onAcceptJoinUser(notification, isAlreadyOnCall) {
    this.props.firebase.deleteUserAskToJoinCallNotification(
      notification.hostId,
      notification.userId
    );

    this.props.firebase.updateUserAskToJoinCallNotification(
      notification.userId,
      notification.hostId,
      {
        ...notification,
        isRead: true,
        status: CALL_JOIN_ASK_STATUS.ACCEPTED,
        timestamp: Date.now(),
      }
    );

    const callId = notification.callId;
    const userId = notification.hostId;

    let callDetail = {
      status: INVITE_FRIEND_STATUS.ACCEPTED,
      userId,
    };
    this.props.firebase
      .setStatusOfUserInCall(callId, userId, callDetail)
      .then(() => {
        this.props.setOrUnsetCurrentCall({
          call_status: CALL_TYPE_STATUS.DIRECT_CALL,
          id: callId,
        });

        this.props.setUserChatDetail({});
      });

    this.props.history.push(`/call/${callId}`, {
      isCallSession: true,
    });

    if (isAlreadyOnCall) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  async handleOnAccept(notification) {
    const {
      firebase,
      userInfo: { userId },
    } = this.props;

    //When host not in call session, then let him joined the call
    const currentCallId = sessionStorage.getItem("currentCallId");
    const callId = notification.callId;

    if (currentCallId && callId !== currentCallId) {
      const callRef = firebase.getUserCall(currentCallId, () => {});
      await callRef.then((response) => {
        const res = response.val();
        if (res) {
          let invitedKeys = [];
          if (res.invited) {
            invitedKeys = Object.values(res.invited).filter(
              (val) => val.status === "ACCEPTED" && val.userId !== userId
            );
          }
          if (!!invitedKeys.length) {
            this.handleOnReject(notification);
          } else {
            this.onAcceptJoinUser(notification, true);
          }
        }
      });
    } else if (!currentCallId) {
      this.onAcceptJoinUser(notification);
    } else {
      this.props.firebase.deleteUserAskToJoinCallNotification(
        notification.hostId,
        notification.userId
      );

      this.props.firebase.updateUserAskToJoinCallNotification(
        notification.userId,
        notification.hostId,
        {
          ...notification,
          isRead: true,
          status: CALL_JOIN_ASK_STATUS.ACCEPTED,
          timestamp: Date.now(),
        }
      );
    }
  }

  handleOnReject(notification) {
    this.props.firebase.deleteUserAskToJoinCallNotification(
      notification.hostId,
      notification.userId
    );

    this.props.firebase.updateUserAskToJoinCallNotification(
      notification.userId,
      notification.hostId,
      {
        ...notification,
        isRead: true,
        status: CALL_JOIN_ASK_STATUS.REJECTED,
        timestamp: Date.now(),
      }
    );
  }

  render() {
    const { notifications } = this.props;
    const notification = notifications[0];

    return (
      <div className="fixedModal incommingCall text-center normalCenterLoader">
        <Fragment>
          <div className="srollView">
            <Fragment>
              <div className={cx("person")}>
                <img
                  className="person-image"
                  src={notification.photoUrl || avatarIcon}
                  alt="Bonfire"
                />
                <p className="normal">
                  <b>{notification.username}</b> wants to join call.
                </p>
              </div>

              <div className="callOption">
                <button
                  title="Allow"
                  onClick={() => this.handleOnAccept(notification)}
                  className="btn-icon callBtn accept"
                >
                  <CallIcon />
                </button>
                <button
                  title="Reject"
                  onClick={() => this.handleOnReject(notification)}
                  className="btn-icon callBtn reject"
                >
                  <CallIcon />
                </button>
              </div>
            </Fragment>
          </div>
        </Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAskJoinCallNotifications: (data) =>
      dispatch(loginAction.setUserAskJoinCallNotifications(data)),
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setUserChatDetail: (data) =>
      dispatch(chatDetailAction.setUserChatDetail(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(AllowJoinCallModal);
