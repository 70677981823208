import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import ImageBox from "../../components/imageBox";
import calanderIcon from "../../assets/images/event-icons/calander.png";
import { ROUTES } from "../../components/shared/constants";
import { Heart } from "../../components/svgIcons";

const AllEvents = (props) => {
  const eventList = props.eventList;
  const eventListKeys = eventList ? Object.keys(eventList) : [];
  const eventListValues = eventList ? Object.values(eventList) : [];
  const scrollSection = () => {
    setTimeout(() => {
      const elemrnt = document.getElementById("scrollView");
      if (elemrnt) {
        elemrnt.scrollTo({ top: 60, behavior: "smooth" });
      }
    }, 500);
  };
  useEffect(() => scrollSection());
  const onCalenderClick = () => {
    props.history.push(ROUTES.user.eventsCalendar);
  };

  const onEventClick = (event, key) => {
    props.history.push(ROUTES.user.eventsDetails, { key, event });
  };

  const renderEmptyView = () => {
    if (eventListValues.length) {
      return;
    }
    return <p>Events coming soon...</p>;
  };

  const { screenAnimations } = props;
  const animationUrl =
    screenAnimations && screenAnimations.events ? screenAnimations.events.animationUrl : "";

  return (
    <section className="events-wrapper">
      <Container style={{ maxWidth: 700 }}>
        <div>
          <div className="text-center head">
            <button onClick={() => onCalenderClick()} className="btn-icon">
              <ImageBox imagePath={calanderIcon} className="mb-3 mx-auto border-0" />
              <p>My Calendar</p>
            </button>
          </div>
        </div>

        <div className="events-list mt-3">
          <Row id="scrollView" className="justify-content-center view no-scrollBar mx-0">
            {eventListValues.map((eventData, index) => (
              <Col key={eventData.name} xs={6} sm={4} className="column">
                <div className="text-center">
                  <button
                    title={eventData.name}
                    onClick={() => onEventClick(eventData, eventListKeys[index])}
                    className="btn-icon"
                  >
                    <ImageBox imagePath={eventData.photoUrl} className="mb-3 mx-auto border-0" />
                    <p>{eventData.name}</p>
                  </button>
                </div>
              </Col>
            ))}
            {renderEmptyView()}
          </Row>
        </div>
        <div>
          <div className="gifWrapper">
            <img className="w-100" src={animationUrl} alt="" />
            <p className="main-text">
              Can’t live without you <Heart />
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    eventList: state.adminEventReducer.events,
    screenAnimations: state.screenAnimations.animations,
  };
};

export default connect(mapStateToProps, null)(AllEvents);
