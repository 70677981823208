/* eslint-disable */

// Constant
export const UPDATE_SCREEN_ANIMATION = "UPDATE_SCREEN_ANIMATION";


export const updateScreenAnimation = (payload) => ({
  type: UPDATE_SCREEN_ANIMATION,
  payload,
});



