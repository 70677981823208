/**
 * @fileoverview WebRTC video chat using Firebase for signaling.
 */

/**
 * Communications channel using Firebase realtime db.
 * @param {string} id Unique identifer representing the current session.
 * @param {Object} ref Firebase database reference which backs this channel.
 */
function FirebaseChannel(id, ref) {
  this._id = id;
  this._ref = ref;
  this.onmessage = null;
  this._ref.on(
    "child_added",
    function (data) {
      // The message was sent by this client, ignore.
      data = data.val();
      if (data.id == this._id) {
        return;
      }
      if (this.onmessage) {
        this.onmessage(data.data);
      }
    }.bind(this)
  );
}

/**
 * Sends data over the channel.
 * @param {Object} data Data to send.
 */
FirebaseChannel.prototype.send = function (data) {
  this._ref
    .push({ id: this._id, data: data })
    .then(function () {}.bind(this))
    .catch(function (error) {
      console.error("Error writing new message to Firebase Database", error);
    });
};

/**
 * Represents a single call between two peers.
 * @param {Object} stream Local video stream, often retrieved from getUserMedia.
 * @param {Object} channel Communications channel between two peers.
 */
function VideoCall(stream, channel) {
  this._stream = stream;
  this._channel = channel;
  this._isCaller = false;
  this.onRemoteStreamAdded = null;
  this.onReplaceTrack = null;
  this._pc = new RTCPeerConnection({
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "turn:ec2-34-195-42-47.compute-1.amazonaws.com:3478",
        credential: "Coturn_Bonfire_User@0101005",
        username: "coturn_bonfire_user",
      },
    ],
  });

  this._pc.onicecandidate = function (evt) {
    if (evt.candidate) {
      this._channel.send(JSON.stringify({ candidate: evt.candidate }));
    }
  }.bind(this);

  this._pc.ontrack = (e) => {
    if (this.onRemoteStreamAdded) {
      this.onRemoteStreamAdded(e.streams[0]);
    }
  };

  this._pc.onnegotiationneeded = (e) => {
    if (this._isCaller) {
      this.call();
    }
  };

  this._channel.onmessage = async function (evt) {
    const signal = JSON.parse(evt);
    if (signal.sdp) {
      if (signal.sdp.type == "offer") {
        this._pc
          .setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(
            function () {
              this._pc
                .createAnswer()
                .then(
                  function (desc) {
                    this._pc
                      .setLocalDescription(desc)
                      .then(() => {})
                      .catch((err) => {
                        console.log("Failure during setLocalDescription()");
                      });
                    this._channel.send(JSON.stringify({ sdp: desc }));
                  }.bind(this)
                )
                .catch((err) => {
                  console.log("Failure during createAnswer()");
                });
            }.bind(this)
          )
          .catch((err) => {
            console.log("Failure during setRemoteDescription()");
          });
      } else {
        this._pc
          .setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(() => {})
          .catch((err) => {
            console.log("Failure during setRemoteDescription()");
          });
      }
    } else if (signal.candidate) {
      if (!signal.candidate.candidate) {
        console.log("Got final candidate!");
        return;
      }

      this._pc
        .addIceCandidate(new RTCIceCandidate(signal.candidate))
        .then(() => {})
        .catch((e) => {
          console.log("Failure during addIceCandidate(): " + e.name);
        });
    }
  }.bind(this);

  this.onReplaceTrack = (stream) => {
    let videoTrack = stream.getVideoTracks()[0];
    const sender = this._pc.getSenders().find(function (s) {
      return s.track.kind === videoTrack.kind;
    });

    sender.replaceTrack(videoTrack);
  };

  this.onAddTrack(this._stream);
}

VideoCall.prototype.onAddTrack = async function (stream) {
  stream.getTracks().forEach((track) => {
    this._pc.addTrack(track, stream);
  });
};

/**
 * Creates an offer to the remote peer.
 * One of the peers must call (offer) and the other needs to accept (answer).
 */
VideoCall.prototype.call = function () {
  this._pc
    .createOffer({ offerToReceiveAudio: 1, offerToReceiveVideo: 1 })
    .then(
      function (desc) {
        this._pc
          .setLocalDescription(desc)
          .then(() => {})
          .catch((err) => {
            console.log("Failure during setLocalDescription()");
          });
        this._channel.send(JSON.stringify({ sdp: desc }));
      }.bind(this)
    )
    .catch((err) => {
      console.log("Failure during createOffer()");
    });
};

/**
 * Manages one or more p2p video calls.
 *
 * Each video chat happens in the context of a room. All peers in a room will
 * be connected to each other. If you want everyone to be in the same room,
 * or you don't have the concept of a room, then just use the same room
 * identifier for all clients.
 *
 *
 * @param {string} id Unique identifer representing the current session.
 * @param {string} roomId Identifier for the room.
 * @param {Object} stream Local video stream, often retrieved from getUserMedia.
 * @param {Object} database Firebase database object.
 */
export function VideoChatManager(id, roomId, stream, database) {
  this._id = id;
  this._roomId = roomId;
  this._stream = stream;
  this._database = database;

  /**
   * These events are called when a remote stream is added or removed. You need
   * to specify these callbacks if you want to be able to add remote streams
   * to your DOM.
   */
  this.onRemoteStreamAdded = null;
  this.onRemoteStreamRemoved = null;
  this.onHanupCall = null;
  this.onScreenShare = null;

  this._room = this._database.ref("room/" + roomId);
  this._connectedUser = this._room.child(id);
  this._connectedUser.set(id);
  this._connectedUser.onDisconnect().remove();
  if (this.callInterval) {
    clearInterval(this.callInterval);
  }

  this.onHanupCall = () => {
    if (this._connectedUser) {
      this._connectedUser.remove();
    }
  };

  this._calls = {};

  // Remove calls for disconnected peers.
  this._room.on(
    "child_removed",
    function (data) {
      if (data.val() == this._id) {
        return;
      }
      if (this._calls[data.val()]) {
        if (this.onRemoteStreamRemoved) {
          this.onRemoteStreamRemoved(data.val());
          delete this._calls[data.val()];
        }
      }
    }.bind(this)
  );

  // Create channels with everyone that is in the room.
  this.callInterval = setInterval(() => {
    this._database.ref("room/" + roomId).once(
      "value",
      function (data) {
        const userList = Object.keys(data.val()||{});
        const connectedUserList = Object.keys(this._calls)
        userList.forEach(userId => {
          console.log("userId from interval ", userId);
          if (connectedUserList.includes(userId)) {
            return;
          }
          
          if (userId === this._id) {
            return;
          }

          console.log("this._room.on child added, ", data);
          const ids = [userId, this._id];
          ids.sort();
          const channelRef = this._database.ref(
            "channels/" + this._roomId + "/" + ids[0] + "/" + ids[1]
          );
          const call = new VideoCall(
            this._stream,
            new FirebaseChannel(this._id, channelRef)
          );
          call.onRemoteStreamAdded = function (stream) {
            console.log("remote stream from simplechatChat");
            if (this.onRemoteStreamAdded) {
              this.onRemoteStreamAdded(userId, stream);
            }
          }.bind(this);

          this._calls[userId] = call;

          this.onScreenShare = (stream, isStreamAdded = false) => {
            if (!isStreamAdded) {
              Object.values(this._calls).forEach((call) => {
                call.onReplaceTrack(stream);
              });
            } else {
              const newCall = this._calls[isStreamAdded];
              newCall.onReplaceTrack(stream);
            }
          };

          // One of the peers needs to call the other. We need a way for both peers to
          // make different decisions (one calls, the other answers). This is done by
          // sorting ids of both peers and using the first sorted element as the
          // caller.
          if (ids[0] === this._id) {
            call._isCaller = true;
            // call.call();
          }
        })
      }.bind(this)
    );
  }, 10000);

  this._room.on(
    "child_added",
    function (data) {
        if (data.val() === this._id) {
          return;
        }

        console.log("this._room.on child added, ", data);
        const ids = [data.val(), this._id];
        ids.sort();
        const channelRef = this._database.ref(
          "channels/" + this._roomId + "/" + ids[0] + "/" + ids[1]
        );
        const call = new VideoCall(
          this._stream,
          new FirebaseChannel(this._id, channelRef)
        );
        call.onRemoteStreamAdded = function (stream) {
          console.log("remote stream from simplechatChat");
          if (this.onRemoteStreamAdded) {
            this.onRemoteStreamAdded(data.val(), stream);
          }
        }.bind(this);

        this._calls[data.val()] = call;

        this.onScreenShare = (stream, isStreamAdded = false) => {
          if (!isStreamAdded) {
            Object.values(this._calls).forEach((call) => {
              call.onReplaceTrack(stream);
            });
          } else {
            const newCall = this._calls[isStreamAdded];
            newCall.onReplaceTrack(stream);
          }
        };

        // One of the peers needs to call the other. We need a way for both peers to
        // make different decisions (one calls, the other answers). This is done by
        // sorting ids of both peers and using the first sorted element as the
        // caller.
        if (ids[0] === this._id) {
          call._isCaller = true;
          // call.call();
        }
      
    }.bind(this)
  );

}
