import React, { useState } from "react";
import { Modal, ModalBody, Button, Alert } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { withFirebase } from "../../../firebase/context";
import cameraIcon from "../../../assets/images/icons/circle-camera.svg";
import closeIcon from "../../../assets/images/icons/close.svg";
import {
  handleImageFileUpload,
  handleUploadToFireBaseStorage,
} from "../../shared/utils";
import { setProfilePhoto } from "../../../redux/login/action";
import { FILE_STORAGE_PATH, SCHEMA } from "../../shared/constants";
import { setIsLoading } from "../../../redux/loader/action";

const UpdateProfilePhotoModalComponent = (props) => {
  const { isOpen, toggle, userInfo } = props;
  const { userId, profilePhotoUrl } = userInfo;

  const [photoUrl, setPhotoUrl] = useState(profilePhotoUrl || "");
  const [photoFile, setPhotoFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const handleFileUpload = (event) => {
    const { url, file } = handleImageFileUpload(event);
    setPhotoUrl(url);
    setPhotoFile(file);
    if(url !== '' && file !== ''){
      setDisabled(false)
    }
  };

  const onSaveToFirebaseDB = (url) => {
    const { userId } = userInfo;
    const userProfile = {
      photoUrl: url,
    };
    props.firebase
      .updateData(SCHEMA.USERS, userId, userProfile)
      .then(() => {
        props.setProfilePhoto(url);
        setLoading(false);
        props.setIsLoading(false);
        Alert(200, "Profile picture updated sucessfully.");
        toggle();
      })
      .catch(() => {
        setLoading(false);
        props.setIsLoading(false);
        Alert(400, "Unable to save profile picture.");
      });
  };

  const onUpdateProfilePic = () => {
    setLoading(true);
    props.setIsLoading(true);
    handleUploadToFireBaseStorage({
      imageFile: photoFile,
      folderName: FILE_STORAGE_PATH.user_profile_photo + userId,
      storage: props.firebase.storage,
      onError: () => {
        Alert(
          400,
          "Sorry, unable to update your profile picture at this time."
        );
        setLoading(false);
        props.setIsLoading(false);
        toggle();
      },
      fileName: FILE_STORAGE_PATH.user_profile_photo_name,
      isEditMode: photoUrl === profilePhotoUrl,
    }).then(async (url) => {
      if (url) {
        onSaveToFirebaseDB(url);
      } else {
        Alert(200, "Profile picture updated sucessfully.");
        setLoading(false);
        props.setIsLoading(false);
        toggle();
      }
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="add-photo-modal">
      <ModalBody>
        <button onClick={toggle} className="modal-close">
          <img src={closeIcon} alt="close" />
        </button>

        <h2 className="title">Add a photo</h2>
        <p className="text">
          Show your friends
          <br />
          how cool you are
        </p>
        <div>
          <div className="imapeWrapper">
            <img src={photoUrl || cameraIcon} alt="" />
            <label className="file-input-label">
              {photoUrl && (
                <span>
                  <i className="fas fa-camera" />
                </span>
              )}
              <input
                disabled={isLoading}
                type="file"
                onChange={(event) => handleFileUpload(event)}
                accept=".jpg, .jpeg, .png"
              />
            </label>
          </div>
        </div>
        <Button
          onClick={() => onUpdateProfilePic()}
          className="w-100 mt-3 submitBtn"
          // disabled={!photoUrl || isLoading}
          disabled={isDisabled}
        >
          {isLoading ? "Saving..." : "Submit"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

const mapDispatchToProps = {
  setProfilePhoto,
  setIsLoading,
};

export default compose(
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(UpdateProfilePhotoModalComponent);
