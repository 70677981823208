import React, { Component } from "react";
import { Button } from "reactstrap";

class AnimationsSetupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Enabled by default
      value: props.value || false,
      isUpdating: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange() {
    const { value } = this.state;
    const currentCallId = sessionStorage.getItem("currentCallId");
    const {
      firebase,
    } = this.props;
    const updatedValue = !value;

    this.setState({ isUpdating: true, value: updatedValue }, async () => {
      firebase.configureChatAnimationsFeature(currentCallId, updatedValue);
      setTimeout(() => {
        this.setState({ isUpdating: false });
      }, 1000);
    });
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const { value, isUpdating } = this.state;

    return (
      <div className="chat-timer position-relative">
        <h3>Animations</h3>
        <button
          onClick={this.handleClose}
          style={{ top: 4, right: 4,fontSize:16 }}
          className="btn-icon position-absolute close "
          disabled={isUpdating}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="d-flex justify-content-between mt-1">
          <Button
            color="purple"
            onClick={this.handleChange}
            disabled={!value || isUpdating}
          >
            Enable
          </Button>
          <Button
            color="purple"
            onClick={this.handleChange}
            disabled={value || isUpdating}
          >
            Disable
          </Button>
        </div>
      </div>
    );
  }
}

export default AnimationsSetupComponent;
