import React, { Component } from "react";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import async from "async";

import { withFirebase } from "../../../firebase/context";
import ImageBox from "../../imageBox";
import { FILE_STORAGE_PATH, ROUTES, SCHEMA } from "../../../components/shared/constants";
import cameraIcon from "../../../assets/images/icons/camera.svg";
import Alert from "../../shared/common/alert";
import {
  handleImageFileUpload,
  handleUploadToFireBaseStorage,
  isValidURL,
  toSnakeCase,
  asyncForEach,
} from "../../shared/utils";
import { setNewEvent } from "../../../redux/admin-events/action";
import {
  addEventVenue,
  deleteEventVenue,
  setEventVenue,
  setEventVenueName,
  setEventVenuePhoto,
  resetEventVenue,
} from "../../../redux/admin-create-events/action";
import { cloneArrayOfObject } from "../../shared/utils";
import { setIsLoading } from "../../../redux/loader/action";

class CreateEventComponent extends Component {
  constructor(props) {
    super(props);

    let name = props.eventName;
    let photoUrl = props.eventPhotoUrl;
    let venues = cloneArrayOfObject(props.venueList);
    let eventId = "";

    const isEditMode = props.location.state && props.location.state.isEdit;
    if (isEditMode) {
      const { event, id } = props.location.state;
      if (event) {
        eventId = id;
        name = event.name;
        photoUrl = event.photoUrl;
        venues = cloneArrayOfObject(event.venueList);
      }
    }

    this.state = {
      eventId: eventId,
      eventName: name,
      eventPhotoUrl: photoUrl,
      eventPhotoFile: null,
      venueList: venues,
      isLoading: false,
      isEditMode,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.isValidEventVenueList = this.isValidEventVenueList.bind(this);
    this.handleFileUploadError = this.handleFileUploadError.bind(this);
    this.onUploadImagesToFirbase = this.onUploadImagesToFirbase.bind(this);
    this.handleSaveEvent = this.handleSaveEvent.bind(this);
    this.handleDeleteEventRow = this.handleDeleteEventRow.bind(this);
    this.handleAddEventRow = this.handleAddEventRow.bind(this);
    this.handleChangeEventName = this.handleChangeEventName.bind(this);
    this.onSaveToFirebase = this.onSaveToFirebase.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleOnChange(event, index, key) {
    const { venueList } = this.state;
    const temp = cloneArrayOfObject(venueList);

    if (key === "photoUrl") {
      const { url, file } = handleImageFileUpload(event);
      temp[index][key] = url;
      temp[index]["photoFile"] = file;
      this.props.setEventVenue({ index, key, value: url });
      this.props.setEventVenue({ index, key: "photoFile", value: file });
    } else {
      temp[index][key] = event.target.value;
      this.props.setEventVenue({ index, key, value: event.target.value });
    }

    this.setState({
      venueList: temp,
    });
  }

  handleFileUpload(event) {
    const { url, file } = handleImageFileUpload(event);
    this.setState({
      eventPhotoUrl: url,
      eventPhotoFile: file,
    });
    this.props.setEventVenuePhoto(url);
  }

  setLoading(isLoading) {
    this.setState({
      isLoading,
    });
  }

  isValidEventVenueList(venueList) {
    let isValidEvent = true;
    let error = "";

    for (const venue of venueList) {
      if (venue) {
        const { photoUrl } = venue;
        const locationName = venue.locationName.toLowerCase();
        const locationWebsite = venue.locationWebsite.toLowerCase();

        if (!locationName || !locationWebsite || !photoUrl) {
          return { isValidEvent: false };
        }

        if (locationName) {
          const list = venueList.filter((val) => val.locationName.toLowerCase() === locationName);
          if (list && list.length > 1) {
            return {
              isValidEvent: false,
              error: "Duplicate location names not allowed.",
            };
          }
        }

        if (locationWebsite) {
          if (!isValidURL(locationWebsite)) {
            return {
              isValidEvent: false,
              error: "Please enter valid website address.",
            };
          }
        }
      }
    }

    return { isValidEvent, error };
  }

  handleFileUploadError(error) {
    if (error) {
      this.setLoading(false);
      this.props.setIsLoading(false);
      Alert(400, "Unable to create new event.");
    }
  }

  onSaveToFirebase(newEvent) {
    const { firebase, history, setNewEvent, resetEventVenue } = this.props;
    const { isEditMode, eventId } = this.state;
    if (isEditMode) {
      firebase
        .updateData(SCHEMA.EVENTS, eventId, newEvent)
        .then(() => {
          setNewEvent({ key: eventId, event: newEvent });
          Alert(200, "Event updated successfully.");
          resetEventVenue();
          this.setLoading(false);
          this.props.setIsLoading(false);
          history.push(ROUTES.admin.viewEvent);
        })
        .catch(() => {
          this.setLoading(false);
          this.props.setIsLoading(false);
          Alert(400, "Unable to update event.");
        });
    } else {
      firebase
        .setData(SCHEMA.EVENTS)
        .push(newEvent)
        .then((res) => {
          const key = res.key;
          setNewEvent({ key, event: newEvent });
          Alert(200, "Event created successfully.");
          resetEventVenue();
          this.setLoading(false);
          this.props.setIsLoading(false);
          history.push(ROUTES.admin.viewEvent);
        })
        .catch(() => {
          this.setLoading(false);
          this.props.setIsLoading(false);
          Alert(400, "Unable to create new event.");
        });
    }
  }

  onUploadImagesToFirbase() {
    const { eventName, eventPhotoUrl, venueList, eventPhotoFile, isEditMode } = this.state;
    const { firebase, setEventVenuePhoto } = this.props;
    let tempEventPhotoUrl = "";
    const eventFolderName = toSnakeCase(eventName);
    const tempVenueList = [];
    const tempList = venueList.filter((val) => val);
    const venueListLength = tempList.length;
    const { event_photos } = FILE_STORAGE_PATH;
    const eventPhotoPath = event_photos + eventFolderName;

    this.eventImageRef = handleUploadToFireBaseStorage({
      imageFile: eventPhotoFile,
      folderName: eventPhotoPath + "/image",
      storage: firebase.storage,
      fileName: eventFolderName,
      onError: this.handleFileUploadError,
      isEditMode,
    }).then(async (eventUrl) => {
      tempEventPhotoUrl = isEditMode ? eventUrl || eventPhotoUrl : eventUrl || "";
      setEventVenuePhoto(tempEventPhotoUrl);

      await asyncForEach(tempList, async (venue, index) => {
        const venueName = toSnakeCase(venue.locationName);
        this.venueRef = handleUploadToFireBaseStorage({
          imageFile: venue.photoFile,
          folderName: eventPhotoPath + "/" + venueName + "/image",
          fileName: venueName,
          storage: firebase.storage,
          onError: this.handleFileUploadError,
          isEditMode,
        });
        await this.venueRef.then((venueUrl) => {
          tempVenueList[index] = venue;
          tempVenueList[index].photoUrl = isEditMode ? venueUrl || venue.photoUrl : venueUrl || "";
          delete tempVenueList[index].photoFile;

          if (venueListLength === index + 1) {
            const newEvent = {
              name: eventName,
              photoUrl: tempEventPhotoUrl,
              venueList: cloneArrayOfObject(tempVenueList),
            };
            this.onSaveToFirebase(newEvent);
          }
        });
      });
    });
  }

  handleSaveEvent() {
    const { eventId, eventName, eventPhotoUrl, venueList, isEditMode } = this.state;
    const { isValidEvent, error } = this.isValidEventVenueList(venueList);

    if (!eventName || !eventPhotoUrl || !isValidEvent) {
      Alert(400, error || "Please fill all fields!!");
      return;
    }

    this.setLoading(true);
    this.props.setIsLoading(true);

    this.props.firebase.getQueryData(SCHEMA.EVENTS, eventName.toLowerCase(), "name", (res) => {
      if (res.err) {
        if (isEditMode) {
          if (!res.id.includes(eventId)) {
            Alert(400, "Event already exists.");
            this.setLoading(false);
            this.props.setIsLoading(false);
          } else {
            this.onUploadImagesToFirbase();
          }
        } else {
          Alert(400, "Event already exists.");
          this.setLoading(false);
          this.props.setIsLoading(false);
        }
      } else {
        this.onUploadImagesToFirbase();
      }
    });
  }

  handleAddEventRow() {
    const temp = cloneArrayOfObject(this.state.venueList);
    temp.push({
      photoUrl: "",
      locationName: "",
      locationWebsite: "",
    });
    this.setState({
      venueList: temp,
    });
    this.props.addEventVenue({
      photoUrl: "",
      locationName: "",
      locationWebsite: "",
    });
  }

  handleDeleteEventRow(index) {
    const temp = cloneArrayOfObject(this.state.venueList);
    temp.splice(index, 1);

    this.setState({
      venueList: temp,
    });

    this.props.deleteEventVenue(index);
  }

  handleChangeEventName(event) {
    this.setState({
      eventName: event.target.value,
    });
    this.props.setEventVenueName(event.target.value);
  }

  handleCancel() {
    this.props.history.push(ROUTES.admin.viewEvent);
  }

  render() {
    console.log("render : ", this.state);

    const { eventPhotoUrl, eventName, venueList, isLoading, isEditMode } = this.state;
    const venueLen = venueList.length;
    const isRemoveButton = venueLen > 1;

    return (
      <div className="event-create-form rounded-input">
        <div className="mb-5">
          <ImageBox
            className="mx-auto mb-4"
            imagePath={eventPhotoUrl || cameraIcon}
            onChange={(event) => this.handleFileUpload(event, "photoUrl")}
            isFileUpload
            isDisabled={isLoading}
          />
          <div style={{ maxWidth: "340px", margin: "0 auto" }}>
            <Input
              disabled={isLoading}
              placeholder="Event Name"
              value={eventName}
              onChange={this.handleChangeEventName}
            />
          </div>
        </div>
        <div className="inputRow">
          <div className="photo">
            <p>Photos</p>
          </div>
          <div className="formWrapper">
            <Row>
              <Col md={6} lg={4}>
                <p>Name</p>
              </Col>
              <Col md={6} lg={4}>
                <p>Website</p>
              </Col>
            </Row>
          </div>
        </div>
        {venueList.map((venue, index) => (
          <div key={index} className="inputRow align-items-center mb-3">
            <div className="photo">
              <ImageBox
                sm
                imagePath={venue.photoUrl || cameraIcon}
                onChange={(event) => this.handleOnChange(event, index, "photoUrl")}
                isFileUpload
                isDisabled={isLoading}
              />
            </div>
            <div className="formWrapper">
              <Row>
                <Col md={6} lg={4}>
                  <FormGroup>
                    <Input
                      disabled={isLoading}
                      placeholder="Location Name"
                      value={venue.locationName}
                      onChange={(event) => this.handleOnChange(event, index, "locationName")}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={8}>
                  <FormGroup>
                    <Input
                      disabled={isLoading}
                      placeholder="Location Website"
                      value={venue.locationWebsite}
                      onChange={(event) => this.handleOnChange(event, index, "locationWebsite")}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            {!isLoading && isRemoveButton && (
              <button
                className="btn btn-icon shadow-none"
                onClick={() => this.handleDeleteEventRow(index)}
              >
                <i className="fas fa-times text-danger" />
              </button>
            )}
          </div>
        ))}

        {!isLoading && (
          <button
            className="btn btn-icon text-center ml-4 shadow-none"
            onClick={this.handleAddEventRow}
          >
            <i className="fas fa-plus text-primary" />
          </button>
        )}

        <div className="text-center">
          <Button
            color="primary"
            onClick={this.handleSaveEvent}
            disabled={isLoading}
            className="createBtn"
          >
            {isEditMode
              ? isLoading
                ? "Updating..."
                : "Update"
              : isLoading
              ? "Creating..."
              : "Create"}
          </Button>
          <Button
            color="danger"
            onClick={this.handleCancel}
            disabled={isLoading}
            className="createBtn"
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventName: state.adminCreateEventReducer.name,
    eventPhotoUrl: state.adminCreateEventReducer.photoUrl,
    venueList: state.adminCreateEventReducer.venueList,
  };
};

const mapDispatchToProps = {
  setNewEvent,
  addEventVenue,
  deleteEventVenue,
  setEventVenueName,
  setEventVenuePhoto,
  setEventVenue,
  resetEventVenue,
  setIsLoading,
};

export default compose(
  withRouter,
  withFirebase
)(connect(mapStateToProps, mapDispatchToProps)(CreateEventComponent));
