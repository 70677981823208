import React from "react";
import { Button, Col } from "reactstrap";

export default ({ isShow, text = "", onClick }) => {
  if (!isShow) {
    return null;
  }

  return (
    <Col sm={6}>
      <div className="imgWrapper">
        <Button
          color="primary"
          className="addAnimation"
          size="sm"
          title="Add"
          onClick={onClick}
        >
          {text}
        </Button>
      </div>
    </Col>
  );
};
