import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { withFirebase } from "../../../firebase/context";

class SignInFacebookComponent extends Component {
  constructor(props) {
    super(props);
    this.onSignIn = this.onSignIn.bind(this);
  }

  onSignIn() {
    this.props.firebase
      .onSignInWithFacebook()
      .then((user) => {
        this.props.onSignIn(user);
      })
      .catch((error) => {
        console.log("Error: ", error);
        this.props.onSignIn(null);
      });
  }

  render() {
    return (
      <Button
        onClick={this.onSignIn}
        color="primary"
        className="w-100 f-login mb-3"
      >
        <i className="fab fa-facebook-square mr-2" />
        Log in with Facebook
      </Button>
    );
  }
}

export default compose(withRouter, withFirebase)(SignInFacebookComponent);

