/* eslint-disable */

// Constant
export const SET_USER_CHAT_DETAIL =
  "SET_USER_CHAT_DETAIL";
export const RESET_USER_CHAT = "RESET_USER_CHAT";

/**
 * @param {Object} payload 
 */
export const setUserChatDetail = (payload) => ({
  type: SET_USER_CHAT_DETAIL,
  payload: payload,
});

/**
 */
export const resetUserChatData = () => ({
  type: RESET_USER_CHAT,
});
