import {
  ADD_EVENT_VENUE,
  DELETE_EVENT_VENUE,
  RESET_EVENT_VENUE_STATE,
  SET_EVENT_DETAIL,
  SET_EVENT_VENUE_NAME,
  SET_EVENT_VENUE_PHOTO,
  SET_EVENT_VENUE_VALUE,
} from "./action";
import { cloneArrayOfObject } from "../../components/shared/utils";

const defaultState = {
  name: "",
  photoUrl: "",
  venueList: [
    {
      photoUrl: "",
      locationName: "",
      locationWebsite: "",
      photoFile: null,
    },
  ],
};

const adminCreateEventReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_EVENT_DETAIL: {
      return action.payload;
    }

    case SET_EVENT_VENUE_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }

    case SET_EVENT_VENUE_PHOTO: {
      return {
        ...state,
        photoUrl: action.payload,
      };
    }

    case ADD_EVENT_VENUE: {
      const prevState = cloneArrayOfObject(state.venueList);
      prevState.push(action.payload);
      return {
        ...state,
        venueList: prevState,
      };
    }

    case DELETE_EVENT_VENUE: {
      const prevState = cloneArrayOfObject(state.venueList);
      prevState.splice(action.payload, 1);
      return {
        ...state,
        venueList: prevState,
      };
    }

    case SET_EVENT_VENUE_VALUE: {
      const prevState = cloneArrayOfObject(state.venueList);
      const { index, key, value } = action.payload;
      prevState[index][key] = value;
      return {
        ...state,
        venueList: prevState,
      };
    }

    case RESET_EVENT_VENUE_STATE: {
      return {
        name: "",
        photoUrl: "",
        venueList: [
          {
            photoUrl: "",
            locationName: "",
            locationWebsite: "",
          },
        ],
      };
    }

    default:
      return state;
  }
};

export default adminCreateEventReducer;
