import {
  UPDATE_CALL_END_ANIMATION,
  IS_UPDATE_CALL_END_ANIMATION,
} from "./action";

const defaultState = {
  animations: {
    animationOne: {
      animationUrl: "",
      description: "",
      title: "",
    },
    animationTwo: {
      animationUrl: "",
      description: "",
      title: "",
    },
  },
  isUpdateCallEndAnimation: false,
};

const callEndAnimationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_CALL_END_ANIMATION: {
      const updatedAnimations = { ...state.animations, ...action.payload };
      return {
        ...state,
        animations: updatedAnimations,
      };
    }

    case IS_UPDATE_CALL_END_ANIMATION: {
      return {
        ...state,
        isUpdateCallEndAnimation: action.payload,
      };
    }

    default:
      return state;
  }
};

export default callEndAnimationsReducer;
