import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import DateTimePicker from "react-datetime";
import moment from "moment";
import ImageBox from "../../components/imageBox";
import ClockIcon from "../../assets/images/icons/clock.svg";
import AddIcon from "../../assets/images/icons/add-call.svg";
import MarkerIcon from "../../assets/images/icons/marker.svg";
import InviteFriends from "./InviteFriends";
import RecommendedVenue from "./RecommendedVenue";
import EventFieldCardComponent from "./eventFieldCard";
import { cloneArrayOfObject, formatDateTime, toTitleCase } from "../../components/shared/utils";
import { withFirebase } from "../../firebase/context";
import { setIsLoading } from "../../redux/loader/action";
import {
  DATE_TIME_FORMAT,
  INVITE_FRIEND_STATUS,
  ROUTES,
  SCHEMA,
  MAX_USER_SUPPORTED_FOR_CALL,
  EMAIL_NOTIFICATION
} from "../../components/shared/constants";
import Alert from "../../components/shared/common/alert";
import { setNewUserEvent } from "../../redux/user-events/action";
import { resetFriendList, resetFriendListUpdationStatus } from "../../redux/login/action";

class EventDetailsComponent extends Component {
  constructor(props) {
    super(props);

    let name = "";
    let photoUrl = "";
    let venues = [];
    let eventId = "";

    if (props.location.state) {
      const { event, key } = props.location.state;
      if (key) {
        eventId = key;
      }
      if (event) {
        name = event.name;
        photoUrl = event.photoUrl;
        venues = cloneArrayOfObject(event.venueList);
      }
    }

    this.state = {
      eventId: eventId,
      eventName: name,
      eventPhotoUrl: photoUrl,
      venueList: venues,
      filteredVenueList: venues,
      friendList: [],
      filteredFriendList: [],
      isFriendListFetched: false,
      isLoading: false,
      isOpenVenueDropdown: false,
      selectedVenueList: [],
      isOpenFriendsDropdown: false,
      selectedFriendList: [],
      eventDateTime: new Date(),
      isFriendsUpdated: false,
    };

    this.onChangeDate = this.onChangeDate.bind(this);

    this.handleChangeFriendsInput = this.handleChangeFriendsInput.bind(this);
    this.onSelectFriends = this.onSelectFriends.bind(this);
    this.onOpenFriendsDropdown = this.onOpenFriendsDropdown.bind(this);
    this.onCloseFriendsDropdown = this.onCloseFriendsDropdown.bind(this);
    this.onRemoveSelectedFriend = this.onRemoveSelectedFriend.bind(this);

    this.handleChangeVenueInput = this.handleChangeVenueInput.bind(this);
    this.onSelectRecommendedVenue = this.onSelectRecommendedVenue.bind(this);
    this.onOpenRecommendedVenue = this.onOpenRecommendedVenue.bind(this);
    this.onCloseRecommendedVenue = this.onCloseRecommendedVenue.bind(this);
    this.onRemoveSelectedVenue = this.onRemoveSelectedVenue.bind(this);
    this.handleCreateUserEvent = this.handleCreateUserEvent.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const {
      userInfo: { userId },
      firebase,
    } = this.props;

    firebase.getData("/" + SCHEMA.USERS + "/" + userId + "/friends", async (users) => {
      if (users) {
        const userList = Object.values(users);

        if (userList.length) {
          let tempFriendsList = [];
          this.props.resetFriendList(users);
          tempFriendsList = cloneArrayOfObject(
            userList.filter((val) => val && val.status === INVITE_FRIEND_STATUS.ACCEPTED)
          );

          let friendsList = [];

          for (const i of tempFriendsList) {
            if (i && i.userId && !friendsList.includes(i.userId)) {
              friendsList.push(i.userId);
            }
          }

          let lastFriendId = "";
          if (friendsList.length) {
            lastFriendId = friendsList[friendsList.length - 1];
          } else {
            this.setState({
              isFriendListFetched: true,
            });
          }

          for (const val of friendsList) {
            await firebase.getData("/" + SCHEMA.USERS + "/" + val, (user) => {
              const friendList = cloneArrayOfObject(this.state.friendList);

              if (user) {
                friendList.push({
                  email: user.email,
                  photoUrl: user.photoUrl,
                  role: user.role,
                  username: user.username,
                  userId: val,
                  isConnected: true,
                });
              }

              this.setState({
                friendList: friendList,
                filteredFriendList: friendList,
              });
              if (lastFriendId === val) {
                this.setState({
                  isFriendListFetched: true,
                });
              }
            });
          }
        }
      } else {
        this.setState({
          isFriendListFetched: true,
        });
      }
    });
  }

  onChangeDate(dateVal) {
    const currentDate = new Date();
    const selectedDate = new Date(dateVal);

    if (currentDate.getTime() > selectedDate.getTime()) {
      if (currentDate.getDate() === selectedDate.getDate()) {
        Alert(400, "Please change time of event, before selecting this date.");
      }
      return;
    }

    this.setState({
      eventDateTime: dateVal,
    });
  }

  handleChangeFriendsInput(event) {
    const username = event.target.value.toLowerCase();
    const { friendList, selectedFriendList } = this.state;
    const filteredList = friendList.filter((item) =>
      item.username.toLowerCase().includes(username)
    );

    let updatedList = [];
    if (filteredList) {
      updatedList = filteredList;
    } else {
      updatedList = username ? [] : friendList;
    }

    selectedFriendList.forEach((item) => {
      const name = item.username.toLowerCase();
      const selectedIndex = updatedList.findIndex((user) => user.username.toLowerCase() === name);
      if (selectedIndex !== -1) {
        updatedList[selectedIndex].isSelected = true;
      }
    });

    this.setState({
      filteredFriendList: updatedList,
    });
  }

  onSelectFriends(friend) {
    const selectedFriendList = cloneArrayOfObject(this.state.selectedFriendList);

    if (selectedFriendList.length === MAX_USER_SUPPORTED_FOR_CALL) {
      Alert(400, `Sorry you can't add more firends. Only ${MAX_USER_SUPPORTED_FOR_CALL} people are allowed to participate in this event.`);
      return;
    }
    const username = friend.username.toLowerCase();
    const isAlreadySelected = selectedFriendList.findIndex(
      (item) => item.username.toLowerCase() === username
    );
    if (isAlreadySelected) {
      selectedFriendList.push(friend);
      this.setState({
        selectedFriendList,
      });
    }
    this.onCloseFriendsDropdown();
  }

  onOpenFriendsDropdown() {
    const { friendList, selectedFriendList } = this.state;
    let updatedList = cloneArrayOfObject(friendList);

    selectedFriendList.forEach((item) => {
      const name = item.username.toLowerCase();
      const selectedIndex = updatedList.findIndex((user) => user.username.toLowerCase() === name);
      if (selectedIndex !== -1) {
        updatedList[selectedIndex].isSelected = true;
      } else {
      }
    });

    this.setState({
      filteredFriendList: updatedList,
      isOpenFriendsDropdown: true,
    });
  }

  onCloseFriendsDropdown() {
    this.setState({
      isOpenFriendsDropdown: false,
    });
  }

  onRemoveSelectedFriend(index) {
    const selectedFriendList = cloneArrayOfObject(this.state.selectedFriendList);
    selectedFriendList.splice(index, 1);
    this.setState({
      selectedFriendList,
    });
  }

  handleChangeVenueInput(event) {
    const venueName = event.target.value.toLowerCase();
    const { venueList } = this.state;
    const filteredList = venueList.filter((venue) =>
      venue.locationName.toLowerCase().includes(venueName)
    );

    if (filteredList) {
      this.setState({
        filteredVenueList: filteredList,
      });
    } else {
      this.setState({
        filteredVenueList: venueName ? [] : venueList,
      });
    }
  }

  onSelectRecommendedVenue(venue) {
    const selectedVenueList = cloneArrayOfObject(this.state.selectedVenueList);

    const venueLocName = venue.locationName.toLowerCase();
    const isAlreadySelected = selectedVenueList.findIndex(
      (venueItem) => venueItem.locationName.toLowerCase() === venueLocName
    );
    if (isAlreadySelected === -1) {
      selectedVenueList.push(venue);
      this.setState({
        selectedVenueList: selectedVenueList,
      });
    }
    this.onCloseRecommendedVenue();
  }

  onOpenRecommendedVenue() {
    this.setState({
      isOpenVenueDropdown: true,
    });
  }

  onCloseRecommendedVenue() {
    this.setState({
      isOpenVenueDropdown: false,
    });
  }

  onRemoveSelectedVenue(index) {
    const selectedVenueList = cloneArrayOfObject(this.state.selectedVenueList);
    selectedVenueList.splice(index, 1);
    this.setState({
      selectedVenueList,
    });
  }

  setLoading(isLoading) {
    this.setState({
      isLoading,
    });
  }

  onSaveEvent(userEvent) {
    const currentUserData = this.props.userInfo;
    const { eventName, eventDateTime } = this.state;

    let dateTime = moment(eventDateTime).format("LLLL");

    this.props.firebase
      .setData(SCHEMA.USER_EVENTS)
      .push(userEvent)
      .then((res) => {
        Alert(200, "Event created successfully.");

        let allFriends = Object.assign({}, userEvent.invited);

        delete allFriends[currentUserData.userId];
        let allUserFriends = Object.values(allFriends);

        for (const friend of allUserFriends) {
          let invitor = toTitleCase(currentUserData.username);
          let object = {
            title: `${invitor} has invited you to ${eventName} event on ${dateTime}`,
            timestamp: Date.now(),
            creatorId: currentUserData.userId,
            userId: currentUserData.userId,
            type: "EVENT_INVITE",
            photoUrl: currentUserData.profilePhotoUrl,
            status: "PENDING",
            friendId: "",
            isread: false,
          };

          this.props.firebase.addUserNotification(SCHEMA.USERS, friend, object).then(() => {
            this.props.firebase.getData(SCHEMA.USERS + '/' + friend, (res) => {
              if(res && res.email) {
                this.onSendInviteEmail(res.email,eventName, dateTime, invitor);
              }
            })
          });
        }
        this.props.setNewUserEvent({ key: res.key, event: userEvent });
        this.setLoading(false);
        this.props.setIsLoading(false);
        this.props.history.push(ROUTES.user.eventsCalendar);
      })
      .catch((err) => {
        this.setLoading(false);
        this.props.setIsLoading(false);
        Alert(400, "Unable to create new event.");
      });
  }

  onSendInviteEmail = (toUserEmail, eventTitle, eventTime, invitor) => {
    // const text = `<div> <h4>Invitation</h4><p>You have been invited to <b>${eventTitle}</b>.</p><p>At time: ${eventTime}</p></div>`;

    // const msg = {
    //   to: toUserEmail,
    //   from: "no-reply@ourbonfire.com",
    //   subject: `Invitation to ${eventTitle}`,
    //   text: text,
    // };
    const date = moment(eventTime);
    const msg = {
      from: {email: "no-reply@ourbonfire.com"},
      personalizations: [{
        to: [{email: toUserEmail}],
        dynamic_template_data: {
          event_title: eventTitle,
          sender_name: invitor,
          event_date: date.format("dddd, MMMM D, YYYY"),
          event_time: date.format("h:mma")
        }
      }],
      template_id: "d-f2ce015841474c8bbd67cc9d0b6f6b2f"
    };


    const body = JSON.stringify(msg);

    fetch(EMAIL_NOTIFICATION.INVITE_API, {
      method: "POST",
      body: "" + body,
    })
      .then((response) => response.json())
      .then((json) => {})
      .catch(() => {});
  };

  handleCreateUserEvent() {
    // const { eventId, selectedFriendList, selectedVenueList, eventDateTime } = this.state;
    const { eventId, selectedFriendList, eventDateTime } = this.state;
    // if (!selectedFriendList.length || !selectedVenueList.length || !eventDateTime) {
      if (!selectedFriendList.length || !eventDateTime) {
      Alert(400, "Please fill all fields!!");
      return;
    }

    const {
      userInfo: { userId },
      setIsLoading,
    } = this.props;

    this.setLoading(true);
    setIsLoading(true);

    const invited = {};
    selectedFriendList.forEach((val) => {
      invited[val.userId] = val.userId;
    });

    invited[userId] = userId;
    const date = moment(eventDateTime)
    const userEvent = {
      creatorId: userId,
      eventId,
      invited,
      // venueList: selectedVenueList,
      venueList: [],
      dateTime: new Date(eventDateTime).toString(),
      event_date: date.format("dddd, MMMM D, YYYY"),
      event_time: date.format("h:mma"),
    };
    this.onSaveEvent(userEvent);
  }

  isValidDate = (current) => {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  handleCancel() {
    this.props.history.push(ROUTES.user.events);
  }

  renderDateTimeInput() {
    const { eventDateTime } = this.state;

    const dateTimeVal = formatDateTime(eventDateTime, DATE_TIME_FORMAT.user_event_date);

    return (
      <>
        <div className="d-flex align-items-center mb-4">
          <div className="eventIcon">
            <img src={ClockIcon} alt={"Clock"} />
          </div>
          <div className="datepickerFill">
            <p className="date mb-0">{dateTimeVal}</p>
            <DateTimePicker
              value={eventDateTime}
              onChange={this.onChangeDate}
              isValidDate={this.isValidDate}
            />
          </div>
        </div>
      </>
    );
  }

  renderInviteFriendInput() {
    const {
      friendList,
      filteredFriendList,
      isOpenFriendsDropdown,
      selectedFriendList,
      isFriendListFetched,
    } = this.state;

    return (
      <EventFieldCardComponent
        icon={AddIcon}
        alt={"Accounts"}
        placeholder={"Invite Friends"}
        imgKey={"photoUrl"}
        selectedList={selectedFriendList}
        imgClassName={"userImg"}
        onClose={this.onCloseFriendsDropdown}
        onFocus={this.onOpenFriendsDropdown}
        onChange={this.handleChangeFriendsInput}
        onRemoveSelected={this.onRemoveSelectedFriend}
      >
        <InviteFriends
          isOpen={isOpenFriendsDropdown}
          isFetched={isFriendListFetched}
          hasFriends={!!friendList.length}
          friendList={filteredFriendList}
          onSelect={this.onSelectFriends}
        />
      </EventFieldCardComponent>
    );
  }

  renderVenueInput() {
    const { filteredVenueList, isOpenVenueDropdown, selectedVenueList } = this.state;
    return (
      <EventFieldCardComponent
        icon={MarkerIcon}
        alt={"Venue"}
        placeholder={"Recommended venue"}
        imgKey={"photoUrl"}
        selectedList={selectedVenueList}
        imgClassName={"locationImg"}
        onClose={this.onCloseRecommendedVenue}
        onFocus={this.onOpenRecommendedVenue}
        onChange={this.handleChangeVenueInput}
        onRemoveSelected={this.onRemoveSelectedVenue}
      >
        <RecommendedVenue
          isOpen={isOpenVenueDropdown}
          venueList={filteredVenueList}
          onSelect={this.onSelectRecommendedVenue}
        />
      </EventFieldCardComponent>
    );
  }

  render() {
    const { eventName, eventPhotoUrl, isLoading } = this.state;

    return (
      <section className="py-5 events-wrapper">
        <Container>
          <Row className="justify-content-center events-list text-center">
            <Col sm={12}>
              <div className="">
                <button className="btn-icon">
                  <ImageBox imagePath={eventPhotoUrl} className="mb-3 mx-auto border-0" />
                  <p className="text-white">{eventName}</p>
                </button>
              </div>

              <div className="eventDetails rounded-input mt-5 mb-4">
                <div>
                  {this.renderDateTimeInput()}

                  {this.renderInviteFriendInput()}
                  {/* {this.renderVenueInput()} */}
                  <div className="text-center mt-5 mb-4">
                    <Button
                      color="primary"
                      className="createBtn"
                      disabled={isLoading}
                      onClick={this.handleCreateUserEvent}
                    >
                      {isLoading ? "Saving..." : "Create"}
                    </Button>
                    <Button
                      color="danger"
                      className="createBtn"
                      disabled={isLoading}
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginReducer,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  setNewUserEvent,
  resetFriendList,
  resetFriendListUpdationStatus,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(EventDetailsComponent);
