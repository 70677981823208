import React from "react";
import { Col, Button, Input } from "reactstrap";
import AddNewAnimation from "../addNew";

export const CallEndAnimation = ({
  id,
  value,
  isEditMode,
  animationType,
  onChangeAnimation,
  onChangeText,
  onEdit,
  onSave,
  onCancel,
}) => {
  if (!value) {
    return null;
  }

  if (!isEditMode && !value.animationUrl) {
    return (
      <AddNewAnimation
        isShow
        text={`Add Animation ${id.split("animation").join(" ")}`}
        onClick={() => onEdit(id)}
      />
    );
  }

  return (
    <Col sm={6}>
      <div className="imgWrapper">
        {isEditMode && (
          <label className="fileUpload" title="Upload Animation Image">
            <i className="fas fa-camera"></i>
            <input
              type="file"
              accept=".gif"
              onChange={(e) => onChangeAnimation(e, animationType, id)}
            />
          </label>
        )}

        <img src={value.animationUrl} alt="" />
      </div>

      <div className="callAniEdit">
        {!isEditMode ? (
          <>
            <p className="name mb-0">{value.title}</p>
            <p className="name">{value.description}</p>
          </>
        ) : (
          <>
            <Input
              placeholder="Title"
              className="mb-1"
              value={value.title}
              onChange={(e) => onChangeText(e, id, "title")}
            />

            <Input
              placeholder="Description"
              value={value.description}
              onChange={(e) => onChangeText(e, id, "description")}
            />
          </>
        )}
        <div className="text-center mt-3">
          {!isEditMode ? (
            <Button
              color="primary"
              size="sm"
              title="Edit"
              onClick={() => onEdit(id)}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                size="sm"
                title="Save"
                onClick={() => onSave(animationType, id)}
              >
                Save
              </Button>
              <Button
                color="danger"
                className="ml-2"
                size="sm"
                title="Cancel"
                onClick={() => onCancel(id)}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};
