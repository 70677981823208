/**
 * @constant SCHEMA : To define database schema.
 */
export const SCHEMA = {
  USERS: "users",
  EVENTS: "events",
  USER_EVENTS: "user_events",
  NOTIFICATIONS: "notifications",
  CHAT: "chats",
  CALLS: "calls",
  CHAT_SPECIAL_SYMBOLS: "chat_special_symbols",
  SCREEN_ANIMATIONS: "screen_animations",
  CALL_DISCONNECT_ANIMATIONS: "call_disconnect_animations",
  RECENT_GIFS: "recent_gifs",
};

/**
 * @constant ROUTES : To define app routes.
 */
export const ROUTES = {
  admin: {
    viewEvent: "/admin",
    createEvent: "/create-admin-event",
    editEvent: "/edit-admin-event",
  },
  user: {
    welcome: "/",
    events: "/events",
    eventsCalendar: "/event-calendar",
    eventsDetails: "/event-details",
    eventsCall: "/call/:eventId",
    call: "/call",
    dashboard: "/dashboard",
    login: "/login",
    signup: "/signup",
    forgotPassword: "/forgot-password",
    notifications: "/notifications",
    feedback: "/feedback",
  },
  pageNotFound: "/page-not-found",
};

/**
 * @constant FILE_STORAGE_PATH : To define path of uploaded files on firebase storage
 */
export const FILE_STORAGE_PATH = {
  user_profile_photo: "users/profile-image/",
  user_profile_photo_name: "user_profile",
  event_photos: "event-images/",
  welcome_screen_animation: "animations/welcome",
  events_screen_animation: "animations/events",
  chat_screen_animation: "animations/chat",
  chat_image_storage: "chats/",
  call_end_animationOne: "animations/callEnd_animationOne",
  call_end_animationTwo: "animations/callEnd_animationTwo",
  facebook_share_url: "facebook/bonfire_connect_memory",
  video_file: "videos",
  feedback_screen_animation: "animations/feedback",
};

/**
 * @constant DATE_TIME_FORMAT : To define formats for date-time values
 */
export const DATE_TIME_FORMAT = {
  user_event_date: "dddd, MMM DD, YYYY hh:mm A",
};

/**
 * @constant INVITE_FRIEND_STATUS
 */
export const INVITE_FRIEND_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

/**
 * @constant CALL_TYPE_STATUS
 */
export const CALL_TYPE_STATUS = {
  DIRECT_CALL: "DIRECT_CALL",
  EVENT_CALL: "EVENT_CALL",
};

/**
 * @constant MAX_USER_SUPPORTED_FOR_CALL
 */
export const MAX_USER_SUPPORTED_FOR_CALL = 8;

/**
 * @constant ALERT_TYPE_STATUS
 */
export const ALERT_TYPE_STATUS = {
  DIRECT_CALL_DECLINED: "DIRECT_CALL_DECLINED",
};

/**
 * @constant CALL_END_OPTIONS
 */
export const CALL_END_OPTIONS = {
  animationTwo: "animationTwo",
  animationOne: "animationOne",
  NORMAL_CALL_END: "End Call",
};

/**
 * @constant EMAIL_NOTIFICATION
 */
export const EMAIL_NOTIFICATION = {
  INVITE_API:
    "https://us-central1-bonfire-connect.cloudfunctions.net/emailMessage",
  CONTACT_US_TO: "abnero@gmail.com",
};

/**
 * @constant VIDEO_CALL_BROWSER_SUPPORT
 */
export const VIDEO_CALL_BROWSER_SUPPORT = {
  SAFARI: 11,
  CHROME: 28,
  FIREFOX: 22,
};

/**
 * @constant SCREEN_SHARE_BROWSER_SUPPORT
 */
export const SCREEN_SHARE_BROWSER_SUPPORT = {
  SAFARI: 13,
};

/**
 * @constant WELCOME_MESSAGE_TEXT
 */
export const WELCOME_MESSAGE_TEXT =
  "Want to see something cool? Type in any of these words in the chat: “LOL”, “OMG”, “hug”, “whatever”, “heart”, “cat”, “dog”, “stars”, “cake”, “love” among other words. Try it out, will be such a fun experience ";

/**
 * @constant INVITE_USER_PER_PAGE_LIMIT
 */
export const INVITE_USER_PER_PAGE_LIMIT = 10;

export const CALL_END_STATE = {
  LOGOUT: "logout",
};

/**
 * @constant SHARED_LINK_SEARCH_PARAMS
 */
export const SHARED_LINK_SEARCH_PARAMS = {
  CALL_HASH: "call_hash",
  HOST: "host",
};

/**
 * @constant CALL_JOIN_ASK_STATUS
 */
export const CALL_JOIN_ASK_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const THEME_SETTINGS = {
  DARK: "dark",
  LIGHT: "light",
};

/**
 * @constant CALL_TIMER_STATUS
 */
export const CALL_TIMER_STATUS = {
  START: "Start",
  PAUSE: "Pause",
  RESUME: "Resume",
  CANCEL: "Cancel",
};

export const CALL_END_ANIMATION_TYPE = {
  smokeBomb: "smoke bomb",
  peaceOut: "peace out",
  NORMAL_CALL_END: "NORMAL_CALL_END",
};

/**
 * @constant MESSAGE_TYPES
 */
export const MESSAGE_TYPES = {
  WELCOME_MESSAGE: "welcome_message",
};

/**
 * @constant APP_FEATURES_CONFIG_KEYS
 */
export const APP_FEATURES_CONFIG_KEYS = {
  ANIMATIONS: "animations",
  TIMER: "timer",
};

/**
 * @constant LOGIN_SESSION_EXPIRED_AT : Number of seconds in 7 days
 */
export const LOGIN_SESSION_EXPIRED_AT = 604800;

/**
 * @constant NON_PREMIUM_CALL_END_AT : Number of seconds in 45 minutes
 */
export const NON_PREMIUM_CALL_END_AT = 2700;

/**
 * @constant IS_HIDE_VIDEO_RECORDING_FEATURE
 * To hide/unhide video recording feature
 */
export const IS_HIDE_VIDEO_RECORDING_FEATURE = true;
