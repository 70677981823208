const initialState = {
    isLoading: false
  };
  
  function loadingReducer(state = initialState, action) {
    switch (action.type) {
        
      case "SET_IS_LOADING": {
        return {
          ...state,
          isLoading: action.payload
        };
      }
      default:
        return state;
    }
  }
  
  export default loadingReducer;
  