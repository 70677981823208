import {
  SET_USER_EVENTS,
  SET_NEW_USER_EVENT,
  RESET_USER_EVENTS,
  SET_USER_EVENT_SESSION_ID,
} from "./action";

const defaultState = {
  events: {},
};

const userEventReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }

    case SET_NEW_USER_EVENT: {
      const prevState = { ...state.events };
      const { key, event } = action.payload;
      prevState[key] = event;

      return {
        ...state,
        events: prevState,
      };
    }

    case SET_USER_EVENT_SESSION_ID: {
      const prevState = Object.assign({}, state.events);
      const { key, sessionId } = action.payload;
      prevState[key].videoCallSessionId = sessionId;

      return {
        ...state,
        events: prevState,
      };
    }

    case RESET_USER_EVENTS: {
      return {
        events: {},
      };
    }

    default:
      return state;
  }
};

export default userEventReducer;
