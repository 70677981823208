/* eslint-disable */
// Constant
import action from '../action';
export const SET_LOGIN_DETAILS = 'SET_LOGIN_DETAILS';
export const LOG_OUT = 'LOG_OUT';
export const SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO';
export const ADD_FRIEND = 'ADD_FRIEND';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const RESET_INVITED_FRIENDS = 'RESET_INVITED_FRIENDS';
export const UPDATE_INVITED_FRIEND_STATUS = 'UPDATE_INVITED_FRIEND_STATUS';
export const RESET_FRIEND_LIST_UPDATION_STATUS =
  'RESET_FRIEND_LIST_UPDATION_STATUS';
export const SET_SESSION_CALL_DETAIL = 'SET_SESSION_CALL_DETAIL';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_CALL_END_ANIMATION_TYPE = 'SET_CALL_END_ANIMATION_TYPE';
export const SET_SCREEN_SHARE_MODE = 'SET_SCREEN_SHARE_MODE';
export const SET_CALL_END_MODE = 'SET_CALL_END_MODE';
export const SET_GUEST_JOINED_MODE = 'SET_GUEST_JOINED_MODE';
export const SET_ASK_JOIN_CALL_NOTIFICATIONS =
  'SET_ASK_JOIN_CALL_NOTIFICATIONS';
export const SET_ANONYMOUS_LOGIN = 'SET_ANONYMOUS_LOGIN';
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const SET_RADIO_BUTTON_VALUE = 'SET_RADIO_BUTTON_VALUE';
export const RECENT_GIFS_LIST = 'RECENT_GIFS_LIST';
export const SET_TIMER_DATA = 'SET_TIMER_DATA';
export const SET_RESUME_TIMER_VALUE = 'SET_RESUME_TIMER_VALUE';
export const IS_TIMER_STARTED = 'IS_TIMER_STARTED';
export const UPDATE_PREMIUM_STATUS = 'UPDATE_PREMIUM_STATUS';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const setLoginDetails = (data) =>
  action({
    type: SET_LOGIN_DETAILS,
    data,
  });

export const setOrUnsetCurrentCall = (data) =>
  action({
    type: 'SET_USER_CALL',
    data,
  });

export const setLogOut = () => ({
  type: 'SET_LOG_OUT',
});

export const setCount = (data) => ({
  type: 'SET_COUNT',
  data,
});

export const setCallCount = (data) => ({
  type: 'SET_CALL_COUNT',
  data,
});

/**
 * @param {string} photoUrl
 */
export const setProfilePhoto = (photoUrl) =>
  action({
    type: SET_PROFILE_PHOTO,
    data: photoUrl,
  });

export const addFriends = (data) =>
  action({
    type: ADD_FRIEND,
    data,
  });

/**
 *
 * @param {Array} data : Array of objects {userId, status}
 */
export const resetFriendList = (data) =>
  action({
    type: RESET_INVITED_FRIENDS,
    data,
  });

/**
 *
 * @param {object} data
 *
 * userId, status
 */
export const updateFriendStatus = (data) =>
  action({
    type: UPDATE_INVITED_FRIEND_STATUS,
    data,
  });

/**
 *
 * @param {boolean} data
 */
export const resetFriendListUpdationStatus = (data) =>
  action({
    type: RESET_FRIEND_LIST_UPDATION_STATUS,
    data,
  });

export const setUserNotifications = (data) =>
  action({
    type: SET_NOTIFICATIONS,
    data,
  });

export const setUserCallNotifications = (data) =>
  action({
    type: 'SET_CALL_NOTIFICATIONS',
    data,
  });

export const setSessionCallDetail = (data) =>
  action({
    type: 'SET_SESSION_CALL_DETAIL',
    data,
  });

/**
 * @param {string} name
 */
export const setUsername = (name) =>
  action({
    type: SET_USERNAME,
    data: name,
  });

/**
 * @param {string} type
 */
export const setCallEndAnimationType = (type) =>
  action({
    type: SET_CALL_END_ANIMATION_TYPE,
    data: type,
  });

/**
 * @param {boolean} isShare
 */
export const setScreenShareMode = (isShare) =>
  action({
    type: SET_SCREEN_SHARE_MODE,
    data: isShare,
  });

/**
 * @param {string} mode
 */
export const setCallEndMode = (mode) =>
  action({
    type: SET_CALL_END_MODE,
    data: mode,
  });

/**
 * To set if guest is joined or not
 * @param {object} data
 * {
 *   @param {boolean} status
 *   @param {string} username
 *   @param {string} userId
 * }
 */
export const setGuestJoinedMode = (data) =>
  action({
    type: SET_GUEST_JOINED_MODE,
    data,
  });

/**
 * To set if guest is joined or not
 * @param {object} data
 * {
 *   @param {boolean} status
 *   @param {string} username
 *   @param {string} userId
 * }
 */
export const setUserAskJoinCallNotifications = (data) =>
  action({
    type: SET_ASK_JOIN_CALL_NOTIFICATIONS,
    data,
  });

/**
 * To set if guest is logined Anonymously
 * @param {object} data
 * {
 *   @param {boolean} isAnonymous
 * }
 */
export const setAnonymousLogin = (data) =>
  action({
    type: SET_ANONYMOUS_LOGIN,
    data,
  });

// to open or close feedback modal
export const openFeedBackModal = (data) =>
  action({
    type: OPEN_FEEDBACK_MODAL,
    data,
  });

// to set the value of radio butoon for theme changing
export const changeActiveRadioButton = (data) =>
  action({
    type: SET_RADIO_BUTTON_VALUE,
    data,
  });

// set recent chat symbols
export const setRecentChatSpecialSymbol = (data) => ({
  type: RECENT_GIFS_LIST,
  payload: data,
});

export const setTimerData = (data) => ({
  type: SET_TIMER_DATA,
  payload: data,
});

export const setResumeTimerValue = (data) => ({
  type: SET_RESUME_TIMER_VALUE,
  payload: data,
});

export const setIsTimerStarted = (data) => ({
  type: IS_TIMER_STARTED,
  payload: data,
});

export const updatePremiumAccountStatus = (data) => ({
  type: UPDATE_PREMIUM_STATUS,
  payload: data,
});

export const updateCurrentUserDetails = (data) => ({
  type: UPDATE_CURRENT_USER,
  payload: data,
});
