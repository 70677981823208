import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { connect } from "react-redux";

import { CALL_END_OPTIONS } from "../../../../components/shared/constants";
import { CallIcon } from "../../../../components/svgIcons";
import cx from "classnames";
import callEndIcon from "../../../../assets/images/icons/callDisconnect/call-end.png";

export const CallDisconnectView = (props) => {
  const { isCallEnabled } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prevState) => !prevState);

  const onSelect = (key) => {
    props.onSelect(key);
  };

  const callEndOptionList = Object.keys(CALL_END_OPTIONS);

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={toggle} className="callDisPopUp">
        <DropdownToggle
          disabled={!isCallEnabled}
          title={
            isCallEnabled
              ? "Click to disconnect your video meeting."
              : "Disconnect is disabled."
          }
          className={cx("btn-icon call", {
            "call-btn-disable": !isCallEnabled,
          })}
        >
          <CallIcon noBackground />
        </DropdownToggle>
        <DropdownMenu>
          {callEndOptionList.map((key) => {
            const option = CALL_END_OPTIONS[key];
            let animationUrl = "";
            let title = "";
            if (props.callEndAnimations) {
              const animationTypeDetail = props.callEndAnimations[key];
              if (animationTypeDetail) {
                animationUrl = animationTypeDetail.animationUrl;
                title = animationTypeDetail.title;
              }
            }
            if (key === "NORMAL_CALL_END") {
              animationUrl = callEndIcon;
              title = CALL_END_OPTIONS.NORMAL_CALL_END;
            }
            if (!animationUrl || !title) {
              return null;
            }

            return (
              <button key={key} onClick={() => onSelect(key)}>
                <img
                  src={animationUrl}
                  width="50px"
                  height="50px"
                  alt={option}
                />
                {title}
              </button>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    callEndAnimations: state.callEndAnimations.animations,
  };
};

export default connect(mapStateToProps)(CallDisconnectView);
