/* eslint-disable */

// Constant
export const SET_USER_EVENT_CALL_LOCAL_STREAM =
  "SET_USER_EVENT_CALL_LOCAL_STREAM";
export const SET_USER_EVENT_CALL_DETAIL = "SET_USER_EVENT_CALL_DETAIL";
export const RESET_USER_EVENT_CALL_DETAIL = "RESET_USER_EVENT_CALL_DETAIL";

/**
 * @param {Object} payload : video stream object
 */
export const setCallLocalStream = (payload) => ({
  type: SET_USER_EVENT_CALL_LOCAL_STREAM,
  payload: payload,
});

/**
 * @param {Object} payload 
 */
export const setEventCallDetail = (payload) => ({
  type: SET_USER_EVENT_CALL_DETAIL,
  payload: payload,
});

/**
 */
export const resetCallData = () => ({
  type: RESET_USER_EVENT_CALL_DETAIL,
});
