import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from 'reactstrap';
import { withFirebase } from '../../../../firebase/context';
import InviteFriends from './inviteFriends';
import { IncommingCallModal, AllowJoinCallModal } from '../../../modal';
import Alert from '../../../shared/common/alert';
import { VideoCameraIcon } from '../../../../components/svgIcons';
import * as loginAction from '../../../../redux/login/action';
import * as adminEventsAction from '../../../../redux/admin-events/action';
import * as userEventsAction from '../../../../redux/user-events/action';
import * as chatDetailAction from '../../../../redux/user-chat/action';
import * as chatSepcialSymbolsAction from '../../../../redux/chat-special-symbols/action';
import * as screenAnimationAction from '../../../../redux/screen-animations/action';
import * as callEndAnimationAction from '../../../../redux/call-end-animations/action';
import avatarIcon from '../../../../assets/images/icons/avatar.svg';
import closeIcon from '../../../../assets/images/icons/close.svg';
import Tooltip from '../../../tooltip';
import AlertComponent from '../../../modal/alertModal';
import {
  CALL_END_STATE,
  CALL_JOIN_ASK_STATUS,
} from '../../../shared/constants';
import PersonName from './personName';
import { ContactForm } from '../../../form';
import * as loaderAction from '../../../../redux/loader/action';
import GuestCallPasswordModal from '../../../modal/guestCallPasswordModal/index';
import ChainIcon from '../../../../assets/images/chain.png';
import SettingsModal from '../../../modal/settingsModal/index';
import { SCHEMA } from '../../../../components/shared/constants';

class SubHeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenCallModal: false,
      isOpenProfileDropdown: false,
      callNotifications: {},
      isOpenContactUsModal: false,
      isOpenEmojiModal: false,
      isOpenAllowCallJoinModal: false,
      askJoinCallNotifications: {},
      isOpenSettingsModal: false,
    };

    this.onContactUs = this.onContactUs.bind(this);
    this.onSettings = this.onSettings.bind(this);
    this.closeSettingsModal = this.closeSettingsModal.bind(this);
  }

  componentDidMount() {
    let {
      userInfo: { userId, call_notifications, askToJoinCallNotifications },
    } = this.props;
    let { callNotifications, askJoinCallNotifications } = this.state;

    let propsCallNotifications = Object.keys(call_notifications || {}).length;
    let stateCallNotifications = Object.keys(callNotifications || {}).length;
    if (propsCallNotifications !== stateCallNotifications) {
      this.setState({
        isOpenCallModal: true,
        callNotifications: call_notifications,
      });
    }

    this.props.setSessionCallDetail({
      id: sessionStorage.getItem('currentCallId'),
      call_status: sessionStorage.getItem('call_status'),
    });

    const pendingNotifications = {};
    Object.keys(askToJoinCallNotifications || {}).forEach((key) => {
      const notification = askToJoinCallNotifications[key];
      if (
        notification.status === CALL_JOIN_ASK_STATUS.PENDING &&
        notification.hostId === userId
      ) {
        pendingNotifications[key] = notification;
      }
    });

    let askJoinCallNotification = Object.keys(pendingNotifications || {})
      .length;

    let askJoinCallNotificationList = Object.keys(
      askJoinCallNotifications || {}
    ).length;

    if (askJoinCallNotification !== askJoinCallNotificationList) {
      this.setState({
        isOpenAllowCallJoinModal: true,
        askJoinCallNotifications: askToJoinCallNotifications,
      });
    }
    this.getAndSetEvents();
    this.checkAllOnEvents(userId);
    this.updateCurrentUserState(userId);
    this.getAnimationDetail();
    this.getRecentChatSpecialSymbol(userId);
    this.getAndSetChatSpecialSymbols();
  }

  onSettings() {
    this.setState({ isOpenSettingsModal: !this.state.isOpenSettingsModal });
  }

  getAndSetEvents = () => {
    this.props.firebase.refreshData('events', (res) => {
      if (res) {
        this.props.setEvents(res);
      }
    });
  };

  getAnimationDetail() {
    this.props.firebase.getScreenAnimations((res) => {
      this.props.updateScreenAnimation(res);
    });

    this.props.firebase.getCallEndAnimations((res) => {
      this.props.updateCallEndAnimation(res);
    });
  }

  checkAllOnEvents = async (userId) => {
    const { firebase } = this.props;

    firebase.getUserUnreadNotifications(userId, (res) => {
      if (!!res && Object.keys(res).length > 0) {
        this.props.setCount(true);
        this.props.setUserNotifications(res);
      }
    });

    firebase.getUserUnreadCallNotifications(userId, (res) => {
      if (!!res && Object.keys(res).length > 0) {
        this.props.setCallCount(true);
        this.props.setUserCallNotifications(res);
      } else {
        this.props.setUserCallNotifications({});
      }
    });

    firebase.getUserUnreadAskToJoinCallNotifications(userId, (res) => {
      if (!!res && Object.keys(res).length > 0) {
        this.props.setUserAskJoinCallNotifications(res);
      } else {
        this.props.setUserAskJoinCallNotifications({});
      }
    });

    firebase.checkIfMergedCall(userId, (res) => {
      if (!!res && Object.keys(res).length > 0) {
        const {
          userInfo: { currentCallId },
        } = this.props;

        if (currentCallId && currentCallId !== '') {
          const keys = Object.keys(res);
          const indexOfCurrentCal = keys.findIndex(
            (key) => key === currentCallId
          );

          if (indexOfCurrentCal !== -1) {
            const values = Object.values(res);
            let newCurrentCall = values[indexOfCurrentCal];

            let obj = {
              id: newCurrentCall,
              call_status: 'DIRECT_CALL',
            };
            firebase.removeMergedObj(userId, currentCallId);
            this.props.setOrUnsetCurrentCall(obj);
            this.props.history.push(`/call/${newCurrentCall}`, {
              isCallSession: true,
            });

            window.location.reload();
          }
        }
      }
    });
  };

  updateCurrentUserState = (userId) => {
    //To update friend list
    this.props.firebase.getUserFriendsData(userId, (val) => {
      this.props.updateFriendStatus(val || {});
    });

    this.props.firebase.getCurrentUserDetail(userId, (val) => {
      if(val) {
        this.props.updateCurrentUserDetails({
          username: val.username,
          profilePhotoUrl: val.photoUrl,
          role: val.role,
          isPremium: val.isPremium,
        });
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      userInfo: { userId, call_notifications, askToJoinCallNotifications },
    } = nextProps;

    let propsCallNotifications = Object.keys(call_notifications || {}).length;

    let stateCallNotifications = Object.keys(prevState.callNotifications)
      .length;

    if (propsCallNotifications !== stateCallNotifications) {
      return {
        isOpenCallModal: true,
        callNotifications: nextProps.userInfo.call_notifications,
      };
    }

    const pendingNotifications = {};
    Object.keys(askToJoinCallNotifications || {}).forEach((key) => {
      const notification = askToJoinCallNotifications[key];
      if (
        notification.status === CALL_JOIN_ASK_STATUS.PENDING &&
        notification.hostId === userId
      ) {
        pendingNotifications[key] = notification;
      }
    });
    let askJoinCallNotification = Object.keys(pendingNotifications || {})
      .length;
    let askJoinCallNotificationList = Object.keys(
      prevState.askJoinCallNotifications || {}
    ).length;

    if (askJoinCallNotification !== askJoinCallNotificationList) {
      return {
        isOpenAllowCallJoinModal: true,
        askJoinCallNotifications: askToJoinCallNotifications,
      };
    }

    return {};
  }

  componentWillUnmount() {
    const {
      userInfo: { userId },
    } = this.props;
    this.clearUserIncomingCalls(userId);
    //ToDo: clear ask notification when logout
  }
  closeSettingsModal() {
    this.setState({ isOpenSettingsModal: false });
  }

  clearUserIncomingCalls = async (userId) => {
    const { callNotifications } = this.state;
    const callNotficationList = Object.keys(callNotifications);

    callNotficationList.forEach(async (key, index) => {
      const notification = callNotifications[key];
      if (notification.status === 'PENDING') {
        await this.props.firebase.checkCallNotificationStatus(
          key,
          userId,
          async (res) => {
            if (res && res.status === 'PENDING') {
              await this.updateNotificationsData(index, 'REJECTED');
              await this.props.firebase.removeUserFromInvitedCall(
                notification.callId,
                userId
              );
            }
          }
        );
      }
    });
  };

  updateNotificationsData = async (index, status) => {
    let props = this.props;
    const { callNotifications } = this.state;
    const { userId } = props.userInfo;
    let keys = Object.keys(callNotifications);
    let selectedKey = keys[index];

    let obj = {
      status,
    };

    await props.firebase
      .updateUserCallNotificationData('users', userId, selectedKey, obj)
      .then(() => {})
      .catch(() => {});
  };

  setCallModal = (value) => {
    this.setState({
      isOpenCallModal: value,
    });
  };

  setOpenProfilePhotoModal = (value) => {
    this.props.setOpenProfilePhotoModal(value);
  };

  onLogOut = () => {
    let props = this.props;
    const {
      userInfo: { currentCallId },
      loaderView: { isLoading },
    } = props;
    if (currentCallId) {
      this.props.setCallEndMode(CALL_END_STATE.LOGOUT);
      this.props.setIsLoading(true);

      setTimeout(() => {
        if (!isLoading) {
          this.handleLogout();
        } else {
          this.logoutInterval = setInterval(() => {
            const {
              loaderView: { isLoading },
            } = props;
            if (!isLoading) {
              this.handleLogout();
              clearInterval(this.logoutInterval);
            }
          }, 2000);
        }
      }, 3000);
    } else {
      this.handleLogout();
    }
  };

  handleLogout() {
    let props = this.props;
    this.resetScreenShareMode();
    props.firebase.onSignOut();
    props.resetEvents();
    props.resetUserEvents();
    props.resetChatSpecialSymbols();
    props.logout();
  }

  async resetScreenShareMode() {
    const {
      userInfo: { currentCallId, isScreenSharingOn },
    } = this.props;
    if (currentCallId) {
      if (isScreenSharingOn) {
        this.props.setScreenShareMode(false);
        this.props.firebase
          .setScreenShareMode(currentCallId, {
            startScreenShare: false,
          })
          .then(() => {})
          .catch(() => {
            Alert(400, 'Unable to stop sharing your screen.');
          });
      }
    }
  }

  onContactUs() {
    this.setState({ isOpenContactUsModal: !this.state.isOpenContactUsModal });
  }

  onVideoIcon = () => {
    const {
      userInfo: { userId, currentCallId },
      history,
      firebase,
    } = this.props;

    if (currentCallId && currentCallId !== '') {
      history.push(`/call/${currentCallId}`, {
        isCallSession: true,
      });
    } else {
      let userObj = {
        userId: userId,
        status: 'ACCEPTED',
      };

      let obj = {
        timestamp: Date.now(),
        start: true,
        creatorId: userId,
        startedAt: Date.now()
      };
      obj.invited = {};
      obj.invited[userId] = userObj;
      firebase
        .addUserCall('calls', obj)
        .then((resp) => {
          history.push(`/call/${resp.key}`, { isCallSession: true });
          let obj = {
            id: resp.key,
            call_status: 'DIRECT_CALL',
          };
          this.props.setOrUnsetCurrentCall(obj);
          this.props.setUserChatDetail({});
        })
        .catch(() => {
          Alert(400, 'Unable to start call.');
        });
    }
  };

  dropdownOpenToggle = () => {
    this.setState({
      isOpenProfileDropdown: !this.state.isOpenProfileDropdown,
    });
  };
  openModal = () => {
    this.setState({
      isOpenEmojiModal: !this.state.isOpenEmojiModal,
    });
  };

  reduceNotification = (key, status) => {
    let allNewNotifications = Object.assign({}, this.state.callNotifications);
    delete allNewNotifications[key];
    this.props.setUserCallNotifications(allNewNotifications);
    this.setState({
      callNotifications: allNewNotifications,
    });
  };

  getRecentChatSpecialSymbol = (userId) => {
    this.props.firebase.getRecentChatSymbol(userId, (res) => {
      const recentGifs = Object.values(res || {}).sort(
        (a, b) => b.timeStamp - a.timeStamp
      );
      this.props.setRecentChatSpecialSymbol(recentGifs);
    });
  };

  getAndSetChatSpecialSymbols = () => {
    this.props.firebase.refreshChatSymbols(
      SCHEMA.CHAT_SPECIAL_SYMBOLS,
      (res) => {
        if (res) {
          this.props.setChatSpecialSymbols(res);
        }
      }
    );
  };

  render() {
    const {
      isOpenCallModal,
      isOpenProfileDropdown,
      callNotifications,
      isOpenContactUsModal,
      isOpenEmojiModal,
      isOpenAllowCallJoinModal,
      askJoinCallNotifications,
      isOpenSettingsModal,
    } = this.state;
    const {
      userInfo: {
        call_notifications,
        notifications,
        profilePhotoUrl,
        username,
        email,
        userId,
        currentCallId,
      },
    } = this.props;
    let user_callNotifications = [];
    let user_notifications = [];
    if (call_notifications && Object.keys(call_notifications).length > 0) {
      user_callNotifications = Object.values(call_notifications);
    }

    if (notifications && Object.keys(notifications).length > 0) {
      user_notifications = Object.values(notifications);
    }

    let askToJoinNotifications = [];
    if (Object.keys(askJoinCallNotifications || {}).length > 0) {
      askToJoinNotifications = Object.values(askJoinCallNotifications);
    }

    return (
      <>
        <div className=" d-flex align-items-center">
          <InviteFriends />
          <div className="btnWrapper">
            <GuestCallPasswordModal
              isHost
              callId={currentCallId}
              hostId={userId}
              isOpen={isOpenEmojiModal}
              onClose={this.openModal}
            >
              {isOpenAllowCallJoinModal && !!askToJoinNotifications.length && (
                <>
                  <div style={{ zIndex: 1 }} class="modal-backdrop fade show" />
                  <AllowJoinCallModal notifications={askToJoinNotifications} />
                </>
              )}
            </GuestCallPasswordModal>
            {!isOpenEmojiModal &&
              isOpenAllowCallJoinModal &&
              !!askToJoinNotifications.length && (
                <>
                  <div style={{ zIndex: 1 }} class="modal-backdrop fade show" />
                  <AllowJoinCallModal notifications={askToJoinNotifications} />
                </>
              )}
            {currentCallId && (
              <button onClick={() => this.openModal()} className="chain-button">
                <img src={ChainIcon} alt="video-link-copy" />
              </button>
            )}
            <Link className="events mx-1" to="/events">
              <i className="fas fa-calendar-plus" />
            </Link>

            <button
              onClick={() => this.onVideoIcon()}
              className="btn-icon-important px-0 camera mx-3 noBoxShadow"
            >
              <VideoCameraIcon />
            </button>
            {isOpenCallModal && !!user_callNotifications.length && (
              <IncommingCallModal
                reduceNotification={this.reduceNotification}
                close={() => this.setCallModal(false)}
                user_callNotifications={user_callNotifications}
                callNotifications={callNotifications}
              />
            )}

            <Link className="notifications mx-1" to="/notifications">
              {!!user_notifications.length && (
                <span>{user_notifications.length}</span>
              )}
              <i className="fas fa-bell" />
            </Link>
          </div>
          <Dropdown
            isOpen={isOpenProfileDropdown}
            toggle={() => this.dropdownOpenToggle()}
          >
            <DropdownToggle className="dropDownBtn">
              <div className="avatar ml-2 ml-md-3 d-flex align-items-center">
                <img src={profilePhotoUrl || avatarIcon} alt="Bonfire" />
              </div>
            </DropdownToggle>
            <DropdownMenu right className="text-center">
              <div>
                <div className="profile">
                  <img src={profilePhotoUrl || avatarIcon} alt="Bonfire" />
                  <button
                    className="btn-icon p-0"
                    onClick={() => this.setOpenProfilePhotoModal(true)}
                  >
                    <i className="fas fa-camera" />
                  </button>
                </div>
                <PersonName username={username} userId={userId} />
                <p className="profile-email" id="d-profile-email">
                  {email}
                </p>
                <Tooltip target="d-profile-email" placement="top">
                  {email}
                </Tooltip>
              </div>
              <DropdownItem onClick={() => this.setOpenProfilePhotoModal(true)}>
                Update Profile Pic
              </DropdownItem>
              <DropdownItem className="logout" onClick={() => this.onLogOut()}>
                Logout
              </DropdownItem>
              <DropdownItem
                className="contact-us"
                onClick={() => this.onContactUs()}
              >
                Contact us
              </DropdownItem>
              <DropdownItem onClick={() => this.onSettings()}>
                Settings
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <AlertComponent />
        </div>
        <Modal
          isOpen={isOpenContactUsModal}
          toggle={this.onContactUs}
          centered
          className="isOpenContactUsModal"
        >
          <ModalBody>
            <button onClick={this.onContactUs} className="modal-close">
              <img src={closeIcon} alt="close" />
            </button>
            <ContactForm onClose={this.onContactUs} />
          </ModalBody>
        </Modal>
        <SettingsModal
          isOpen={isOpenSettingsModal}
          onClose={this.closeSettingsModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginReducer,
  loaderView: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (data) => dispatch(loginAction.setLogOut(data)),
    resetEvents: () => dispatch(adminEventsAction.resetEvents()),
    resetUserEvents: () => dispatch(userEventsAction.resetUserEvents()),
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setUserChatDetail: (data) =>
      dispatch(chatDetailAction.setUserChatDetail(data)),
    setUserCallNotifications: (data) =>
      dispatch(loginAction.setUserCallNotifications(data)),
    setUserNotifications: (data) =>
      dispatch(loginAction.setUserNotifications(data)),
    setCount: (data) => dispatch(loginAction.setCount(data)),
    setCallCount: (data) => dispatch(loginAction.setCallCount(data)),
    setEvents: (data) => dispatch(adminEventsAction.setEvents(data)),
    setSessionCallDetail: (data) =>
      dispatch(loginAction.setSessionCallDetail(data)),
    updateFriendStatus: (data) =>
      dispatch(loginAction.updateFriendStatus(data)),
    resetChatSpecialSymbols: () =>
      dispatch(chatSepcialSymbolsAction.resetSpecialSymbols()),
    setChatSpecialSymbols: (res) =>
      dispatch(chatSepcialSymbolsAction.setSpecialSymbols(res)),
    updateScreenAnimation: (res) =>
      dispatch(screenAnimationAction.updateScreenAnimation(res)),
    updateCallEndAnimation: (res) =>
      dispatch(callEndAnimationAction.updateCallEndAnimation(res)),
    setScreenShareMode: (data) =>
      dispatch(loginAction.setScreenShareMode(data)),
    setCallEndMode: (data) => dispatch(loginAction.setCallEndMode(data)),
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
    setUserAskJoinCallNotifications: (data) =>
      dispatch(loginAction.setUserAskJoinCallNotifications(data)),
    setRecentChatSpecialSymbol: (data) =>
      dispatch(loginAction.setRecentChatSpecialSymbol(data)),
    updateCurrentUserDetails: (data) =>
      dispatch(loginAction.updateCurrentUserDetails(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(SubHeaderComponent);
