import React from "react";
import { SignUpForm } from "../../components/form";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

// class SignUp extends Component {
const SignUp = (props) => {
  return (
    <div className="main-form-wrapper">
      <div className="w-100">
        <Card>
          <CardBody>
            <div className="p-sm-4">
              <center>
                <h2 className="title">Bonfire</h2>
                <p className="subText">
                  Sign up to connect
                  <br />
                  with your friends
                </p>
              </center>
              <SignUpForm {...props} />
              <center>
                <p className="mt-3 px-4 mb-0">
                  By sigining up, you agree to our Terms, Data Policy and
                  Cookies Policy.
                </p>
              </center>
            </div>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardBody>
            <p className="text-center mb-0 linkText">
              Have an account? <Link to="/">Log in</Link>
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default SignUp;
