import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import fairy from "../../../assets/images/link-password-icons/fairy.png";
import lion from "../../../assets/images/link-password-icons/lion.png";
import monkey from "../../../assets/images/link-password-icons/monkey.png";
import panda from "../../../assets/images/link-password-icons/panda.png";
import shark from "../../../assets/images/link-password-icons/shark.png";
import unicorn from "../../../assets/images/link-password-icons/unicorn.png";
import dog from "../../../assets/images/link-password-icons/dog.png";
import ghost from "../../../assets/images/link-password-icons/ghost.png";
import dinosaur from "../../../assets/images/link-password-icons/dinosaur.png";
import copyIcon from "../../../assets/images/icons/copy.png";
import sha256 from "sha256";
import Alert from "../../shared/common/alert";
import {
  SHARED_LINK_SEARCH_PARAMS,
  CALL_JOIN_ASK_STATUS,
} from "../../shared/constants";

let emojiList = [
  { image: lion, name: "lion" },
  { image: monkey, name: "monkey" },
  { image: dog, name: "dog" },
  { image: ghost, name: "ghost" },
  { image: dinosaur, name: "dinosaur" },
  { image: unicorn, name: "unicorn" },
  { image: panda, name: "panda" },
  { image: shark, name: "shark" },
  { image: fairy, name: "fairy" },
];

const GuestCallPasswordModal = (props) => {
  const {
    isOpen,
    isHost,
    name = "",
    onClose,
    onAskToJoin,
    isGuestMode,
    hostId,
    callId,
    notifications,
    onRejectStatus,
    onAcceptStatus,
    children,
    firebase,
    onJoinByHost,
    userId,
  } = props;

  const [emoji, setEmoji] = useState([]);
  const [value, setValue] = useState("");
  const [username, setUsername] = useState(name);
  const [hostInfo, setHostInfo] = useState(null);
  const [isEnableAskToJoin, setEnableAskToJoin] = useState(true);
  const [requestStatus, setRequestStatus] = useState("");

  let url = "";

  useEffect(() => {
    const callHash = window.location.search;
    const callInfo = window.location.pathname;
    if (callHash) {
      try {
        const queryParams = callHash.split("&");
        const hostHash = queryParams[0].split(
          "?" + SHARED_LINK_SEARCH_PARAMS.CALL_HASH + "="
        )[1];
        const id = queryParams[1].split(
          SHARED_LINK_SEARCH_PARAMS.HOST + "="
        )[1];

        setHostInfo({
          id: id || "",
          callId: callInfo ? callInfo.split("/call/")[1] || "" : "",
          hash: hostHash || "",
        });
      } catch (err) {
        Alert(400, "Hash link not found!!");
      }
    }
  }, []);

  useEffect(() => {
    const notificationKey = hostInfo
      ? Object.keys(notifications || {}).find(
          (key) => notifications[key].callId === hostInfo.callId
        )
      : "";
    const notification = notificationKey
      ? notifications[notificationKey]
      : null;

    if (notification) {
      if (notification.isRead) {
        if (notification.status === CALL_JOIN_ASK_STATUS.REJECTED) {
          setRequestStatus(
            "Your host denied your request to join this call. Please retry."
          );
          onRejectStatus(notification, () => {
            setTimeout(() => {
              setEnableAskToJoin(true);
              setRequestStatus("");
            }, 5000);
          });
        } else if (notification.status === CALL_JOIN_ASK_STATUS.ACCEPTED) {
          onAcceptStatus(notification);
        }
      } else {
        setRequestStatus("Waiting for host to allow you to join this call...");
        autoReject(notification);
        setEnableAskToJoin(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const autoReject = (notification) => {
    if (notification.status === "PENDING") {
      const createAt = new Date(notification.timestamp);
      const expiredAt = new Date(
        createAt.setMinutes(createAt.getMinutes() + 1)
      );

      const currentTime = new Date();
      let waitTime = expiredAt.getTime() - currentTime.getTime();
      if (waitTime <= 0) {
        waitTime = 0;
      }

      setTimeout(async () => {
        await firebase.checkAskToJoinCallNotificationStatus(
          notification.userId,
          notification.hostId,
          async (res) => {
            if (res && res.status === "PENDING") {
              onRejectStatus(notification, () => {
                setTimeout(() => {
                  setEnableAskToJoin(true);
                  setRequestStatus("");
                }, 1000);
              });
            }
          }
        );
      }, waitTime);
    }
  };

  const selectedEmoji = (item) => {
    if (!!requestStatus) {
      return;
    }

    let array = emoji.slice();

    if (array.length <= 2) {
      array.push(item);
      setEmoji(array);

      if (array.length === 3) {
        array.forEach((item) => {
          url = url + sha256(item.name);
        });

        if (isHost) {
          setValue(url);
        } else {
          if (hostInfo) {
            if (hostInfo.hash !== url) {
              Alert(
                400,
                "Icons selected are not same as selected by your host!!"
              );
            } else {
              setValue(url);
            }
          }
        }
      }
    }
  };

  const onRemoveSelected = (index) => {
    const currentPasswordArr = emoji.slice();
    currentPasswordArr.splice(index, 1);
    setEmoji(currentPasswordArr);
    setValue("");
  };

  const handleOnAskToJoinCall = () => {
    onAskToJoin({
      guestUsername: username.trim(),
      hostId: hostInfo ? hostInfo.id : "",
      callId: hostInfo ? hostInfo.callId : "",
    });
    setEnableAskToJoin(false);
  };

  const handleJoinCall = () => {
    onJoinByHost(null, true);
  };

  const handleOnResetState = () => {
    url = "";
    setEmoji([]);
    setValue("");
    setHostInfo(null);
    setEnableAskToJoin(false);
    setRequestStatus("");
  };

  const handleOnClose = () => {
    if (onClose) {
      handleOnResetState();
      onClose();
    }
  };

  let linkToShare = "";
  if (isHost && value) {
    linkToShare =
      window.location.origin +
      "/call/" +
      callId +
      "?" +
      SHARED_LINK_SEARCH_PARAMS.CALL_HASH +
      "=" +
      value +
      "&" +
      SHARED_LINK_SEARCH_PARAMS.HOST +
      "=" +
      hostId;
  }

  const isUsernameRequired = isGuestMode;
  const isJoinEnabled = isUsernameRequired
    ? !!username.trim() && !!value.trim()
    : !!value.trim();
  const isShowUsername = isUsernameRequired && !!value.trim();
  const isHostJoining = hostInfo ? hostInfo.id === userId : false;

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="iconSelection"
      toggle={handleOnClose}
    >
      <ModalBody
        className={`text-center position-relative ${isHost ? "py-5" : ""}`}
      >
        {onClose && (
          <button
            title="Close"
            style={{ top: 15, right: 15 }}
            className="close position-absolute"
            onClick={handleOnClose}
          >
            <i className="fas fa-times" />
          </button>
        )}
        <h3 className="mb-3 title">Select 3 icons</h3>
        <div className="dec">
          {isHost ? (
            <>
              <p>Your guest will need to enter the same icons</p>
              <p>
                when they recieve the invitation order to access your channel
              </p>
            </>
          ) : (
            <p>
              In order to access the call, select the same 3 icons as your Host
              selected
            </p>
          )}
        </div>
        <div className="mx-auto px-3 iconsListWrapper">
          <Row className="iconsList">
            {emojiList.map((item) => (
              <Col key={item.name} sm={4} className="text-center">
                <img
                  src={item.image}
                  alt={item.name}
                  onClick={() => selectedEmoji(item)}
                  className="password-icon"
                />
              </Col>
            ))}
          </Row>
        </div>
        <div className="selectedIconsWrapper mx-auto mt-4 mb-3">
          <div className="selectedIcons">
            <p className="pass">Pass:</p>
            {emoji.map((item, index) => (
              <div key={index} className="password-icon-wrapper">
                <img src={item.image} alt="" className="image-width" />
                <i
                  className="fas fa-times"
                  onClick={() => onRemoveSelected(index)}
                />
              </div>
            ))}
          </div>
        </div>
        {isHost ? (
          <CopyToClipboard
            text={linkToShare}
            onCopy={() => {
              if (linkToShare) {
                Alert(200, "Copied to clipboard!!");
              }
            }}
          >
            <div
              title={linkToShare ? "Click to copy link" : ""}
              className={`link-border py-2 pl-3 pr-2  d-flex align-items-center ${
                linkToShare ? "active" : ""
              }`}
            >
              <p
                className={`mb-0  ${
                  linkToShare ? "text-primary" : "text-secondary"
                }`}
              >
                {linkToShare || "Select 3 icons for password..."}
              </p>
              <div>
                <img src={copyIcon} alt="copy" />
              </div>
            </div>
          </CopyToClipboard>
        ) : (
          <div className="join">
            {isShowUsername && (
              <FormGroup>
                <Input
                  type="text"
                  value={username}
                  placeholder="Enter your username"
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={!!requestStatus || !!name}
                />
              </FormGroup>
            )}
            {isHostJoining ? (
              <Button
                color={isJoinEnabled ? "primary" : "secondary"}
                onClick={handleJoinCall}
                disabled={!isJoinEnabled}
              >
                Join
              </Button>
            ) : requestStatus ? (
              <p>{requestStatus}</p>
            ) : (
              <Button
                color={
                  isJoinEnabled && isEnableAskToJoin ? "primary" : "secondary"
                }
                onClick={handleOnAskToJoinCall}
                disabled={!isJoinEnabled || !isEnableAskToJoin}
              >
                Ask to join
              </Button>
            )}
          </div>
        )}
        {children}
      </ModalBody>
    </Modal>
  );
};

export default GuestCallPasswordModal;
