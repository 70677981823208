import React from "react";

export const CameraIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 42 31.18"
  >
    <path d="M40.41,3.18H29.8l-2-2.8A.91.91,0,0,0,27.05,0H15a.91.91,0,0,0-.75.38l-2,2.8H8.06V2.55a.43.43,0,0,0-.43-.43H3.28a.43.43,0,0,0-.43.43v.63H1.59A1.59,1.59,0,0,0,0,4.77V29.59a1.59,1.59,0,0,0,1.59,1.59H40.41A1.59,1.59,0,0,0,42,29.59V4.77A1.59,1.59,0,0,0,40.41,3.18ZM21.51,25.84a8.68,8.68,0,1,1,8.68-8.68A8.67,8.67,0,0,1,21.51,25.84ZM36.12,10.59a1.33,1.33,0,1,1,1.32-1.33A1.33,1.33,0,0,1,36.12,10.59Z" />
    <circle cx="21.51" cy="17.16" r="6.57" />
  </svg>
);

export const VideoCameraIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 -87 452 452"
    fill="currentColor"
  >
    <path d="m452 25.121094v227.820312l-121.878906-66.550781h-1.210938v-94.71875h1.210938zm0 0" />
    <path d="m328.910156 186.390625v51.03125c0 22.089844-17.910156 40-40 40h-248.910156c-22.089844 0-40-17.910156-40-40v-197.421875c0-22.089844 17.910156-40 40-40h248.910156c22.089844 0 40 17.910156 40 40zm0 0" />
  </svg>
);

export const CallIcon = ({ noBackground = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 74.12 74.12"
  >
    {!noBackground && (
      <circle
        fill="currentColor"
        className="cls-1"
        cx="37.06"
        cy="37.06"
        r="37.06"
      />
    )}
    <g className="call-icon" fill="#fff">
      <path
        className="cls-2"
        d="M19.21,17.76l8,11.12s-3,3.18-.71,6c0,0,11.12,11.47,12.53,13.06,0,0,3.7,2.82,6.7-.88l10.06,8.12s-6.41,6-14.11,2.47c-7.54-3.46-18-13.47-24.09-22.52A13.92,13.92,0,0,1,19,18Z"
      />
      <polygon points="20.35 16.82 24 13.88 32 24.71 28.39 27.81 20.35 16.82" />
      <polygon
        className="cls-2"
        points="57.06 54.12 60.14 50.59 49.73 42.23 46.62 45.82 57.06 54.12"
      />
    </g>
  </svg>
);

export const MicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      className="cls-1"
      d="M6.44,7.44a.65.65,0,0,0-.65.65v4.22a6.21,6.21,0,0,0,5.63,6.18v4.16H8.87a.58.58,0,1,0,0,1.15h6.26a.58.58,0,1,0,0-1.15H12.58V18.49a6.21,6.21,0,0,0,5.63-6.18V8.09a.65.65,0,0,0-1.3,0v4.22a4.91,4.91,0,0,1-9.82,0V8.09A.65.65,0,0,0,6.44,7.44ZM11.74.2A3.71,3.71,0,0,0,8,3.89v8.88a3.71,3.71,0,0,0,3.7,3.69h.52A3.71,3.71,0,0,0,16,12.77V3.89A3.71,3.71,0,0,0,12.26.2Z"
    />
  </svg>
);

export const TickIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 37.12 37.37"
  >
    <circle fill="currentColor" cx="18.56" cy="18.56" r="18.56" />
    <path
      fill="#fff"
      d="M26.28,9.56C25.89,9.28,16.4,20,14.71,22.07l-.3.34-.3-.35c-.71-.9-1.16-1.62-1.88-2.51-.29-.36-.61-.68-.92-1a.41.41,0,0,0-.5-.1,2.14,2.14,0,0,0-.69.21c-.08.1,0,.25,0,.41s-.67.16-1,.4c-.09.06.07.28,0,.34s-.26.13-.17.35c.77,1.89,1.92,3.49,2.85,5.41,1.47,3.06,1.75,1.15,2.18,1.52,1.31,1.13.73,1.44,3.82-2.51,1.3-1.83,8.14-10.35,9.66-12a2.69,2.69,0,0,0,.49-.67c.24-.48.26-.47-.09-.86a.49.49,0,0,0-.45-.17.43.43,0,0,1-.5-.52.4.4,0,0,0-.06-.22A6.2,6.2,0,0,0,26.28,9.56Z"
    />
  </svg>
);

export const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 18.61 18.98"
  >
    <polygon
      className="cls-1"
      points="18.61 7.68 10.81 7.68 10.81 0 7.19 0 7.19 7.68 0 7.68 0 11.3 7.19 11.3 7.19 18.98 10.81 18.98 10.81 11.3 18.61 11.3 18.61 7.68"
    />
  </svg>
);

export const CancleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 37.12 37.37"
  >
    <circle fill="currentColor" cx="18.56" cy="18.56" r="18.56" />
    <path
      fill="#fff"
      d="M25.6,7.3c-0.1,0-0.3,0.1-0.5,0.3c-2.3,2.1-4.5,4.3-6.6,6.6c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.2-0.3-0.4-0.4-0.5  c-0.8-1.3-1.7-2.6-2.5-3.8c-0.3-0.5-0.7-1-1.1-1.5c-0.2-0.2-0.4-0.2-0.6-0.2c-0.3,0.1-0.4,0.2-0.4,0.5c0,0.4-0.2,0.5-0.5,0.7  c-0.2-0.2-0.4-0.3-0.7-0.5c-0.3,0.3-0.5,0.6-0.7,0.8c-0.2,0.2-0.2,0.4-0.1,0.7c0.8,2.6,1.9,5.2,3.3,7.6c0.1,0.1,0.1,0.3,0.2,0.4  c-1.8,2.2-3.6,4.3-5.5,6.4c0.1,0.4,0.3,0.7,0.4,0.9c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2,0,0.3  c0.3,0.6,0.6,1.1,1.1,1.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.4,0.1,0.3,0.3c-0.1,0.4,0.2,0.6,0.4,0.9c0.2,0.2,0.4,0.2,0.6,0  c0.1-0.1,0.1-0.2,0.2-0.2c1.5-1.9,3-3.9,4.5-5.8c0.1-0.1,0.2-0.3,0.4-0.4c0.1,0.2,0.3,0.4,0.4,0.6c0.9,1.4,1.8,2.7,2.9,4  c0.3,0.4,0.5,0.4,0.9,0.1c0.3-0.2,0.5-0.5,0.8-0.7c0.3,0.2,0.6,0.4,0.9,0.6c0.2-0.2,0.3-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.4-0.6  c0-0.3,0.2-0.6,0.5-0.7c0.3-0.1,0.4-0.3,0.6-0.5c0.2-0.4,0.4-0.7,0.5-1.1c-1.5-1.9-3-3.9-4.6-5.8c0.2-0.2,0.3-0.4,0.4-0.5  c1.9-2.4,3.9-4.6,6-6.8c0.3-0.3,0.5-0.5,0.7-0.9c0.4-0.6,0.4-0.6-0.1-1.2c-0.2-0.2-0.3-0.3-0.6-0.3c-0.5,0-0.7-0.3-0.6-0.7  c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.4-0.4-0.5C25.9,7.4,25.8,7.3,25.6,7.3z"
    />
  </svg>
);

export const Heart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 51.997 51.997"
  >
    <path
      fill="currentColor"
      d="M51.911,16.242C51.152,7.888,45.239,1.827,37.839,1.827c-4.93,0-9.444,2.653-11.984,6.905  c-2.517-4.307-6.846-6.906-11.697-6.906c-7.399,0-13.313,6.061-14.071,14.415c-0.06,0.369-0.306,2.311,0.442,5.478  c1.078,4.568,3.568,8.723,7.199,12.013l18.115,16.439l18.426-16.438c3.631-3.291,6.121-7.445,7.199-12.014  C52.216,18.553,51.97,16.611,51.911,16.242z"
    />
  </svg>
);

export const ScreenShareIcon = () => (
  <svg
    id="Layer_1"
    fill="currentColor"
    width="1em"
    height="1em"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56.48 39.32"
  >
    <path
      className="cls-1"
      d="M50.5,10.32H41V6a6,6,0,0,0-6-6H6A6,6,0,0,0,0,6V23a6,6,0,0,0,6,6h9.51v4.34a6,6,0,0,0,6,6H50.5a6,6,0,0,0,6-6V16.29A6,6,0,0,0,50.5,10.32ZM6,26a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3H35a3,3,0,0,1,3,3v4.35H21.45a6,6,0,0,0-6,6V26Zm47.51,7.34a3,3,0,0,1-3,3h-29a3,3,0,0,1-3-3V16.29a3,3,0,0,1,3-3H50.5a3,3,0,0,1,3,3Z"
    />
  </svg>
);

export const ShareIcon = () => (
  <svg
    id="Layer_1"
    width="1em"
    height="1em"
    fill="#fff"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46.49 32.37"
  >
    <path
      fill="#535353"
      className="cls-1"
      d="M41.58,8.49H33.75V4.92A4.92,4.92,0,0,0,28.84,0H4.92A4.93,4.93,0,0,0,0,4.92V19a4.92,4.92,0,0,0,4.92,4.91h7.82v3.58a4.92,4.92,0,0,0,4.92,4.92H41.58a4.92,4.92,0,0,0,4.91-4.92v-14A4.92,4.92,0,0,0,41.58,8.49ZM4.92,21.4A2.45,2.45,0,0,1,2.47,19v-14A2.45,2.45,0,0,1,4.92,2.47H28.84a2.45,2.45,0,0,1,2.44,2.45V8.49H17.66a4.92,4.92,0,0,0-4.92,4.92v8ZM44,27.45a2.45,2.45,0,0,1-2.44,2.45H17.66a2.45,2.45,0,0,1-2.45-2.45v-14A2.45,2.45,0,0,1,17.66,11H41.58A2.45,2.45,0,0,1,44,13.41Z"
    />
    <path
      fill="#535353"
      className="cls-2"
      d="M4.92,21.4A2.45,2.45,0,0,1,2.47,19v-14A2.45,2.45,0,0,1,4.92,2.47H28.84a2.45,2.45,0,0,1,2.44,2.45V8.49H17.66a4.92,4.92,0,0,0-4.92,4.92v8ZM44,27.45a2.45,2.45,0,0,1-2.44,2.45H17.66a2.45,2.45,0,0,1-2.45-2.45v-14A2.45,2.45,0,0,1,17.66,11H41.58A2.45,2.45,0,0,1,44,13.41Z"
    />
  </svg>
);

export const RecordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="32px"
    fill="currentColor"
    viewBox="0 0 38.58 38.35"
  >
    <circle cx="7.18" cy="6.39" r="6.39" />
    <circle cx="21.94" cy="6.39" r="6.39" />
    <path d="M38.58,19.1V33.74s-.21,3.59-4.28,2.71c0,0-3.73-1.54-3.75-3.51s0-12.84,0-12.84a5.67,5.67,0,0,1,4.79-3.92A3.2,3.2,0,0,1,38.58,19.1Z" />
    <path d="M26.37,14.63H2.85A2.85,2.85,0,0,0,0,17.48v18a2.84,2.84,0,0,0,2.85,2.84H26.37a2.84,2.84,0,0,0,2.84-2.84v-18A2.85,2.85,0,0,0,26.37,14.63Zm-7.17,5.6h-15a1.52,1.52,0,1,1,0-3h15a1.52,1.52,0,0,1,0,3Z" />
  </svg>
);

export const AddCallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 43.53 31.29"
  >
    <path d="M0,29c0-2.89,1.92-4.24,4.15-5.33q4.24-2.05,8.54-4a14.35,14.35,0,0,1,1.8-.55c-.63-1.12-1.21-2.18-1.81-3.24C10.8,12.52,11,8.89,11.17,5.3A6,6,0,0,1,11.91,3,4.77,4.77,0,0,1,15.67.16a17,17,0,0,1,5.14.07c2.62.53,4.19,3,4.31,5.94.18,4.57-.44,8.93-3.37,12.67a.8.8,0,0,0-.05.22,2.52,2.52,0,0,0,.62.21c.61,0,.71.37.71.9,0,2.25,0,4.51,0,6.77v1H29.2v3.31H0Z" />
    <path d="M43.53,24.9c-1.25.06-2.5.13-3.75.17-.8,0-1.6,0-2.55,0v6.15H32.3V25.05c-1.86,0-3.63,0-5.39,0-.72,0-1-.2-.93-.93.05-1.32,0-2.63,0-4.08h6.28V13.81h4.84v6.26h6.42Z" />
  </svg>
);

export const TreeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.37 31.45"
    fill="currentColor"
  >
    <path
      className="cls-1"
      d="M9.67,18.75h17s.56,5-8.5,5S9.67,18.75,9.67,18.75Z"
    />
    <path
      className="cls-1"
      d="M28.14,26.28c2.48-6.58.12-14.17-.23-15.2,3.4.14,6.46,3.05,6.46,3.05,0-4.7-3.64-5.61-3.64-5.61,1.05-1.12,3.64-.7,3.64-.7C31.43,3.54,26.8,6.41,26.8,6.41,19.29,4.66,19,9.36,19,9.36a4.16,4.16,0,0,1,3.51.49c-3.51,2.46-1.27,7-1.27,7,1.47-3.79,3.46-5.3,5.45-5.65,1.11,9.45-.41,13.37-1.12,14.62a48.53,48.53,0,0,0-7.4-.49,50.79,50.79,0,0,0-7,.43c-.83-1.38-3-6.23-1.5-18.56,2.5.44,5,2.34,6.85,7.1,0,0,2.82-5.65-1.59-8.74a5.19,5.19,0,0,1,4.41-.61S19-1,9.53,1.19C9.53,1.19,3.71-2.43,0,3c0,0,3.26-.53,4.59.89C4.59,3.84,0,5,0,10.9c0,0,3.86-3.67,8.12-3.84-.43,1.3-3.45,11-.21,19.3-6.53,1.77-6,5.09-6,5.09H34.41S35,28,28.14,26.28Z"
    />
  </svg>
);

export const MiceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="41px" height="41px" viewBox="0 0 41 41" version="1.1" >
      
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group" transform="translate(1.000000, 1.000000)">
              <path d="M14.5507248,16.1344308 C14.2465679,16.1344308 14,16.3809987 14,16.6851556 L14,20.2606307 C14.0012482,22.9751912 16.0674292,25.2432194 18.7701243,25.496753 L18.7701243,29.0213919 L16.6095884,29.0213919 C16.4201963,28.9963614 16.2334617,29.0833612 16.1307896,29.2444647 C16.0281175,29.4055682 16.0281175,29.6115749 16.1307896,29.7726784 C16.2334617,29.9337819 16.4201963,30.0207817 16.6095884,29.9957512 L21.9134922,29.9957512 C22.1028843,30.0207817 22.2896189,29.9337819 22.392291,29.7726784 C22.4949631,29.6115749 22.4949631,29.4055682 22.392291,29.2444647 C22.2896189,29.0833612 22.1028843,28.9963614 21.9134922,29.0213919 L19.7529563,29.0213919 L19.7529563,25.496753 C22.4556514,25.2432194 24.5218325,22.9751912 24.5230806,20.2606307 L24.5230806,16.6851556 C24.5230806,16.3809987 24.2765127,16.1344308 23.9723558,16.1344308 C23.6681989,16.1344308 23.421631,16.3809987 23.421631,16.6851556 L23.421631,20.2606307 C23.421631,22.5581853 21.5590949,24.4207214 19.2615403,24.4207214 C16.9639857,24.4207214 15.1014497,22.5581853 15.1014497,20.2606307 L15.1014497,16.6851556 C15.1014497,16.3809987 14.8548818,16.1344308 14.5507248,16.1344308 Z M19.0412504,10.0001002 C18.206111,9.9934381 17.4026108,10.3192998 16.808098,10.9058645 C16.2135853,11.4924293 15.8769393,12.2914711 15.8724644,13.126626 L15.8724644,20.6503744 C15.8817636,22.3764876 17.2812276,23.7721693 19.0073596,23.7767969 L19.4479395,23.7767969 C20.2889101,23.7926786 21.1011648,23.470856 21.7030489,22.8833024 C22.3049331,22.2957488 22.6462318,21.4914836 22.6506162,20.6503744 L22.6506162,13.126626 C22.6461414,12.2914711 22.3094954,11.4924293 21.7149826,10.9058645 C21.1204699,10.3192998 20.3169697,9.9934381 19.4818303,10.0001002 L19.0412504,10.0001002 Z" id="Shape" fill="green" fill-rule="nonzero"/>
              
          </g>
      </g>
     
  </svg>)



export const MutedMiceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="41px" height="41px" viewBox="0 0 41 41" version="1.1" >
      
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group" transform="translate(1.000000, 1.000000)">
              <path d="M14.5507248,16.1344308 C14.2465679,16.1344308 14,16.3809987 14,16.6851556 L14,20.2606307 C14.0012482,22.9751912 16.0674292,25.2432194 18.7701243,25.496753 L18.7701243,29.0213919 L16.6095884,29.0213919 C16.4201963,28.9963614 16.2334617,29.0833612 16.1307896,29.2444647 C16.0281175,29.4055682 16.0281175,29.6115749 16.1307896,29.7726784 C16.2334617,29.9337819 16.4201963,30.0207817 16.6095884,29.9957512 L21.9134922,29.9957512 C22.1028843,30.0207817 22.2896189,29.9337819 22.392291,29.7726784 C22.4949631,29.6115749 22.4949631,29.4055682 22.392291,29.2444647 C22.2896189,29.0833612 22.1028843,28.9963614 21.9134922,29.0213919 L19.7529563,29.0213919 L19.7529563,25.496753 C22.4556514,25.2432194 24.5218325,22.9751912 24.5230806,20.2606307 L24.5230806,16.6851556 C24.5230806,16.3809987 24.2765127,16.1344308 23.9723558,16.1344308 C23.6681989,16.1344308 23.421631,16.3809987 23.421631,16.6851556 L23.421631,20.2606307 C23.421631,22.5581853 21.5590949,24.4207214 19.2615403,24.4207214 C16.9639857,24.4207214 15.1014497,22.5581853 15.1014497,20.2606307 L15.1014497,16.6851556 C15.1014497,16.3809987 14.8548818,16.1344308 14.5507248,16.1344308 Z M19.0412504,10.0001002 C18.206111,9.9934381 17.4026108,10.3192998 16.808098,10.9058645 C16.2135853,11.4924293 15.8769393,12.2914711 15.8724644,13.126626 L15.8724644,20.6503744 C15.8817636,22.3764876 17.2812276,23.7721693 19.0073596,23.7767969 L19.4479395,23.7767969 C20.2889101,23.7926786 21.1011648,23.470856 21.7030489,22.8833024 C22.3049331,22.2957488 22.6462318,21.4914836 22.6506162,20.6503744 L22.6506162,13.126626 C22.6461414,12.2914711 22.3094954,11.4924293 21.7149826,10.9058645 C21.1204699,10.3192998 20.3169697,9.9934381 19.4818303,10.0001002 L19.0412504,10.0001002 Z" id="Shape" fill="#EE220D" fill-rule="nonzero"/>
              
          </g>
      </g>
      <line
          x1="37.5"
          y1="4.5"
          x2="4.05428716"
          y2="38.9294103"
          id="Line"
          stroke="#EE220D"
          stroke-width="2"
          stroke-linecap="square"
          style={{ transform: 'scale(.7)',transformOrigin: '20px 20px'}}
        ></line>
  </svg>)