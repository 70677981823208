import React from "react";
import { Container } from "reactstrap";
import { AdminCreateEventForm } from "../../../components/form";

export default () => (
  <section className="py-5">
    <Container>
      <h1 className="text-center mb-5 title">Bonfire admin</h1>
      <AdminCreateEventForm />
    </Container>
  </section>
);
