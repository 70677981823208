import React, { useState } from "react";
import { FormGroup, Input, Button } from "reactstrap";
import SignInFacebook from "../LoginForm/signInFacebook";
import { validateSignUpFields } from "../../shared/common/validation";
import { withFirebase } from "../../../firebase/context";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Alert from "../../shared/common/alert";
import { connect } from "react-redux";
import * as loaderAction from "../../../redux/loader/action";
import * as loginAction from "../../../redux/login/action";
import * as chatAction from "../../../redux/user-chat/action";
import {
  CALL_TYPE_STATUS,
  INVITE_FRIEND_STATUS,
  SCHEMA,
} from "../../shared/constants";

const SignUpForm = (props) => {
  const {
    firebase,
    location: { state },
  } = props;
  const [info, setInfo] = useState({});

  const fields = [
    {
      label: "Email",
      item: "email",
      type: "email",
    },
    // { label: "Full name", item: "fullname", type: "text" },
    { label: "Username", item: "username", type: "text" },
    { label: "Password", item: "password", type: "password" },
  ];

  const onInputChange = (e, field) => {
    setInfo({ ...info, [field.item]: e.target.value });
  };
  const onSignIn = (userInfo) => {
    if (userInfo) {
      //handle redirect to dashboard
    }
    //handle error in signin
  };

  const getUserFromDatabase = async (user) => {
    let obj = {};
    await firebase
      .users()
      .orderByChild("email")
      .equalTo(user.email.toLowerCase())
      .once("value", async function (snapshot) {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const key = Object.keys(userData)[0];
          const userVal = Object.values(userData)[0];
          obj = {
            username: userVal.username,
            email: userVal.email,
            userId: key,
            authId: user.uid,
            profilePhotoUrl: userVal.photoUrl,
            role: userVal.role,
            friends: userVal.friends,
            isPremium: userVal.isPremium,
          };

          return obj;
        }
        return obj;
      });
    return obj;
  };

  const getCurrentUser = async (user) => {
    if (user != null) {
      const userObj = await getUserFromDatabase(user);
      return userObj;
    }
    return null;
  };

  const handleStartVideoCall = (userId) => {
    const currentCallId = sessionStorage.getItem("currentCallId");
    if (!currentCallId) {
      const userObj = {
        userId,
        status: INVITE_FRIEND_STATUS.ACCEPTED,
      };

      const obj = {
        timestamp: Date.now(),
        start: true,
        creatorId: userId,
        startedAt: Date.now()
      };
      obj.invited = {};
      obj.invited[userId] = userObj;

      firebase
        .addUserCall(SCHEMA.CALLS, obj)
        .then((resp) => {
          props.setIsLoading(false);
          props.history.push(`/call/${resp.key}`, {
            isCallSession: true,
          });
          const obj = {
            call_status: CALL_TYPE_STATUS.DIRECT_CALL,
            id: resp.key,
          };
          props.setOrUnsetCurrentCall(obj);
          props.setUserChatDetail({});
        })
        .catch((e) => {
          props.setIsLoading(false);
          props.history.push("/");
          Alert(400, "Unable to start call.");
        });
    } else {
      props.setIsLoading(false);
      props.history.push("/");
    }
  }

  const handleLogin = (email, password) => {
    firebase
      .authorization()
      .signInWithEmailAndPassword(email, password)
      .then(async (userRes) => {
        if (userRes) {
          const currentUser = await getCurrentUser(userRes.user);
          if (!!currentUser) {
            const userId = currentUser.userId;
            props.addCurrentUser(currentUser);

            if (state && state.state && state.state.isStartVideoCall) {
              handleStartVideoCall(userId);
            } else {
              props.setIsLoading(false);
              props.history.push("/");
            }            
          } else {
            props.setIsLoading(false);
            props.history.push("/");
          }
        } else {
          props.setIsLoading(false);
          props.history.push("/");
        }
      })
      .catch((error) => {
        props.setIsLoading(false);
        Alert(400, error.message);
        props.history.push("/");
      });
  };

  const signUpWithEmailAndPasswordHandler = (email, password) => {
    props.setIsLoading(true);
    firebase
      .authorization()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        if (res && res.code === "auth/email-already-in-use") {
          props.setIsLoading(false);
          Alert(400, res.message);
        } else {
          let obj = {
            username: info.username.toLowerCase(),
            email: email,
            role: "user",
            photoUrl: "",
            // isPremium: "true"
          };

          const user = firebase.setData("users").push(obj);
          if (user && user.key) {
            Alert(200, "Sign up successfully");
            handleLogin(email, password);
          } else {
            props.setIsLoading(false);
          }
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        props.setIsLoading(false);
        Alert(400, error.message);
      });
  };

  const submit = () => {
    const userObj = Object.assign({}, info);

    if (Object.keys(userObj).length === 0) {
      Alert(400, "Please fill the form.");
      return false;
    }
    const isValidData = validateSignUpFields(userObj);

    if (!isValidData) {
      return false;
    }
    if (userObj) {
      let userEmail = userObj.email.toLowerCase();
      signUpWithEmailAndPasswordHandler(userEmail, userObj.password);
    }
  };

  return (
    <>
      <SignInFacebook onSignIn={onSignIn} />
      <p className="or">
        <span>OR</span>
      </p>
      {fields.map((field, index) => {
        return (
          <FormGroup key={index} className="mb-2">
            <Input
              type={field.type}
              onChange={(e) => {
                onInputChange(e, field);
              }}
              placeholder={field.label}
              name={field.item}
              id={index}
            />
          </FormGroup>
        );
      })}
      <Button onClick={() => submit()} color="primary" className="w-100 mt-2">
        Sign up
      </Button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setUserChatDetail: (data) => dispatch(chatAction.setUserChatDetail(data)),
    addCurrentUser: (data) => dispatch(loginAction.setLoginDetails(data)),
  };
};
export default compose(
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(SignUpForm);
