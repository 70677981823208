import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import closeIcon from "../../../assets/images/icons/close.svg";
import { withFirebase } from "../../../firebase/context";
import { ROUTES } from "../../../components/shared/constants";
import ImageBox from "../../../components/imageBox";
import addIcon from "../../../assets/images/icons/add.svg";
import cameraIcon from "../../../assets/images/icons/camera.svg";
import { resetEventVenue, setEventDetail } from "../../../redux/admin-create-events/action";
import { setIsLoading } from "../../../redux/loader/action";
import { deleteEvent } from "../../../redux/admin-events/action";
import { cloneArrayOfObject } from "../../../components/shared/utils";
import Alert from "../../../components/shared/common/alert";
import { toSnakeCase } from "../../../components/shared/utils";
import Animations from "./animations";

class AdminEventComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoving: false,
      selectedEvent: null,
      selectedEventId: "",
      activeTab: 1,
    };

    this.onCreateEvent = this.onCreateEvent.bind(this);
    this.onEditEvent = this.onEditEvent.bind(this);
    this.handleOnRemoveEvent = this.handleOnRemoveEvent.bind(this);
    this.onCancelRemoveEvent = this.onCancelRemoveEvent.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTabs = this.toggleTabs.bind(this);
  }

  onCreateEvent() {
    this.props.resetEventVenue();
    this.props.history.push(ROUTES.admin.createEvent);
  }

  onEditEvent(event, key) {
    const tempEvent = {
      ...event,
      venueList: cloneArrayOfObject(event.venueList),
    };

    this.props.setEventDetail(tempEvent);
    this.props.history.push(ROUTES.admin.editEvent, {
      isEdit: true,
      id: key,
      event: tempEvent,
    });
  }

  handleOnRemoveEvent(event, key) {
    this.setState({
      selectedEvent: event,
      selectedEventId: key,
    });
    this.toggleModal();
  }

  onCancelRemoveEvent() {
    this.setState({
      selectedEvent: null,
      selectedEventId: "",
    });
    this.toggleModal();
  }

  onRemoveEvent(key, selectedEvent) {
    const name = toSnakeCase(selectedEvent.name);
    this.toggleModal();
    this.props.setIsLoading(true);

    this.ref = this.props.firebase.deleteAdminEvent(key);
    this.ref.then(async () => {
      this.props.deleteEvent(key);
      await this.props.firebase.deleteEventImagesFromStorage(name, "image/" + name);
      const { venueList } = selectedEvent;
      for (const venue of venueList) {
        if (venue) {
          const venueName = toSnakeCase(venue.locationName);
          await this.props.firebase.deleteEventImagesFromStorage(
            name,
            venueName + "/image/" + venueName
          );
        }
      }

      this.props.setIsLoading(false);
      Alert(200, "Event deleted sucessfully.");
    });
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  toggleTabs(tab) {
    this.setState({ activeTab: tab });
  }
  renderConfirmationDialog() {
    const { isOpen, selectedEvent, selectedEventId } = this.state;

    if (!selectedEvent || !selectedEventId) {
      return;
    }
    return (
      <Modal isOpen={isOpen} toggle={this.toggleModal} centered className="delete-modal">
        <ModalBody>
          <button onClick={this.onCancelRemoveEvent} className="modal-close">
            <img src={closeIcon} alt="close" />
          </button>
          <p className="text-danger m-text mb-0">
            Are you sure to remove <span className="text-capitalize">{selectedEvent.name}</span>{" "}
            event?{" "}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => this.onRemoveEvent(selectedEventId, selectedEvent)}
          >
            Remove
          </Button>
          <Button color="secondary" onClick={this.onCancelRemoveEvent}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { eventList } = this.props;
    const { activeTab } = this.state;
    const eventListKeys = eventList ? Object.keys(eventList) : [];
    const eventListValues = eventList ? Object.values(eventList) : [];

    return (
      <section className="py-4">
        <Container>
          <h1 className="text-center mb-3 title">Bonfire admin</h1>
          <div className="adminTabBtn">
            <button
              onClick={() => this.toggleTabs(1)}
              className={cx({ "active": activeTab === 1 })}
            >
              Events
            </button>
            <button
              onClick={() => this.toggleTabs(2)}
              className={cx({ "active": activeTab === 2 })}
            >
              Animations
            </button>
          </div>
          {activeTab === 1 && (
            <Row className="justify-content-center mx-auto" style={{ maxWidth: 700 }}>
              {eventListValues.map((event, index) => {
                const selectedId = eventListKeys[index];
                return (
                  <Col key={index} xs={6} sm={4} className="text-center">
                    <div className="position-relative d-table mx-auto">
                      <button
                        className="btb-icon p-0"
                        onClick={() => this.onEditEvent(event, selectedId)}
                      >
                        <ImageBox
                          imagePath={event.photoUrl || cameraIcon}
                          className="mb-3 mx-auto"
                        />
                      </button>
                      <button
                        className="btn-icon  position-absolute"
                        style={{ left: "100%", top: 0 }}
                        onClick={() => this.handleOnRemoveEvent(event, selectedId)}
                      >
                        <i className="fas fa-times text-danger" />
                      </button>
                    </div>
                    <button
                      className="btb-icon p-0"
                      onClick={() => this.onEditEvent(event, selectedId)}
                    >
                      <p className="text-center">{event.name}</p>
                    </button>
                  </Col>
                );
              })}
              <Col xs={6} sm={4}>
                <div className="text-center">
                  <button className="btn-icon p-0" onClick={this.onCreateEvent}>
                    <ImageBox imagePath={addIcon} className="mb-3 mx-auto" />
                    <p>New Event</p>
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
        {activeTab === 2 && <Animations />}
        {this.renderConfirmationDialog()}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventList: state.adminEventReducer.events,
  };
};
const mapDispatchToProps = {
  resetEventVenue,
  setEventDetail,
  deleteEvent,
  setIsLoading,
};

export default compose(
  withRouter,
  withFirebase
)(connect(mapStateToProps, mapDispatchToProps)(AdminEventComponent));
