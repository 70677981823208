import * as eventAction from "../../../redux/admin-events/action";
import * as loaderAction from "../../../redux/loader/action";
import * as loginAction from "../../../redux/login/action";
import * as chatAction from "../../../redux/user-chat/action";

import { Button, FormGroup, Input } from "reactstrap";
import React, { useState } from "react";

import Alert from "../../shared/common/alert";
import { Link } from "react-router-dom";
import SignInFacebook from "./signInFacebook";
import { compose } from "redux";
import { connect } from "react-redux";
import { validateLoginFields } from "../../shared/common/validation";
import { withFirebase } from "../../../firebase/context";
import { withRouter } from "react-router-dom";
import {
  CALL_TYPE_STATUS,
  INVITE_FRIEND_STATUS,
  SCHEMA,
} from "../../shared/constants";

const Login = (props) => {
  const {
    firebase,
    location: { state },
  } = props;
  const [info, setInfo] = useState({});
  const fields = [
    {
      label: "Email",
      item: "email",
      type: "email",
      size: 6,
    },
    { label: "Password", item: "password", type: "password", size: 6 },
  ];

  const onInputChange = (e, field) => {
    setInfo({ ...info, [field.item]: e.target.value });
  };

  const clearUserAlerts = async (userId) => {
    await props.firebase.clearUserAlerts(userId);
  };

  const onSignIn = async (userInfo) => {
    if (userInfo) {
      const userData = await getUserFromDatabase(
        userInfo.additionalUserInfo.profile.email
      );

      if (userData && Object.keys(userData).length > 0) {
        clearUserAlerts(userData.userId);
        props.addCurrentUser(userData);
      } else {
        let obj = {
          username: userInfo.additionalUserInfo.profile.name.toLowerCase(),
          email: userInfo.additionalUserInfo.profile.email.toLowerCase(),
          role: "user",
          photoUrl: "",
        };

        const userData = props.firebase.setData("users").push(obj);
        const currentUser = await getCurrentUser();
        await clearUserAlerts(userData.userId);
        props.addCurrentUser(currentUser);
      }
      props.history.push("/");
    }
  };

  const getAuthUser = () => {
    let user = props.firebase.authorization().currentUser;
    return user;
  };

  const getUserFromDatabase = async (userEmail) => {
    let obj = {};
    let user = getAuthUser();
    await props.firebase
      .users()
      .orderByChild("email")
      .equalTo(userEmail)
      .once("value", async function (snapshot) {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const key = Object.keys(snapshot.val())[0];
          obj.username = userData[key].username;
          obj.email = user.email;
          obj.userId = key;
          obj.authId = user.uid;
          obj.profilePhotoUrl = userData[key].photoUrl;
          obj.role = userData[key].role;
          obj.friends = userData[key].friends;
          obj.isPremium = userData[key].isPremium;
          return obj;
        }
        return obj;
      });
    return obj;
  };

  const getCurrentUser = async () => {
    let user = getAuthUser();
    let userEmail = user.email.toLowerCase();
    if (user != null) {
      const userObj = await getUserFromDatabase(userEmail);
      return userObj;
    }
  };

  const handleStartVideoCall = (userId) => {
    const currentCallId = sessionStorage.getItem("currentCallId");
    if (!currentCallId) {
      let userObj = {
        userId,
        status: INVITE_FRIEND_STATUS.ACCEPTED,
      };

      let obj = {
        timestamp: Date.now(),
        start: true,
        creatorId: userId,
        startedAt: Date.now()
      };
      obj.invited = {};
      obj.invited[userId] = userObj;

      firebase
        .addUserCall(SCHEMA.CALLS, obj)
        .then((resp) => {
          props.history.push(`/call/${resp.key}`, {
            isCallSession: true,
          });
          let obj = {
            call_status: CALL_TYPE_STATUS.DIRECT_CALL,
            id: resp.key,
          };
          props.setIsLoading(false);
          props.setOrUnsetCurrentCall(obj);
          props.setUserChatDetail({});
        })
        .catch((e) => {
          props.setIsLoading(false);
          Alert(400, "Unable to start call.");
          props.history.push("/");
        });
    } else {
      props.setIsLoading(false);
      props.history.push("/");
    }
  };

  const login = () => {
    props.setIsLoading(true);
    let email = info.email.toLowerCase();
    doLoginWithEmailAndPassword(email, info.password)
      .then(async () => {
        const currentUser = await getCurrentUser();

        await clearUserAlerts(currentUser.userId);
        props.addCurrentUser(currentUser);

        if (state && state.isStartVideoCall) {
          handleStartVideoCall(currentUser.userId);
        } else {
          props.setIsLoading(false);
          Alert(200, "Login success");
          props.history.push("/");
        }

      })
      .catch((error) => {
        props.setIsLoading(false);

        Alert(400, error.message);
      });
  };

  const doLoginWithEmailAndPassword = (email, password) =>
    props.firebase.authorization().signInWithEmailAndPassword(email, password);

  const submit = async () => {
    if (Object.keys(info).length === 0) {
      Alert(400, "Please fill the form.");
      return false;
    }
    const isValidData = validateLoginFields(info);
    if (!isValidData) {
      return false;
    }

    login();
  };

  return (
    <>
      <>
        {fields.map((field, index) => {
          return (
            <FormGroup key={index} className="mb-2">
              <Input
                type={field.type}
                onChange={(e) => {
                  onInputChange(e, field);
                }}
                placeholder={field.label}
                name={field.item}
                id={field.item}
              />
            </FormGroup>
          );
        })}
      </>
      <Button
        // disabled={isDisabled}
        onClick={() => submit()}
        color="primary"
        className="w-100 mt-2 mb-3"
      >
        Log In
      </Button>
      <p className="or">
        <span>OR</span>
      </p>
      <SignInFacebook onSignIn={onSignIn}/>

      <p className="forgot-pass text-center mb-0">
        <Link to="/forgot-password">Forgot password?</Link>
      </p>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginReducer: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setEvents: (data) => dispatch(eventAction.setEvents(data)),
    setCount: (data) => dispatch(loginAction.setCount(data)),
    setCallCount: (data) => dispatch(loginAction.setCallCount(data)),
    addCurrentUser: (data) => dispatch(loginAction.setLoginDetails(data)),
    setIsLoading: (data) => dispatch(loaderAction.setIsLoading(data)),
    updateFriendStatus: (data) =>
      dispatch(loginAction.updateFriendStatus(data)),
    setUserNotifications: (data) =>
      dispatch(loginAction.setUserNotifications(data)),
    setOrUnsetCurrentCall: (data) =>
      dispatch(loginAction.setOrUnsetCurrentCall(data)),
    setUserChatDetail: (data) => dispatch(chatAction.setUserChatDetail(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
