/* eslint-disable */

// Constant
export const UPDATE_CALL_END_ANIMATION = "UPDATE_CALL_END_ANIMATION";
export const IS_UPDATE_CALL_END_ANIMATION = "IS_UPDATE_CALL_END_ANIMATION";


export const updateCallEndAnimation = (payload) => ({
  type: UPDATE_CALL_END_ANIMATION,
  payload,
});

/**
 * 
 * @param {boolean} isUpdated 
 */
export const onUpdateCallEndAnimation = (isUpdated) => ({
  type: IS_UPDATE_CALL_END_ANIMATION,
  payload: isUpdated,
});



