import React, { Component } from "react";
import { FormGroup, Input, Button } from "reactstrap";
import Alert from "../shared/common/alert";
import { CALL_TIMER_STATUS } from '../shared/constants';

class TimerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || "",
      actionType: this.getActionType(props.actionType),
    };
  }

  getActionType = (actionType) => {
    const { PAUSE, RESUME, START } = CALL_TIMER_STATUS;
    if (actionType === START) {
      return PAUSE;
    } else if (actionType === PAUSE) {
      return RESUME;
    } else if (actionType === RESUME) {
      return PAUSE;
    }
    return START;
  };

  onChange = (event) => {
    const { callTimer } = this.props.userInfo;
    if (!callTimer) {
      this.setState({
        value: event.target.value,
      });
    }
  };

  onKeyDown = (event) => {
    if(event.keyCode === 13){
    this.onStart();      
   }
  };

  onStart = () => {
    const { userId, callTimer } = this.props.userInfo;
    const { value, actionType } = this.state;

    if ((callTimer && userId === callTimer.createdBy) || !callTimer) {
      const updatedActionType = this.getActionType(actionType);
      if (1 <= value && value <= 60) {
        const callID = sessionStorage.getItem("currentCallId");
        const data = {
          value: value * 60,
          timeStamp: Date.now(),
          createdBy: userId,
          status: actionType,
          callId: callID
        };
        this.props.firebase.setTimerValue(callID, userId, data);
        this.setState({ actionType: updatedActionType });
      } else {
        Alert(400, "Timer value should be between 1 to 60 minutes!");
      }
    } else {
      Alert(400, "Countdown timer is already in progress.");
      this.props.onCancel();
    }
  };

  onCancel = () => {
    const callID = sessionStorage.getItem("currentCallId");
    const {
      userInfo: { callTimer, userId },
    } = this.props;
    const { value } = this.state;
    if (!isNaN(value) && callTimer) {
      const data = {
        value: value * 60,
        timeStamp: Date.now(),
        createdBy: userId,
        status: CALL_TIMER_STATUS.CANCEL,
      };
      this.props.setTimerData(data);
      this.props.onCancel();
      setTimeout(() => {
        this.props.firebase.setTimerValue(callID, userId, data);
        this.setState({ actionType: CALL_TIMER_STATUS.START });
      }, 1000);
    } else {
      this.props.onCancel();
    }
  };

  render() {
    const { value, actionType } = this.state;
    const { userInfo: {callTimer} } = this.props;
    const isDisableStart = !value || isNaN(value);
    
    return (
      <div className="chat-timer">
        <FormGroup className="mb-0">
          <Input
            placeholder="Enter time in minutes..."
            value={value} 
            disabled={
              !!callTimer
            }
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
          ></Input>
        </FormGroup>
        <div className="d-flex justify-content-between mt-1">
          <Button
            color="purple"
            onClick={this.onStart}
            disabled={isDisableStart}
          >
            {actionType}
          </Button>
          <Button color="purple" onClick={this.onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default TimerComponent;
