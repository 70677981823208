import { combineReducers } from "redux";
import loginReducer from "../login/reducer";
import adminEventReducer from "../admin-events/reducer";
import adminCreateEventReducer from "../admin-create-events/reducer";
import loadingReducer from "../loader/reducer";
import userEventReducer from "../user-events/reducer";
import userCallsReducer from '../user-calls/reducer';
import userChatReducer from '../user-chat/reducer';
import chatSpecialSymbolReducer from '../chat-special-symbols/reducer';
import screenAnimationReducer from '../screen-animations/reducer';
import callEndAnimationsReducer from '../call-end-animations/reducer';
// import notificationReducer from "../notifications/reducer";


// combineReducers function turns an object whose values are different reducing functions into a single reducing function you can pass to createStore.
const appReducers = combineReducers({
  loginReducer: loginReducer,
  adminEventReducer: adminEventReducer,
  adminCreateEventReducer: adminCreateEventReducer,
  loaderDetails: loadingReducer,
  userEventReducer: userEventReducer,
  userCallsReducer:userCallsReducer,
  userChatReducer: userChatReducer,
  chatSpecialSymbols: chatSpecialSymbolReducer,
  screenAnimations: screenAnimationReducer,
  callEndAnimations: callEndAnimationsReducer
  // notificationReducer: notificationReducer
});

function rootReducer(state, action) {
  let newState = state;
  if (action.type === "CLEAR_STORAGE") {
    newState = undefined;
  }
  return appReducers(newState, action);
}

export default rootReducer;
