import React, { useState } from "react";
import { FormGroup, Input, Button } from "reactstrap";
import cx from "classnames";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import sendIcon from "../../../../assets/images/icons/chat-send.png";
import { ClickAwayListener } from "../../../../util";
import avatarIcon from "../../../../assets/images/icons/avatar.svg";
import { withFirebase } from "../../../../firebase/context";
import {
  SCHEMA,
  EMAIL_NOTIFICATION,
  INVITE_USER_PER_PAGE_LIMIT,
} from "../../../shared/constants";
import Alert from "../../../shared/common/alert";
import * as loginAction from "../../../../redux/login/action";
import { BasicLoader } from "../../../shared/common/loader";
import Validation from "../../../shared/common/validationMethods";
import { cloneArrayOfObject } from "../../../shared/utils";

const InviteFriends = (props) => {
  const [isDropDown, setIsDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileDropDown, setIsMobileDropDown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [isInviteToBonfire, setInviteToBonfire] = useState(false);
  const [isFriendsFetched, setFriendsFetched] = useState(false);
  const [isSendInvite, setSendInvite] = useState(false);
  const [count, setCount] = useState(INVITE_USER_PER_PAGE_LIMIT);

  const clickAway = () => {
    if (isDropDown) {
      setIsDropDown(false);
    }
  };

  const clickAwayMobile = () => {
    if (isMobileDropDown) {
      setIsMobileDropDown(false);
    }
  };

  const checkInvitedStatusOfUser = (userId) => {
    let status = "Invite";
    const loginUserData = props.loginReducer;

    if (
      loginUserData.friends &&
      Object.keys(loginUserData.friends).length >= 0
    ) {
      const friends = Object.values(loginUserData.friends);

      const friendIndex = friends.findIndex(
        (friend) => friend.userId === userId
      );
      if (friendIndex !== -1) {
        status = friends[friendIndex].status;
      }
    }

    return status;
  };

  const getSearchUsers = () => {
    let arr = [];
    const loginUserData = props.loginReducer;
    setIsDropDown(true);
    setCount(INVITE_USER_PER_PAGE_LIMIT);
    if (searchText) {
      setIsLoading(true);
      let textToSearch = searchText.trim();
      textToSearch = textToSearch.toLowerCase();
      let findBy = "username";
      let isEmailAddress = false;

      if (textToSearch.includes("@")) {
        findBy = "email";
        if (Validation.validateEmail(textToSearch)) {
          isEmailAddress = true;
        }
      }

      props.firebase
        .users()
        .orderByChild(findBy)
        .startAt(textToSearch)
        .endAt(textToSearch + "\uf8ff")
        .once("value", async function (snapshot) {
          if (snapshot.exists()) {
            setFriendsFetched(true);
            snapshot.forEach(function (child) {
              const status = checkInvitedStatusOfUser(child.key);
              if (child.key !== loginUserData.userId) {
                const userVal = child.val();
                if (userVal) {
                  arr.push({
                    userId: child.key,
                    username: userVal.username,
                    role: userVal.role,
                    photoUrl: userVal.photoUrl,
                    status,
                    email: userVal.email,
                  });
                }
              }
            });

            setIsLoading(false);
            setSearchedUsers(arr);
          } else {
            if (isEmailAddress) {
              setSendInvite(true);
              onSendInviteEmail(
                { email: textToSearch },
                loginUserData.username,
                true
              );
            } else {
              setFriendsFetched(true);
            }
            setIsLoading(false);
            setSearchedUsers([]);
          }
        });
    }
  };

  const onInputChange = (e) => {
    const name = e.target.value;
    setIsDropDown(false);
    setInviteToBonfire(false);
    setFriendsFetched(false);
    setSendInvite(false);
    if (searchedUsers && searchedUsers.length >= 0) {
      setSearchedUsers([]);
    }
    setSearchText(name);
  };

  const onFriendInvite = async (user) => {
    const loginUserData = props.loginReducer;
    let obj = {
      userId: user.userId,
      status: "PENDING",
    };

    props.firebase
      .addUserFriends(SCHEMA.USERS, loginUserData.userId, obj)
      .then((resp) => {
        obj.key = resp.key;
        props.addFriends(obj);

        updateStatusAfterInvite(obj.userId);

        let object = {
          title: `${loginUserData.username} sent you a friend request`,
          timestamp: Date.now(),
          creatorId: loginUserData.userId,
          userId: user.userId,
          type: "INVITE_FRIEND",
          photoUrl: loginUserData.profilePhotoUrl,
          status: "PENDING",
          friendId: resp.key,
          isread: false,
        };

        props.firebase
          .addUserNotification(SCHEMA.USERS, user.userId, object)
          .then(() => {
            onSendInviteEmail(user, loginUserData.username);
            Alert(200, "Friend invited successfully");
            setSearchText("");
            setSearchedUsers([]);
            setIsDropDown(false);
          });
      })
      .catch((err) => {
        Alert(400, "Unable to invite friend.", err);
      });
  };

  const onSendInviteEmail = (toUser, username, isNewUser) => {
    const msg = {
      from: { email: "no-reply@ourbonfire.com" },
      personalizations: [
        {
          to: [{ email: toUser.email }],
          dynamic_template_data: {
            sender_name: username,
          },
        },
      ],
      template_id: isNewUser
        ? "d-b22cb3d8a61d42698dffe17639f448ed"
        : "d-7483e7e5264745f182b34f8c806ad1de",
    };

    const body = JSON.stringify(msg);

    fetch(EMAIL_NOTIFICATION.INVITE_API, {
      method: "POST",
      body: "" + body,
    })
      .then((response) => response.json())
      .then((json) => {
        if (isNewUser) {
          setSendInvite(false);
          setInviteToBonfire(true);
        }
      })
      .catch(() => {});
  };

  const updateStatusAfterInvite = (id) => {
    let allSearchedUsers = searchedUsers.slice();

    let objIndex = allSearchedUsers.findIndex((user) => user.userId === id);
    allSearchedUsers[objIndex].status = "PENDING";
    setSearchedUsers(allSearchedUsers);
  };

  const onCrossClick = () => {
    setSearchText("");
    setIsDropDown(false);
    setSearchedUsers([]);
  };

  const showMore = () => {
    setCount(count + INVITE_USER_PER_PAGE_LIMIT);

    setTimeout(() => setIsDropDown(true), 100);
  };

  const displayUsers = () => {
    if (!searchedUsers || !searchedUsers.length) {
      return null;
    }

    const users = cloneArrayOfObject(searchedUsers.slice(0, count));

    return users.map((user, index) => {
      let isdisabled = false;
      let status = user.status;
      if (user.status === "Invite" || user.status === "REJECTED") {
        status = "Invite";
      } else if (user.status === "PENDING") {
        status = "Invited";
        isdisabled = true;
      }

      return (
        <div key={index} className="personConnect">
          <div className="d-flex  align-items-center">
            <img
              className="person-image"
              src={user.photoUrl || avatarIcon}
              alt="Bonfire"
            />
            <p className="mb-0 text-capitalize">{user.username}</p>
          </div>

          {(user.status === "REJECTED" ||
            user.status === "Invite" ||
            user.status === "PENDING") && (
            <Button
              disabled={isdisabled}
              onClick={() => onFriendInvite(user)}
              color="primary"
            >
              {status}
            </Button>
          )}
        </div>
      );
    });
  };

  const isShowMoreBtn = searchedUsers.length > count;
  return (
    <div className="pr-3">
    <ClickAwayListener onClickAway={clickAwayMobile}>
      <button
        className="btn-icon p-0 searchBtn d-sm-none"
        onClick={() => setIsMobileDropDown(true)}
      >
        <i className="fas fa-search"></i>
      </button>
      <div
        className={cx("invitefriendsWrapper", { "active": isMobileDropDown })}
      >
        <ClickAwayListener onClickAway={clickAway}>
          <FormGroup className="rounded-input mb-0 invitefriends">
            <Input
              value={searchText}
              name="search"
              onChange={(e) => {
                onInputChange(e);
              }}
              placeholder="Invite friends..."
            />
            <div className="srch-icon">
              {searchText !== "" && (
                <button onClick={() => onCrossClick()} className="btn-icon ">
                  <i className="fas fa-times"></i>
                </button>
              )}
              <button
                className="btn-icon sendBtn"
                onClick={() => getSearchUsers()}
              >
                <img src={sendIcon} alt={"search"} />
              </button>
            </div>

            <div
              className="searchResults"
              style={isDropDown ? {} : { display: "none" }}
            >
              <div className="scrollView">
                {searchedUsers && searchedUsers.length === 0 && (
                  <div className="text-center">
                    {isInviteToBonfire
                      ? "Invitation sent"
                      : isSendInvite
                      ? "Sending invite email..."
                      : isFriendsFetched
                      ? "No users found"
                      : "Fetching..."}
                  </div>
                )}
                {isLoading && <BasicLoader />}
                {displayUsers()}
              </div>

              {isShowMoreBtn && (
                <div className="mt-2 pt-1 text-center">
                  <Button color="primary" onClick={showMore}>
                    Show More
                  </Button>
                </div>
              )}
            </div>
          </FormGroup>
        </ClickAwayListener>

        <button
          className="btn-icon p-0 searchBtn ml-2 d-sm-none"
          onClick={() => setIsMobileDropDown(false)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    </ClickAwayListener>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginReducer: state.loginReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    addFriends: (data) => dispatch(loginAction.addFriends(data)),
  };
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(InviteFriends);
